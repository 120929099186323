import axios from 'axios';
import handle400 from './handle-400';
import handle500 from './handle-500';
import handleUnknown from './handle-unknown';

const handleDashboardErrors = (error: any) => {
    if (axios.isCancel(error)) return Promise.reject(error);

    if (isUnknownOrNetworkError(error)) return Promise.reject(error);

    error.response.status >= 500 && handle500();
    error.response.status < 500 && error.response.status >= 400 && handle400.dashboard(error.response.status);

    return Promise.reject(error);
};

const handleActingAsErrors = (error: any) => {
    if (isUnknownOrNetworkError(error)) return Promise.reject(error);

    error.response.status >= 500 && handle500();
    error.response.status < 500 && error.response.status >= 400 && handle400.actingAs(error.response.status);

    return Promise.reject(error);
};

const handlePlayErrors = (error: any) => {
    if (isUnknownOrNetworkError(error)) return Promise.reject(error);

    error.response.status >= 500 && handle500();
    error.response.status < 500 && error.response.status >= 400 && handle400.play(error.response.status);

    return Promise.reject(error);
};

const isUnknownOrNetworkError = (error: any): boolean => {
    if (typeof error === 'object'
        && error.response !== undefined
        && error.response.status !== undefined
    ) {
        return false;
    }

    handleUnknown();

    return true;
};

export default {
    handlePlayErrors,
    handleActingAsErrors,
    handleDashboardErrors,
};
