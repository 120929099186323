import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormValues } from './types';
import validation from './validation';
import FormPage from '../../../form/FormPage';
import LinkButton from '../../../common/LinkButton';
import SetNewPasswordForm from './SetNewPasswordForm';
import Notification from '../../../common/Notification';
import { apiLogin, endpoints } from '../../../../../api';
import withFormPageDefault from '../../../hoc/withFormPageDefault';
import { FormDefaultProps, FormPageProps, FormProps } from '../../../form/types';

type Props = FormDefaultProps<FormValues> & RouteComponentProps<{
    id: string,
    code: string,
}>

type State = {
    loading: boolean,
    hasValidCode: boolean,
}

class SetNewPassword extends React.Component<Props, State> {
    state = {
        loading: true,
        hasValidCode: true,
    }

    async componentDidMount() {
        const path = `${endpoints.app.setNewPassword}/${this.props.match.params.id}/${this.props.match.params.code}`;

        try {
            await apiLogin.get(path);
        } catch (e) {
            this.setState({ hasValidCode: false });
        }

        this.setState({ loading: false });
    }

    render() {
        const pageProps: FormPageProps = {
            headerI18n: 'set-new-password',
        };

        const formProps: FormProps<FormValues> = {
            renderForm: (props) => <SetNewPasswordForm {...props} />,
            handleSubmit: this.props.handleSubmit,
            validation: validation,
            initialValues: {
                id: this.props.match.params.id,
                code: this.props.match.params.code,
            },
            mode: 'none',
            loading: this.state.loading,
        };

        if (this.state.hasValidCode) {
            return <FormPage pageProps={pageProps} formProps={formProps} />;
        }

        return <Notification type="error" actions={<LinkButton i18n="go-home"/>}>
            <FormattedMessage id="notification.on-page.reset-password-link-invalid"/>
        </Notification>;
    }
}

export default withRouter(withFormPageDefault<FormValues>(SetNewPassword, props => ({
    endpoint: endpoints.app.setNewPassword,
    method: 'patch',
    validation: validation,
    i18nSuccessful: 'notification.set-my-password.success',
    callback: () => props.history.push('/'),
})));
