const SIGN_IN = 'global/sign-in';
const HANDSHAKE_LITE = 'global/handshake-lite';
const SIGN_OUT = 'global/sign-out';
const ACTING_AS = 'global/acting-as';
const ACTIVATE_TAB = 'global/activate-tab';

export default {
    SIGN_IN: SIGN_IN as typeof SIGN_IN,
    HANDSHAKE_LITE: HANDSHAKE_LITE as typeof HANDSHAKE_LITE,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
    ACTING_AS: ACTING_AS as typeof ACTING_AS,
    ACTIVATE_TAB: ACTIVATE_TAB as typeof ACTIVATE_TAB,
};
