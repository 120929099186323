import React from 'react';
import { AnyObject } from 'final-form';
import { FormattedMessage } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { Form, FormRenderProps } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import Switch from './Switch';
import { FormProps } from './types';
import Dialog from '../dialog/Dialog';
import Header from '../dialog/Header';
import Footer from '../dialog/Footer';
import FormFooter from './FormFooter';
import FormContainer from './FormContainer';
import { ModalProps } from '../modals/types';
import ModalLoader from '../loaders/ModalLoader';
import { touchFieldsWithSubmitErrors } from './utils';
import styles from './_form.module.sass';

type Props<FormValues extends AnyObject> = {
    modalProps: ModalProps,
    formProps: FormProps<FormValues>,
}

const FormModal = <FormValues extends AnyObject>({ modalProps, formProps }: Props<FormValues>) => {
    const mutators = { setFieldTouched, ...arrayMutators };

    return <Form
        onSubmit={formProps.handleSubmit}
        validate={formProps.validation.validationWithMessages}
        initialValues={formProps.initialValues}
        initialValuesEqual={formProps.initialValuesEqual}
        mutators={mutators as any}
        render={(formRenderProps: FormRenderProps<FormValues>) => {
            touchFieldsWithSubmitErrors<FormValues>(formRenderProps.form);

            return <Dialog
                size={modalProps.size}
                name={modalProps.name}
                canBeHidden={modalProps.canBeHidden}
                disableClose={formRenderProps.submitting}
            >
                <FormContainer<FormValues> formRenderProps={formRenderProps} classes={styles.scrollableFormBody}>
                    <Header>
                        {!! modalProps.customHeader && modalProps.customHeader}
                        {! modalProps.customHeader && <>
                            <FormattedMessage id={`modal.header.${modalProps.headerI18n}`}/>
                            {!! modalProps.subHeader && <span>{modalProps.subHeader}</span>}
                        </>}
                    </Header>
                    {formProps.loading ? <ModalLoader/> : formProps.renderForm({
                        ...formProps,
                        switchCreateAnother: (formProps) => {
                            return <Switch
                                checked={formProps.createAnother}
                                handleChange={formProps.handleCreateAnotherChange}
                                i18n="add-another"
                            />;
                        },
                    })}
                    <Footer>
                        <FormFooter<FormValues>
                            formRenderProps={formRenderProps}
                            formProps={formProps}
                        />
                    </Footer>
                </FormContainer>
            </Dialog>;
        }}
    />;
};

export default FormModal;
