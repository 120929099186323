import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './_layout.module.sass';

type Props = {
    classes?: string | undefined,
}

const Main: FunctionComponent<Props> = ({ children, classes = undefined }) => (
    <main className={classNames(styles.main, classes)}>
        {children}
    </main>
);

export default Main;
