import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import types from './../ducks/ui/types';
import { authSelectors } from '../ducks/auth';
import { mapUIToPersist } from '../ducks/ui/mappers';
import { browserStorage, tabStorage } from '../utils';

const uiStorageMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => action => {
    const result = next(action);

    if (~Object.values(types).indexOf(action.type)) {
        authSelectors.isActingAsAnotherUser(store.getState())
            ? tabStorage.setUI(mapUIToPersist(store.getState().ui))
            : browserStorage.setUI(mapUIToPersist(store.getState().ui));
    }

    return result;
};

export {
    uiStorageMiddleware,
};
