import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import * as reducers from './ducks';
import { ecommerceMapMiddleware, uiStorageMiddleware } from './middleware';

const rootReducer = combineReducers(reducers);

const createAppStore = (initialState: Partial<ReturnType<typeof rootReducer>> = {}) => {
    return createStore(
        rootReducer,
        initialState,
        process.env.NODE_ENV === 'development'
            ? composeWithDevTools(applyMiddleware(thunkMiddleware, ecommerceMapMiddleware, uiStorageMiddleware))
            : applyMiddleware(thunkMiddleware, ecommerceMapMiddleware, uiStorageMiddleware)
    );
};

export default createAppStore;

export {
    rootReducer,
};
