import React, { FunctionComponent } from 'react';
import Main from '../components/layout/Main';
import Container from '../components/layout/Container';
import PaymentCompleted from './payment/PaymentCompleted';
import PublicLightHeader from './common/PublicLightHeader';
import PublicLightFooter from './common/PublicLightFooter';
import styles from './_public.module.sass';

const PaymentCompletedView: FunctionComponent = () => (
    <Container>
        <PublicLightHeader/>
        <Main classes={styles.centeredBody}>
            <PaymentCompleted/>
        </Main>
        <PublicLightFooter/>
    </Container>
);

export default PaymentCompletedView;
