import React from 'react';
import { FormattedMessage } from 'react-intl';
import snackbars from '../../../views/components/snackbars/utils';

const handleUnknown = () => {
    snackbars.errorPersist(
        <FormattedMessage id="notification.http.unknown" values={{ br: <br /> }}/>
    );
};

export default handleUnknown;
