import { AxiosRequestConfigWithDelay, AxiosRequestConfigWithDelayOrPromise } from '../../types';

const delay = (config: AxiosRequestConfigWithDelay): AxiosRequestConfigWithDelayOrPromise => {
    if (config.delay) {
        return new Promise(resolve => setTimeout(() => resolve(config), config.delay));
    }

    return config;
};

export default delay;
