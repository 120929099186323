import React, { FunctionComponent } from 'react';
import Container from './Container';

const Blank: FunctionComponent = ({ children }) => (
    <Container>
        {children}
    </Container>
);

export default Blank;
