import axios from 'axios';
import interceptors from './interceptors';
import { AxiosRequestConfigWithDelay, AxiosRequestConfigWithDelayOrPromise } from '../types';

const apiActions = axios.create({
    baseURL: '/api/',
});

apiActions.interceptors.response.use(
    response => response,
    error => interceptors.response.handleDashboardErrors(error)
);

apiActions.interceptors.request.use((config: AxiosRequestConfigWithDelay): AxiosRequestConfigWithDelayOrPromise => {
    return interceptors.activeAt(config);
});

export default apiActions;
