import React, { FunctionComponent } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { endpoints } from '../../../../api';
import Footer from '../../../components/layout/Footer';
import LinkExternal from '../../../components/common/LinkExternal';
import styles from './_taiwan.module.sass';
import stylesCommon from './../_common.module.sass';

const PublicFooterTaiwan: FunctionComponent = () => (
    <Footer classes={classNames(stylesCommon.footer, styles.footer)}>
        <FormattedMessage id="footer.copyright" values={{ year: moment().year() }}/>
        <span>|</span>
        <LinkExternal to={endpoints.external.marketingWebsiteDomain}>
            <FormattedMessage id="footer.marketing-website-name"/>
        </LinkExternal>
        <LinkExternal to="https://curafun.com/terms-and-conditions/">
            <FormattedMessage id="footer.terms"/>
        </LinkExternal>
        <LinkExternal to="https://curafun.com/privacy-policy/">
            <FormattedMessage id="footer.privacy"/>
        </LinkExternal>
    </Footer>
);

export default PublicFooterTaiwan;
