import React from 'react';
import { AnyObject } from 'final-form';
import { FormattedMessage } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import Typography from '@material-ui/core/Typography';
import { Form, FormRenderProps } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import FormFooter from './FormFooter';
import FormContainer from './FormContainer';
import PageLoader from '../loaders/PageLoader';
import { FormPageProps, FormProps } from './types';
import styles from './_form.module.sass';

type Props<FormValues extends AnyObject> = {
    pageProps: FormPageProps,
    formProps: FormProps<FormValues>,
}

const FormPage = <FormValues extends AnyObject>({ pageProps, formProps }: Props<FormValues>) => {
    const mutators = { setFieldTouched, ...arrayMutators };

    return <section className={styles.formPage}>
        <Form
            onSubmit={formProps.handleSubmit}
            validate={formProps.validation.validationWithMessages}
            initialValues={formProps.initialValues}
            initialValuesEqual={formProps.initialValuesEqual}
            mutators={mutators as any}
            render={(formRenderProps: FormRenderProps<FormValues>) => (
                <>
                    {formProps.loading && <PageLoader/>}
                    {! formProps.loading && <FormContainer<FormValues> formRenderProps={formRenderProps}>
                        <header className={styles.formPageHeader}>
                            <Typography variant="h5">
                                <FormattedMessage id={`modal.header.${pageProps.headerI18n}`}/>
                            </Typography>
                        </header>
                        {formProps.renderForm({
                            ...formProps,
                            switchCreateAnother: (formProps) => <></>,
                        })}
                        <footer className={styles.formPageFooter}>
                            <FormFooter<FormValues>
                                formRenderProps={formRenderProps}
                                formProps={formProps}
                            />
                        </footer>
                    </FormContainer>}
                </>
            )}
        />
    </section>;
};

export default FormPage;
