import intl from '../i18n/intl';
import validationMethods from './validation-methods';
import { Errors, FormValues, LabelOverrides, Rules } from './types';

type RulesCallback<F> = (values: any) => Rules<F>
type RulesParam<F> = Rules<F> | RulesCallback<F>

const createValidation = <F extends FormValues>(rules: RulesParam<F>, labelOverrides?: LabelOverrides<F>) => {
    const errorsWithMessages = (errors: Errors<F>): Errors => {
        return Object.keys(errors).reduce((result, name) => {
            if (errors[name] === undefined) {
                return { ...result, [name]: undefined };
            }

            const label = `form.label.${labelOverrides && labelOverrides[name] ? labelOverrides[name] : name}`;
            const field = intl.formatMessage({ id: label });
            const msg = intl.formatMessage({ id: `form.${errors[name]}` }, { field });

            return { ...result, [name]: msg };
        }, {});
    };

    const validationWithMessages = (values: F) => {
        const validationRules = rules instanceof Function ? rules(values) : rules;
        const errors = Object.keys(validationRules).reduce((result, name) => {
            const errors = (validationRules[name] || []).map(rule => validationMethods[rule](name, values));
            const firstError = errors.find(v => !! v);

            return { ...result, [name]: firstError };
        }, {});

        return errorsWithMessages(errors);
    };

    return {
        errorsWithMessages,
        validationWithMessages,
    };
};

export default createValidation;
