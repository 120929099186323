const resolveStripe = (isSandbox: boolean): string => {
    const production = process.env.REACT_APP_PRODUCTION_STRIPE_PUBLISHABLE_KEY || '';
    const sandbox = process.env.REACT_APP_SANDBOX_STRIPE_PUBLISHABLE_KEY || '';

    if (isSandbox) {
        return sandbox;
    }

    return production;
};

export default resolveStripe;
