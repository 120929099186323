import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Body from '../../../dialog/Body';
import InputText from '../../../form/InputText';
import { FormProps } from '../../../form/types';
import config, { mode } from '../../../../../config';
import InputPassword from '../../../form/InputPassword';
import Notification from '../../../common/Notification';
import LinkExternal from '../../../common/LinkExternal';
import { AuthLoginFormValues } from '../../../../../state/ducks/auth';

const LoginForm: FunctionComponent<FormProps<AuthLoginFormValues>> = ({ extra }) => {
    const username = useField('username');

    const notifyEducator = () => {
        if (! config.isStudentSubdomain || ! mode.isCentervention()) return null;

        if (~username.input.value.indexOf('@')) {
            const url = `${config.dashboardSubdomain}.${config.domain}`;
            const values = { url: <LinkExternal to={`https://${url}`} newWindow={false}>{url}</LinkExternal>, br: <br/> };

            return <Grid item xs={12}>
                <Notification type="warning" inline>
                    <FormattedMessage id="notification.login.dashboard.subdomain" values={values}/>
                </Notification>
            </Grid>;
        }

        return null;
    };

    return <Body>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputText
                    name="username"
                    i18n={config.isStudentSubdomain ? 'username' : 'email'}
                    onFocus={extra.usernameOnFocus}
                    onBlur={extra.usernameOnBlur}
                />
            </Grid>
            {notifyEducator()}
            <Grid item xs={12}>
                <InputPassword
                    name="password"
                    eye={config.isStudentSubdomain}
                    onFocus={extra.passwordOnFocus}
                    onBlur={extra.passwordOnBlur}
                />
            </Grid>
        </Grid>
    </Body>;
};

export default LoginForm;
