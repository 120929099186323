import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/app/taiwan/logo.png';
import styles from './_taiwan.module.sass';

const PublicHeaderLogoTaiwan: FunctionComponent = () => <Link to="/" className={styles.header}>
    <img src={logo} alt=""/>
</Link>;

export default PublicHeaderLogoTaiwan;
