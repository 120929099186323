import config from '../../config';

const program = {
    'program.academy.title': config.programs.academy.title,
    'program.zoou.title': config.programs.zoou.title,
    'program.ssgrin.title': config.programs.ssgrin.title,
    'program.hoh.title': config.programs.hoh.title,
    'program.sim.title': config.programs.sim.title,

    'program.cdc': 'Cool Down Corner',

    'academy.target.group.description': 'Grade K-1',
    'zoou.target.group.description': 'Grade 2-4',
    'ssgrin.target.group.description': 'Grade 3-5',
    'hoh.target.group.description': 'Grade 6+',
    'sim.target.group.description': 'Autism',

    'academy.target.group.suggestion': 'Suggested for Grades K-1',
    'zoou.target.group.suggestion': 'Suggested for Grades 2-4',
    'ssgrin.target.group.suggestion': 'Suggested for Grades 3-5',
    'hoh.target.group.suggestion': 'Suggested for Grades 6+',
    'sim.target.group.suggestion': 'Suggested for Students with Autism',

    'program.expiration.date.warning': 'Your license will expire on {date}',
    'program.missing-report': 'Report is not available',

    'program.canvas.html5': `HTML5 canvas appears to be unsupported in the current browser. {br}
        Please try updating or use a different browser.`,

    'sim.title.1': 'In The Lunchroom: Joining In',
    'sim.title.2': 'On The Playground: Dealing with Bullying',
    'sim.title.3': 'In The Lunchroom: Listening',
    'sim.title.4': 'In The Classroom: Cooperating',
    'sim.title.5': 'On The Playground: Sportsmanship',
    'sim.title.6': 'In The Classroom: Perspective Taking',
    'sim.title.7': 'In The Classroom: Stop and Think',
    'sim.title.8': 'In The Lunchroom: Expressing Feelings',
    'sim.title.9': 'In The Classroom: Transitions',
    'sim.title.10': 'In The Classroom: Friendship',
    'sim.title.11': 'In The Classroom: Feeling Anxious',
    'sim.title.12': 'On The Playground: Personal Space',

    'sim.library.header': `{name}'s Library`,
};

export default program;
