const student = {
    config: 'config',
    preload: 'preload',
    sim: {
        library: 'student/sim/library',
        comic: 'student/sim/comic',
    },
};

export default student;
