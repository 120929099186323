import { createSelector } from 'reselect';
import { AccessToken, ActingAsUser, RootState, Scope, User } from './types';

const accessToken = (state: RootState): AccessToken => state.auth.accessToken;
const authUser = (state: RootState): User => state.auth.user;
const actingAs = (state: RootState): ActingAsUser | undefined => state.auth.actingAs;

const isAuthenticated = createSelector(
    [accessToken],
    (accessToken: AccessToken): boolean => {
        return !! accessToken.original;
    }
);

const originalAccessToken = createSelector(
    [accessToken],
    (accessToken: AccessToken): string => {
        return accessToken.original;
    }
);

const homePath = createSelector(
    [accessToken, actingAs],
    (accessToken: AccessToken, actingAs: ActingAsUser | undefined): string => {
        if (actingAs) {
            return `/${actingAs.scope}`;
        }

        return `/${accessToken.scope}`;
    }
);

const user = createSelector(
    [authUser, actingAs],
    (authUser: User, actingAs: ActingAsUser | undefined): User => {
        if (! actingAs) {
            return authUser;
        }

        return {
            username: actingAs.username,
            firstName: actingAs.firstName,
            lastName: actingAs.lastName,
            verified: actingAs.verified,
            isGroupAdmin: actingAs.isGroupAdmin,
            isTeamAdmin: actingAs.isTeamAdmin,
            isHighPriority: actingAs.isHighPriority,
            hasStudentsUsingProgram: actingAs.hasStudentsUsingProgram,
            hasSiteLicenseUsageReport: actingAs.hasSiteLicenseUsageReport,
            fullName: actingAs.fullName,
            grade: actingAs.grade,
            personalChannel: undefined,
        };
    }
);

const isUber = createSelector(
    [accessToken, actingAs],
    (accessToken: AccessToken, actingAs: ActingAsUser | undefined): boolean => {
        if (actingAs) {
            return false;
        }

        return accessToken.scope === Scope.Uber;
    }
);

const isEducator = createSelector(
    [accessToken, actingAs],
    (accessToken: AccessToken, actingAs: ActingAsUser | undefined): boolean => {
        if (actingAs) {
            return actingAs.scope === Scope.Educator;
        }

        return accessToken.scope === Scope.Educator;
    }
);

const isParent = createSelector(
    [accessToken, actingAs],
    (accessToken: AccessToken, actingAs: ActingAsUser | undefined): boolean => {
        if (actingAs) {
            return actingAs.scope === Scope.Parent;
        }

        return accessToken.scope === Scope.Parent;
    }
);

const isStudent = createSelector(
    [accessToken, actingAs],
    (accessToken: AccessToken, actingAs: ActingAsUser | undefined): boolean => {
        if (actingAs) {
            return actingAs.scope === Scope.Student;
        }

        return accessToken.scope === Scope.Student;
    }
);

const isActingAsAnotherUser = createSelector(
    [actingAs],
    (actingAs: ActingAsUser | undefined): boolean => {
        return !! actingAs;
    }
);

const hasAllStudentsView = createSelector(
    [authUser, actingAs],
    (authUser: User, actingAs: ActingAsUser | undefined): boolean => {
        if (! actingAs) {
            return authUser.isGroupAdmin || authUser.isTeamAdmin;
        }

        return actingAs.isGroupAdmin || actingAs.isTeamAdmin;
    }
);

const hasManageEducatorsView = createSelector(
    [authUser, actingAs],
    (authUser: User, actingAs: ActingAsUser | undefined): boolean => {
        if (! actingAs) {
            return authUser.isGroupAdmin || authUser.isTeamAdmin;
        }

        return actingAs.isGroupAdmin || actingAs.isTeamAdmin;
    }
);

const canInviteColleague = createSelector(
    [authUser, actingAs],
    (authUser: User, actingAs: ActingAsUser | undefined): boolean => {
        const user = actingAs || authUser;

        return user.verified && (user.hasStudentsUsingProgram || user.isGroupAdmin || user.isTeamAdmin);
    }
);

export default {
    isAuthenticated,
    originalAccessToken,
    homePath,
    user,
    isUber,
    isEducator,
    isParent,
    isStudent,
    isActingAsAnotherUser,
    hasAllStudentsView,
    hasManageEducatorsView,
    canInviteColleague,
};
