import { createSelector } from 'reselect';
import { RootState } from '../../types';
import { toExpirationDate } from '../../../i18n';
import { FreeTrial, Individual, Site } from './types';

const freeTrial = (state: RootState): FreeTrial => state.ecommerce.purchases.freeTrial;
const individual = (state: RootState): Individual => state.ecommerce.purchases.individual;
const site = (state: RootState): Site => state.ecommerce.purchases.site;

const paidRemaining = createSelector(
    [individual, site],
    (individual, site): number => {
        return (individual || 0) + (site || 0);
    }
);

const hasIndividualRemaining = createSelector(
    [individual],
    (individual): boolean => {
        return (individual || 0) > 0;
    }
);

const hasPaidRemaining = createSelector(
    [paidRemaining],
    (paidRemaining): boolean => {
        return paidRemaining > 0;
    }
);

const hasValidFreeTrial = createSelector(
    [freeTrial],
    (freeTrial: FreeTrial): boolean => {
        return freeTrial !== undefined && toExpirationDate(freeTrial).isValid;
    }
);

const shouldUseFreeTrial = createSelector(
    [hasValidFreeTrial, hasPaidRemaining],
    (hasValidFreeTrial, hasPaidRemaining): boolean => {
        return hasValidFreeTrial && ! hasPaidRemaining;
    }
);

const shouldUsePaidLicense = createSelector(
    [hasPaidRemaining],
    (hasPaidRemaining): boolean => {
        return hasPaidRemaining;
    }
);

export default {
    paidRemaining,
    hasIndividualRemaining,
    hasPaidRemaining,
    hasValidFreeTrial,
    shouldUseFreeTrial,
    shouldUsePaidLicense,
};
