import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoginForm from './LoginForm';
import validation from './validation';
import config from '../../../../../config';
import { FormProps } from '../../../form/types';
import FormModal from '../../../form/FormModal';
import { Errors } from '../../../../../validation';
import LoginCustomHeader from './LoginCustomHeader';
import LoginAdditionalFooter from './LoginAdditionalFooter';
import { ModalContainerProps, ModalProps } from '../../../modals/types';
import createModalSettings from '../../../modals/create-modal-settings';
import { RootState, AuthLoginFormValues, authOperations, authSelectors, modalsOperations } from '../../../../../state';

type State = {
    usernameFocussed: boolean,
    passwordFocussed: boolean,
}

type Props = ModalContainerProps & RouteComponentProps & ConnectedProps<typeof connector>

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
}), {
    signIn: authOperations.signIn,
    open: modalsOperations.open,
    close: modalsOperations.close,
});

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.closeLogin = this.closeLogin.bind(this);
        this.switchToResetPassword = this.switchToResetPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.usernameOnFocus = this.usernameOnFocus.bind(this);
        this.usernameOnBlur = this.usernameOnBlur.bind(this);
        this.passwordOnFocus = this.passwordOnFocus.bind(this);
        this.passwordOnBlur = this.passwordOnBlur.bind(this);

        this.state = { usernameFocussed: false, passwordFocussed: false };
    }

    closeLogin() {
        this.props.close('login');
    }

    switchToResetPassword() {
        this.closeLogin();
        setTimeout(() => this.props.open(createModalSettings('reset-my-password')), 100);
    }

    async handleSubmit(values: AuthLoginFormValues) {
        const errors = await this.props.signIn(values, () => {
            this.props.close(this.props.name);
            const query = new URLSearchParams(this.props.history.location.search);
            const redirect = query.get('r');
            this.props.history.push(redirect ? redirect : this.props.homePath);
        });

        if (config.isStudentSubdomain) {
            return validation.studentValidation.errorsWithMessages((errors as unknown) as Errors<AuthLoginFormValues>);
        }

        return validation.appValidation.errorsWithMessages((errors as unknown) as Errors<AuthLoginFormValues>);
    }

    usernameOnFocus() {
        this.setState({ usernameFocussed: true });
    }

    usernameOnBlur() {
        this.setState({ usernameFocussed: false });
    }

    passwordOnFocus() {
        this.setState({ passwordFocussed: true });
    }

    passwordOnBlur() {
        this.setState({ passwordFocussed: false });
    }

    render() {
        const modalProps: ModalProps = {
            name: this.props.name,
            size: 'xs',
            headerI18n: this.props.type,
            canBeHidden: this.props.canBeHidden,
            customHeader: <LoginCustomHeader {...this.state}/>,
        };

        const formProps: FormProps<AuthLoginFormValues> = {
            renderForm: (props) => <LoginForm {...props} />,
            handleSubmit: this.handleSubmit,
            validation: config.isStudentSubdomain
                ? validation.studentValidation
                : validation.appValidation,
            initialValues: undefined,
            mode: 'none',
            loading: false,
            submitI18n: 'login',
            additionalFooterButtons: submitting => <LoginAdditionalFooter
                submitting={submitting}
                closeLogin={this.closeLogin}
                switchToResetPassword={this.switchToResetPassword}
            />,
            extra: {
                usernameOnFocus: this.usernameOnFocus,
                usernameOnBlur: this.usernameOnBlur,
                passwordOnFocus: this.passwordOnFocus,
                passwordOnBlur: this.passwordOnBlur,
            },
        };

        return <FormModal<AuthLoginFormValues> key="m-login" modalProps={modalProps} formProps={formProps}/>;
    }
}

export default withRouter(connector(Login));
