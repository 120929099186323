import React, { FunctionComponent, Suspense } from 'react';
import { mode } from '../config';
import AppRoutes from './AppRoutes';
import HandShake from './components/common/HandShake';
import PublicModals from './public/common/PublicModals';
import RouterLazyLoader from './components/loaders/RouterLazyLoader';
import ChunkLoadErrorBoundary from './components/common/ChunkLoadErrorBoundary';

const AppContent: FunctionComponent = () => {
    if (mode.isBuildSnapshot()) {
        return <AppRoutes/>;
    }

    return <HandShake>
        <ChunkLoadErrorBoundary>
            <Suspense fallback={<RouterLazyLoader/>}>
                <AppRoutes/>
                <PublicModals/>
            </Suspense>
        </ChunkLoadErrorBoundary>
    </HandShake>;
};

export default AppContent;
