import React from 'react';
import Body from '../dialog/Body';
import ModalInlineLoader from './ModalInlineLoader';

const ModalLoader = () => (
    <Body>
        <ModalInlineLoader/>
    </Body>
);

export default ModalLoader;
