import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormValues } from './types';
import Body from '../../../dialog/Body';
import { FormProps } from '../../../form/types';
import InputPassword from '../../../form/InputPassword';

const SetNewPasswordForm: FunctionComponent<FormProps<FormValues>> = () => (
    <Body>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputPassword name="new_password" />
            </Grid>
            <Grid item xs={12}>
                <InputPassword name="new_password_confirmation" />
            </Grid>
        </Grid>
    </Body>
);

export default SetNewPasswordForm;
