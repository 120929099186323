import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseButton from './CloseButton';
import DialogContext from './DialogContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import styles from './_dialog.module.sass';

const Header: FunctionComponent = ({ children }) => (
    <DialogContext.Consumer>
        {({ canBeHidden, disableClose, handleHide, handleClose }) => (
            <DialogTitle key="header" className={styles.header}>
                {children}
                {canBeHidden && <IconButton
                    key="i-hide"
                    onClick={handleHide}
                    className={classNames(styles.iconButton, styles.hideButton)}
                >
                    <FontAwesomeIcon icon={faMinus} className={styles.icon} />
                </IconButton>}
                <CloseButton
                    key="i-close"
                    handleClose={handleClose}
                    disableClose={disableClose}
                />
            </DialogTitle>
        )}
    </DialogContext.Consumer>
);

export default Header;
