import {
    User,
    TabUser,
    Profile,
    AccessToken,
    ActingAsUser,
    OriginalUser,
    OriginalProfile,
    OriginalAccessToken,
    OriginalActingAsUser,
} from './types';

const mapAccessToken = (originalAccessToken: OriginalAccessToken): AccessToken => {
    const accessTokenParts = originalAccessToken.split('.');
    const originalScopes = JSON.parse(atob(accessTokenParts.pop() as string)).scopes;

    return {
        original: originalAccessToken,
        scope: originalScopes.shift(),
        scopes: originalScopes,
    };
};

const nullAccessToken = (): AccessToken => {
    return {
        original: '',
        scope: '',
        scopes: [],
    };
};

const mapUser = (user: OriginalUser): User => {
    return {
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        verified: user.verified,
        isGroupAdmin: user.is_group_admin || false,
        isTeamAdmin: user.is_team_admin || false,
        isHighPriority: user.is_high_priority || false,
        hasStudentsUsingProgram: user.has_students_using_program || false,
        hasSiteLicenseUsageReport: user.has_site_license_usage_report || false,
        fullName: `${user.first_name} ${user.last_name}`,
        grade: user.grade,
        personalChannel: user.personal_channel,
    };
};

const mapActingAsUser = (user: OriginalActingAsUser): ActingAsUser => {
    return {
        id: user.id,
        scope: user.scope,
        scopes: user.scopes,
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        verified: user.verified,
        isGroupAdmin: user.is_group_admin || false,
        isTeamAdmin: user.is_team_admin || false,
        isHighPriority: user.is_high_priority || false,
        hasStudentsUsingProgram: user.has_students_using_program || false,
        hasSiteLicenseUsageReport: user.has_site_license_usage_report || false,
        fullName: `${user.first_name} ${user.last_name}`,
        grade: user.grade,
        personalChannel: user.personal_channel,
    };
};

const mapProfile = (profile: OriginalProfile): Profile => {
    return {
        firstName: profile.first_name,
        lastName: profile.last_name,
        fullName: `${profile.first_name} ${profile.last_name}`,
    };
};

const mapTabUser = (id: number, type: string): TabUser => {
    return {
        id,
        type,
    };
};

const nullUser = (): User => {
    return {
        username: '',
        firstName: '',
        lastName: '',
        verified: false,
        isGroupAdmin: false,
        isTeamAdmin: false,
        isHighPriority: false,
        hasStudentsUsingProgram: false,
        hasSiteLicenseUsageReport: false,
        fullName: '',
        grade: undefined,
        personalChannel: undefined,
    };
};

export {
    mapAccessToken,
    nullAccessToken,
    mapUser,
    mapActingAsUser,
    mapProfile,
    mapTabUser,
    nullUser,
};
