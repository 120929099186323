import global from './../types';

const SIGN_IN = global.SIGN_IN;
const HANDSHAKE_LITE = global.HANDSHAKE_LITE;
const SIGN_OUT = global.SIGN_OUT;
const ACTING_AS = global.ACTING_AS;
const FETCH_FRESH = 'ecommerce/fetch/fresh';

export default {
    SIGN_IN: SIGN_IN as typeof SIGN_IN,
    HANDSHAKE_LITE: HANDSHAKE_LITE as typeof HANDSHAKE_LITE,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
    ACTING_AS: ACTING_AS as typeof ACTING_AS,
    FETCH_FRESH: FETCH_FRESH as typeof FETCH_FRESH,
};

export * from './../../types';

export type FreeTrial = string | undefined;
export type Individual = number | undefined;
export type Site = number | undefined;
export type Purchases = {
    freeTrial: FreeTrial,
    individual: Individual,
    site: Site,
}
export type ProductUsage = { [key in string]: string };

export interface EcommerceState {
    purchases: Purchases,
    productUsage: ProductUsage,
}

export type EcommercePartial = {
    purchases?: {
        'free-trial'?: FreeTrial,
        individual?: Individual,
        site?: Site,
    }
    product_usage?: ProductUsage,
}

export interface EcommerceSignInAction {
    type: typeof SIGN_IN,
    payload: {
        accessToken: any,
        user: any,
        ecommerce: EcommerceState,
        notifications: number,
    }
}

export interface EcommerceSignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: any,
        user: any,
    }
}

export interface EcommerceActingAsAction {
    type: typeof ACTING_AS,
    payload: {
        actingAsUser: any,
        ecommerce: EcommerceState,
        notifications: number,
    }
}

export interface EcommerceFetchFreshAction {
    type: typeof FETCH_FRESH,
    payload: {
        ecommerce: EcommerceState,
    }
}

export interface EcommerceSet {
    payload: {
        ecommerce: EcommerceState,
    }
}
