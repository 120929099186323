import global from './../types';

const SIDEBAR_TOGGLE = 'ui/sidebar/toggle';
const TABS_ACTIVATE_GROUP = 'ui/tabs/activate-group';
const TABS_ACTIVATE_TAB = global.ACTIVATE_TAB;
const SIGN_OUT = global.SIGN_OUT;
const CONFETTI_SHOW = 'ui/confetti/show';
const VIDEO_OVERVIEW_TOGGLE = 'ui/video/overview/toggle';

export default {
    SIDEBAR_TOGGLE: SIDEBAR_TOGGLE as typeof SIDEBAR_TOGGLE,
    TABS_ACTIVATE_GROUP: TABS_ACTIVATE_GROUP as typeof TABS_ACTIVATE_GROUP,
    TABS_ACTIVATE_TAB: TABS_ACTIVATE_TAB as typeof TABS_ACTIVATE_TAB,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
    CONFETTI_SHOW: CONFETTI_SHOW as typeof CONFETTI_SHOW,
    VIDEO_OVERVIEW_TOGGLE: VIDEO_OVERVIEW_TOGGLE as typeof VIDEO_OVERVIEW_TOGGLE,
};

export * from './../../types';

export type UITab = {
    group: string,
    tab: string,
}

export interface UIState {
    sidebar: {
        isCollapsed: boolean,
        uberIsOpen: boolean,
        studentsIsOpen: boolean,
        accountSettingsIsOpen: boolean,
        uberToolsIsOpen: boolean,
    },
    tabs: {
        [id: string]: UITab[],
    },
    lastTab: UITab | undefined,
    confetti: boolean,
    video: {
        isOverviewCollapsed: boolean,
    },
}

export interface UIPersist {
    sidebar: {
        isCollapsed: boolean,
        uberIsOpen: boolean,
        studentsIsOpen: boolean,
        accountSettingsIsOpen: boolean,
    }
    tabs: {
        [id: string]: UITab[],
    },
    video: {
        isOverviewCollapsed: boolean,
    },
}

export enum TabsIdentifiers {
    UberAccountSourcesView = 'uber-account-sources-view',
    UberSchoolsView = 'uber-schools-view',
    UberFamiliesView = 'uber-families-view',
    UberMetricsView = 'uber-metrics-view',
    UberAdminView = 'uber-admin-view',
    UberAcademyView = 'uber-academy-view',
    UberZoouView = 'uber-zoou-view',
    UberSsgrinView = 'uber-ssgrin-view',
    UberHohView = 'uber-hoh-view',
    UberSimView = 'uber-sim-view',
    EducatorEducatorsView = 'educator-educators-view',
    EducatorStudentsView = 'educator-students-view',
    EducatorSelCheckInsView = 'educator-sel-check-ins-view',
    EducatorReportsView = 'educator-reports-view',
    EducatorGroupDataView = 'educator-group-data-view',
    ParentStudentsView = 'parent-students-view',
    ParentProgressView = 'parent-progress-view',
}

export enum TabsGroups {
    Students = 'students',
    Purchases = 'purchases',
    MyStudents = 'my-students',
    AllStudents = 'all-students',
    Children = 'children',
    Academy = 'academy',
    Zoou = 'zoou',
    Ssgrin = 'ssgrin',
    Hoh = 'hoh',
    Sim = 'sim',
    MetricsGifts = 'metrics-gifts',
    ManageStudents = 'manage-students',
    SelCheckIns = 'sel-check-ins',
    Reports = 'reports',
    GroupDataAcademy = 'group-data-academy',
    GroupDataZoou = 'group-data-zoou',
    GroupDataSsgrin = 'group-data-ssgrin',
    GroupDataHoh = 'group-data-hoh',
}

export enum ButtonGroup {
    AllRosterCurrent = 'admin--all---roster--current',
    MyRosterCurrent = 'roster--current'
}

export interface SidebarToggleAction {
    type: typeof SIDEBAR_TOGGLE,
    payload: keyof UIState['sidebar'],
}

export interface TabsActivateGroupAction {
    type: typeof TABS_ACTIVATE_GROUP,
    payload: {
        id: TabsIdentifiers,
        group: string,
        defaultTab: string,
    },
}

export interface TabsActivateTabAction {
    type: typeof TABS_ACTIVATE_TAB,
    payload: {
        id: TabsIdentifiers,
        group: string,
        tab: string,
    },
}

export interface UISignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: any,
        user: any,
    }
}

export interface ConfettiShowAction {
    type: typeof CONFETTI_SHOW,
    payload: {
        show: boolean,
    }
}

export interface VideoOverviewToggleAction {
    type: typeof VIDEO_OVERVIEW_TOGGLE,
}
