const app = {
    login: 'login',
    logout: 'logout',
    handshake: 'handshake',
    handshakeLite: 'handshake-lite',
    classlinkLogin: 'classlink/login',
    cleverLogin: 'clever/login',
    ecommerce: 'ecommerce',
    resetMyPassword: 'reset-my-password',
    setNewPassword: 'set-new-password',
    changeMyPassword: 'change-my-password',
    profile: 'profile',
    setNewProfileUsername: 'set-new-profile-username',
    accountRequest: 'account/request',
    accountActivate: 'account/activate',
    paymentSessionEducator: 'payment/session/educator',
    paymentSessionParent: 'payment/session/parent',
    paymentSessionParentWithUsage: 'payment/session/parent/usage',
    paymentSessionParentNew: 'payment/session/parent/new',
    paymentCompleted: 'payment/completed',
    trackingMetrics: 'dashboard-metrics',
};

export default app;
