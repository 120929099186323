import React, { FunctionComponent } from 'react';
import { ModalContainerProps } from './types';
import { ModalSettings } from '../../../state';

type Props = {
    Component: React.ComponentType<ModalContainerProps>,
    modal: ModalSettings & { extra: any },
    canBeHidden?: boolean,
}

const ModalComponent: FunctionComponent<Props> = ({ Component, modal, canBeHidden = false }) => (
    <Component
        name={modal.name}
        id={modal.id}
        type={modal.type}
        canBeHidden={canBeHidden}
        tabulator={modal.tabulator}
        tabulatorRowCopy={modal.tabulatorRowCopy}
        extra={modal.extra}
    />
);

export default ModalComponent;
