import { Store } from 'redux';
import { Provider } from 'react-redux';
import React, { FunctionComponent } from 'react';

type Props = {
    store: Store,
}

const Root: FunctionComponent<Props> = ({ store, children }) => (
    <Provider store={store}>
        {children}
    </Provider>
);

export default Root;
