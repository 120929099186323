import React from 'react';
import { AnyObject } from 'final-form';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';
import Submit from './Submit';
import { FormProps } from './types';

type Props<FormValues extends AnyObject> = {
    formRenderProps: FormRenderProps<FormValues>,
    formProps: FormProps<FormValues>,
}

const FormFooter = <FormValues extends AnyObject>({
    formRenderProps,
    formProps: {
        loading = false,
        submitI18n = 'submit',
        hideFooterOnPristine = false,
        disableSubmitOnPristine = true,
        useCenterventionLayout = false,
        additionalFooterButtons = undefined,
    },
}: Props<FormValues>) => {
    if (hideFooterOnPristine && formRenderProps.pristine) {
        return null;
    }

    return <>
        <Submit
            submitting={formRenderProps.submitting}
            disabled={(formRenderProps.pristine && disableSubmitOnPristine) || loading}
            useCenterventionLayout={useCenterventionLayout}
        >
            <FormattedMessage id={`modal.button.${submitI18n}`}/>
        </Submit>
        {additionalFooterButtons && additionalFooterButtons(formRenderProps.submitting)}
    </>;
};

export default FormFooter;
