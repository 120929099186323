import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import ButtonLoader from '../loaders/ButtonLoader';
import coreStyles from './../../../styles.module.scss';

type Props = {
    submitting: boolean,
    disabled: boolean,
    useCenterventionLayout?: boolean,
}

const Submit: FunctionComponent<Props & WithStyles> = ({
    submitting,
    disabled,
    children,
    classes,
    useCenterventionLayout = false,
}) => {
    return (
        <Button
            key="submit"
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting || disabled}
            className={useCenterventionLayout ? classes.centervention : undefined}
        >
            <ButtonLoader show={submitting}>
                {children || <FormattedMessage id="modal.button.submit" />}
            </ButtonLoader>
        </Button>
    );
};

const classes = {
    centervention: { backgroundColor: coreStyles.generalCenterventionLightGreenColor },
};

export default withStyles(classes)(Submit);
