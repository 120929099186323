import React, { FunctionComponent, useRef, useState } from 'react';
import { FieldProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { TextField } from 'final-form-material-ui';
import IconButton from '@material-ui/core/IconButton';
import { Field, FieldRenderProps } from 'react-final-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import styles from './_form.module.sass';

type Props = {
    eye?: boolean
}

const InputPassword: FunctionComponent<FieldProps<any, FieldRenderProps<any>> & Props> = ({ eye = false, ...props }) => {
    const inputRef = useRef();
    const [secure, setSecure] = useState<boolean>(true);

    const handleSecureChange = () => {
        setSecure(! secure);

        // @ts-ignore
        const input = inputRef.current.children[0];
        input.focus();
        input.selectionStart = input.value.length;
        input.selectionEnd = input.value.length;
    };

    const inputProps = eye ? {
        ref: inputRef,
        endAdornment: <InputAdornment position="end">
            <IconButton className={styles.securePassword} onClick={handleSecureChange} disableRipple>
                <FontAwesomeIcon icon={secure ? faEyeSlash : faEye}/>
            </IconButton>
        </InputAdornment>,
    } : {};

    return <Field
        type={eye && ! secure ? 'text' : 'password'}
        component={TextField}
        variant="outlined"
        fullWidth
        label={<FormattedMessage id={`form.label.${props.i18n || props.name}`}/>}
        className={styles.field}
        InputProps={inputProps}
        {...props}
    />;
};

export default InputPassword;
