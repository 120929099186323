import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../routes/app';
import legacy from '../routes/legacy';

const AppRoutes: FunctionComponent = () => (
    <Switch>
        {legacy.map(route => <Route {...route} key={route.path as string}/>)}
        {routes.map(route => <Route {...route} key={route.path as string} />)}
    </Switch>
);

export default AppRoutes;
