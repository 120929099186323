import React from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import config from '../../../config';
import RouterLazyLoader from '../loaders/RouterLazyLoader';
import { browserStorage, tabStorage } from '../../../state/utils';
import { authOperations, authSelectors, RootState, TabUser } from '../../../state/ducks/auth';

type State = {
    loading: boolean,
    actingAsFailed: boolean,
}

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

const connector = connect((state: RootState) => ({
    isUber: authSelectors.isUber(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
}), {
    actingAs: authOperations.actingAs,
    handshake: authOperations.handshake,
    handshakeLite: authOperations.handshakeLite,
});

class HandShake extends React.PureComponent<Props, State> {
    private excludeHandshake = [
        '/logout',
    ];

    state = {
        loading: true,
        actingAsFailed: false,
    }

    componentDidMount() {
        const user = tabStorage.user();
        const activeAt = browserStorage.activeAt();

        if (user) {
            return this.actingAs(user);
        }

        if (this.shouldHandshake(activeAt)) {
            return this.handshake();
        }

        if (this.shouldHandshakeLite()) {
            return this.handshakeLite();
        }

        this.ready();
    }

    actingAs = (user: TabUser) => {
        this.props.actingAs(user.id, user.type, this.ready, () => {
            this.setState({ actingAsFailed: true });
        });
    }

    shouldHandshake = (activeAt: string | null): boolean => {
        const { isAuthenticated, location: { pathname } } = this.props;

        const wasLatelyNotActive = activeAt === null
            || moment().unix() - parseInt(activeAt) > config.allowHandshakeIfUserNotActiveBySeconds;

        return isAuthenticated
            && wasLatelyNotActive
            && ! ~this.excludeHandshake.indexOf(pathname);
    }

    handshake = () => {
        this.props.handshake(this.ready);
    }

    shouldHandshakeLite = (): boolean => {
        const { isAuthenticated, isUber, location: { pathname } } = this.props;

        return isAuthenticated
            && ! isUber
            && ! ~this.excludeHandshake.indexOf(pathname);
    }

    handshakeLite = () => {
        this.props.handshakeLite(this.ready);
    }

    ready = () => {
        this.setState({ loading: false });
    }

    render() {
        if (this.state.actingAsFailed) {
            return null;
        }

        if (this.state.loading) {
            return <RouterLazyLoader/>;
        }

        return this.props.children;
    }
}

export default withRouter(connector(HandShake));
