import React, { FunctionComponent } from 'react';
import Main from '../components/layout/Main';
import Container from '../components/layout/Container';
import PublicLightHeader from './common/PublicLightHeader';
import PublicLightFooter from './common/PublicLightFooter';
import CenterventionPartners from './common/CenterventionPartners';
import AccountRequest from '../components/actions/public/account-request/AccountRequest';
import styles from './_public.module.sass';

const AccountRequestView: FunctionComponent = () => (
    <Container>
        <PublicLightHeader/>
        <Main classes={styles.centeredBody}>
            <AccountRequest/>
        </Main>
        <CenterventionPartners/>
        <PublicLightFooter/>
    </Container>
);

export default AccountRequestView;
