import moment from 'moment';
import { Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import utils from './utils';
import actions from './actions';
import mappers from './segments/mappers';
import relations from './segments/relations';
import downloadCsv from '../../../api/download-csv';
import downloadXlsx from '../../../api/download-xlsx';
import exceptionTracker from '../../../exception-tracker';
import { apiTabulator, freshCancelTokenSource } from '../../../api';
import { Column, DownloadSpreadsheetMode } from '../../../views/components/tabulator';
import {
    SUBSET,
    RootState,
    TabulatorFilter,
    tabulatorSelectors,
    TabulatorSortingDir,
    TabulatorFilterAutocompleteOption,
} from './index';
import {
    ApiItem,
    TabulatorPage,
    TabulatorName,
    AppThunkAction,
    TabulatorSegment,
    TabulatorSingleNameState,
} from './types';

const fetchPage = (
    name: TabulatorName,
    page: TabulatorPage = 1
): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.pageChange(name, page));
    const tabulator = getState().tabulator[name];

    if (shouldFetchFresh(tabulator)) {
        await fetch(name, dispatch, getState, 0, false, (mapper, data, filtered, total, totalFromCache, at) => {
            dispatch(actions.fetchFresh(name, mapper, data, filtered, total, totalFromCache, at));
        });
        return;
    }

    if (alreadyHasPageData(tabulator)) {
        abort(tabulator);
        dispatch(actions.loadingCommit(name));
        return;
    }

    await fetch(name, dispatch, getState, 0, false, (mapper, data, filtered, total, totalFromCache, at) => {
        dispatch(actions.fetchPage(name, mapper, data, filtered, total, totalFromCache, at));
    });
};

const fetchFresh = (
    name: TabulatorName,
    page: TabulatorPage = 1,
    delay: number = 0,
    refreshView: boolean = false
): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.pageChange(name, page));

    await fetch(name, dispatch, getState, delay, refreshView, (mapper, data, filtered, total, totalFromCache, at) => {
        dispatch(actions.fetchFresh(name, mapper, data, filtered, total, totalFromCache, at));
    });
};

const fetch = async (
    name: TabulatorName,
    dispatch: Dispatch,
    getState: () => RootState,
    delay: number,
    refreshView: boolean = false,
    callback: (mapper: any, data: any, filtered: number, total: number, totalFromCache: boolean, at: number) => void
) => {
    abort(getState().tabulator[name]);
    dispatch(actions.loadingBegin(name, freshCancelTokenSource()));

    const tabulator = getState().tabulator[name];
    const { segment, endpoint, filters, selected } = tabulator;
    const mapper = mappers[segment];

    const path = !! filters[SUBSET] ? `${endpoint}/subset`: endpoint;

    const request = (): Promise<AxiosResponse> => {
        if (!! filters[SUBSET]) {
            const data = { _ids: Object.values(selected).map(v => v.id) };
            return apiTabulator.post(path, data, config(tabulator, delay, refreshView));
        }

        return apiTabulator.get(path, config(tabulator, delay, refreshView));
    };

    try {
        const response = await request();
        const { data, filtered, total, total_from_cache = false } = response.data;
        const at = moment().unix();
        callback(mapper, data, filtered, total, total_from_cache, at);
        dispatch(actions.loadingCommit(name));
    } catch (e) {
        if (! axios.isCancel(e)) {
            exceptionTracker.info(e, path);
            dispatch(actions.loadingCommit(name));
        }
    }
};

const refreshWithRelations = (name: TabulatorName | false): AppThunkAction => async (dispatch, getState) => {
    if (! name) {
        return;
    }

    const state = getState().tabulator;
    const segment = state[name].segment;
    const related = relations[segment as TabulatorSegment];
    const tabulatorNames = Object.keys(state);

    const relatedTabulatorNames = tabulatorNames.filter(tabulatorName => {
        const tabulatorNameSegment = state[tabulatorName].segment;
        return ~related.indexOf(tabulatorNameSegment as never) || segment === tabulatorNameSegment;
    });

    relatedTabulatorNames.forEach(tabulatorName => dispatch(actions.forceFresh(tabulatorName)));

    const freshState = getState().tabulator;
    const refresh = relatedTabulatorNames.find(tabulatorName => freshState[tabulatorName].isCurrent);

    refresh && await dispatch(fetchFresh(refresh, freshState[refresh].page));
};

const download = (
    name: TabulatorName,
    columns: Column[],
    downloadMode: DownloadSpreadsheetMode,
): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.downloadingBegin(name));

    const tabulator = getState().tabulator[name] as TabulatorSingleNameState;
    const { segment, endpoint: path, filters, selected } = tabulator;
    const mapper = mappers[segment];

    const request = (): Promise<AxiosResponse> => {
        const params = { params: { _download: 1, ...config(tabulator, 0).params } };

        if (!! filters[SUBSET]) {
            const data = { _ids: Object.values(selected).map(v => v.id) };
            return apiTabulator.post(path, data, params);
        }

        return apiTabulator.get(path, params);
    };

    try {
        const response = await request();
        downloadMode === 'csv' && downloadCsv(name, response.data.data, columns, mapper, () => {
            dispatch(actions.downloadingCommit(name));
        });
        downloadMode === 'xlsx' && downloadXlsx(name, response.data.data, columns, mapper, () => {
            dispatch(actions.downloadingCommit(name));
        });
    } catch (e) {
        if (! axios.isCancel(e)) {
            exceptionTracker.info(e, path);
            dispatch(actions.downloadingCommit(name));
        }
    }
};

const selectAllFiltered = (name: TabulatorName, perPage: number): AppThunkAction => async (dispatch, getState) => {
    if (tabulatorSelectors.hasSelectedAll(getState(), name)) {
        return;
    }

    const tabulator = getState().tabulator[name];

    const fetchSelectAll = async () => {
        if (tabulator.filtered <= perPage) {
            return Promise.resolve(Object.values(tabulator.byId) as ApiItem[]);
        }

        dispatch(actions.loadingBegin(name, freshCancelTokenSource()));

        const freshTabulator = getState().tabulator[name];
        const path = `${freshTabulator.endpoint}/select-all`;

        try {
            const response = await apiTabulator.get(path, config(freshTabulator, 0));
            dispatch(actions.loadingCommit(name));
            return response.data.data;
        } catch (e) {
            exceptionTracker.info(e, path);
            dispatch(actions.loadingCommit(name));
            return [];
        }
    };

    const toSelect = await fetchSelectAll();

    if (! toSelect.length) {
        return;
    }

    await dispatch(actions.selectAll(name, toSelect));

    const nextTabulator = getState().tabulator[name];
    const nextSelected = Object.values(nextTabulator.selected).length;

    if (tabulatorSelectors.hasSelectedAll(getState(), name) && nextSelected < tabulator.filtered) {
        utils.limitNotification(nextTabulator.selectableLimit);
    }

    if (nextTabulator.filters.hasOwnProperty(SUBSET)) {
        dispatch(fetchFresh(name));
    }
};

const selectCurrentPage = (name: TabulatorName): AppThunkAction => async (dispatch, getState) => {
    const tabulator = getState().tabulator[name];

    const toSelect = (Object.values(tabulator.byId) as ApiItem[]).filter(item => {
        return tabulator.pages[tabulator.page].includes(item.id);
    });

    await dispatch(actions.selectAll(name, toSelect));
};

const deselectAll = (name: TabulatorName): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.deselectAll(name));

    if (getState().tabulator[name].filters.hasOwnProperty(SUBSET)) {
        dispatch(filterChange(name, { [SUBSET]: '' }));
        dispatch(fetchFresh(name));
    }
};

const nameActivate = (
    tabIdentifier: any,
    group: any,
    name: TabulatorName,
    filters: TabulatorFilter,
    sorting?: { column: string, first: TabulatorSortingDir },
): AppThunkAction => async (dispatch, getState) => {
    const state = getState().tabulator;

    const isNewSorting = ! (
        name in state
        && sorting
        && sorting.column in state[name].sorting && state[name].sorting[sorting.column] === sorting.first
    );

    if (name in state) {
        sorting && isNewSorting && dispatch(sortingChange(name, sorting.column, sorting.first));
        dispatch(filterClearAll(name));
        dispatch(filterChange(name, filters));
        dispatch(actions.forceFresh(name));
        dispatch(fetchFresh(name));
    }

    dispatch(actions.nameActivate(
        tabIdentifier,
        group,
        name,
        filters,
        sorting && isNewSorting ? { [sorting.column]: sorting.first } : undefined,
    ));
};

const nameRemove = (name: TabulatorName): AppThunkAction => async (dispatch, getState) => {
    abort(getState().tabulator[name]);

    dispatch(actions.nameRemove(name));
};

const config = (tabulator: TabulatorSingleNameState, delay: number, refreshView: boolean = false) => {
    const { page, filters, sorting, loading } = tabulator;

    const cache = refreshView ? { _refresh_view: 1 } : {};

    const sort = Object.keys(sorting).reduce((obj, key) => (
        { _sort: `${sorting[key] === 'desc' ? '-' : ''}${key}` }
    ), {});

    const filter = Object.keys(filters).reduce((obj, key) => {
        return {
            ...obj,
            [key]: typeof filters[key] === 'object'
                ? (filters[key] as TabulatorFilterAutocompleteOption).id
                : filters[key],
        };
    }, {});

    const cancelToken = loading ? { cancelToken: loading.token } : {};

    return {
        params: { ...{ _page: page }, ...cache, ...sort, ...filter },
        ...cancelToken,
        delay,
    };
};

const abort = (tabulator: TabulatorSingleNameState) => {
    const { loading } = tabulator;
    loading && loading.cancel();
};

const shouldFetchFresh = (tabulator: TabulatorSingleNameState): boolean => {
    const forceFreshFetchAfter = tabulator.forceFreshFetchAfter;
    const lastFreshFetchAt = tabulator.lastFreshFetchAt;

    return tabulator.forceFresh || lastFreshFetchAt + forceFreshFetchAfter <= moment().unix();
};

const alreadyHasPageData = (tabulator: TabulatorSingleNameState): boolean => {
    return !! tabulator.pages[tabulator.page];
};

const filterChange = (name: TabulatorName, filter: TabulatorFilter): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.filterChange(name, filter));

    getState().tabulator[name].shareFiltersWith.forEach((shareWith: TabulatorName) => {
        if (filter.hasOwnProperty(SUBSET) && ! tabulatorSelectors.isSelectable(getState(), shareWith)) return;

        dispatch(actions.filterChange(shareWith, filter));
        dispatch(actions.forceFresh(shareWith));
    });
};

const filterClearAll = (name: TabulatorName): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.filterClearAll(name));

    getState().tabulator[name].shareFiltersWith.forEach((shareWith: TabulatorName) => {
        dispatch(actions.filterClearAll(shareWith));
        dispatch(actions.forceFresh(shareWith));
    });
};

const sortingChange = (
    name: TabulatorName,
    column: string,
    first: TabulatorSortingDir
): AppThunkAction => async (dispatch, getState) => {
    dispatch(actions.sortingChange(name, column, first));

    getState().tabulator[name].shareFiltersWith.forEach((shareWith: TabulatorName) => {
        dispatch(actions.sortingChange(shareWith, column, first));
        dispatch(actions.forceFresh(shareWith));
    });
};

export default {
    nameAdd: actions.nameAdd,
    fetchPage,
    fetchFresh,
    refreshWithRelations,
    download,
    filterChange,
    filterClearAll,
    sortingChange,
    setCurrent: actions.setCurrent,
    forceFresh: actions.forceFresh,
    selectAllFiltered,
    selectCurrentPage,
    deselectAll,
    nameActivate,
    nameRemove,
    selectRow: actions.selectRow,
    selectBulk: actions.selectBulk,
    signOut: actions.signOut,
};
