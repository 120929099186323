import { AnyObject } from 'final-form';
import { CV, Programs } from '../programs';
import {
    InputSwitchGroup,
    AutocompleteOption,
    InputEducatorAccount,
    InputTeamParticipant,
} from '../views/components/form/types';

const extractFormErrorsOrFail = (error: any, formRegisteredFields: string[] = []): object => {
    if (! error || ! error.hasOwnProperty('response')) {
        throw error;
    }

    if (! error.response?.data.hasOwnProperty('errors')) {
        throw error;
    }

    return mapFieldsToFormValidation(error.response.data.errors, formRegisteredFields);
};

const mapFieldsToFormValidation = (errors: any, formRegisteredFields: string[] = []): object => {
    return Object.keys(errors).reduce((result, name) => {
        const mappedName = name.replace('_id', '_selected');
        const errorName = formRegisteredFields.includes(mappedName) ? mappedName : name;

        return { ...result, [errorName]: errors[name] };
    }, {});
};

const mapToApiValues = <FormValues extends AnyObject>(values: FormValues) => {
    return Object.keys(values).reduce((result, name) => {
        if (typeof values[name] === 'object' && name === 'accounts') {
            const accounts = values[name]
                .filter((account: InputEducatorAccount) => {
                    return account.connect && account.school_selected && account.school_selected.hasOwnProperty('id');
                })
                .map((account: { school_selected: AutocompleteOption, role: string }) => {
                    return { id: account.school_selected.id, role: account.role };
                });

            return { ...result, [name]: accounts };
        }

        if (typeof values[name] === 'object' && name === 'team_participants') {
            const admins = values[name]
                .filter((educator: InputTeamParticipant) => {
                    return educator.connect
                        && educator.educator_selected
                        && educator.educator_selected.hasOwnProperty('id')
                        && educator.role === 'admin';
                })
                .map((educator: { educator_selected: AutocompleteOption }) => {
                    return educator.educator_selected.id;
                });

            const members = values[name]
                .filter((educator: InputTeamParticipant) => {
                    return educator.connect
                        && educator.educator_selected
                        && educator.educator_selected.hasOwnProperty('id')
                        && educator.role === 'member';
                })
                .map((educator: { educator_selected: AutocompleteOption }) => {
                    return educator.educator_selected.id;
                });

            return { ...result, admins, members };
        }

        if (typeof values[name] === 'object'
            && Array.isArray(values[name])
            && values[name].length
            && typeof values[name][0] === 'object'
            && 'name' in values[name][0]
            && 'value' in values[name][0]
            && (
                name === 'interested_in' ||
                name === 'usage' ||
                name === 'skills' ||
                name === 'grade_levels' ||
                name === 'prep_levels' ||
                name === 'challenges'
            )
        ) {
            return { ...result, [name]: values[name].filter((value: InputSwitchGroup) => {
                return value.value;
            }).map((value: InputSwitchGroup) => {
                return value.name;
            })};
        }

        if (typeof values[name] === 'object' && Array.isArray(values[name])) {
            return { ...result, [name]: values[name] };
        }

        if (typeof values[name] === 'object' && values[name] !== null) {
            return { ...result, [name.replace('_selected', '_id')]: values[name].id };
        }

        return { ...result, [name]: values[name] };
    }, {});
};

const mapToApiFormData = <FormValues extends AnyObject>(values: FormValues, addLaravelPatchMethod: boolean = false) => {
    const formData = Object.keys(values).reduce((formData, name) => {
        if (values[name] instanceof File) {
            formData.append(name, values[name]);
            return formData;
        }

        const mapped = mapToApiValues({ [name]: values[name] });
        const key = Object.keys(mapped)[0];
        const value = Object.values(mapped)[0];

        if (Array.isArray(value)) {
            value.forEach(item => {
                formData.append(key + '[]', item)
            })
            return formData;
        }

        formData.append(key, value as string);
        return formData;
    }, new FormData());

    if (addLaravelPatchMethod) {
        formData.append('_method', 'PATCH')
    }

    return formData;
};

const openExternalUrl = (url: string) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
};

const url = {
    edit: (endpoint: string, id: any) => `${endpoint}/${id}/edit`,
    patch: (endpoint: string, id: any) => `${endpoint}/${id}`,
    scoped: (endpoint: string, id: any) => `${endpoint}/${id}`,
    actingAs: (endpoint: string, type: string, id: any) => `${endpoint}/${type}/${id}`,
    play: (homePath: string, endpoint: string, program: Programs | CV | undefined) => {
        return program ? `${homePath}/${program}/${endpoint}` : `${homePath}/${endpoint}`;
    },
    withParams: (endpoint: string, params: object) => {
        const query = Object.keys(params).map((key) => `${key}=${(params as any)[key]}`);

        return `${endpoint}?${query.join('&')}`;
    },
};

export {
    extractFormErrorsOrFail,
    mapFieldsToFormValidation,
    mapToApiValues,
    mapToApiFormData,
    openExternalUrl,
    url,
};
