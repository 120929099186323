const resolveDomain = (): { domain: string, isSandbox: boolean } => {
    const production = process.env.REACT_APP_PRODUCTION_DOMAIN as string;
    const sandbox = process.env.REACT_APP_SANDBOX_DOMAIN || '';
    const hostname = window.location.hostname;

    if (sandbox.length > 0 && (hostname === sandbox || hostname.includes(`.${sandbox}`))) {
        return {
            domain: sandbox,
            isSandbox: true,
        };
    }

    return {
        domain: production,
        isSandbox: false,
    };
};

export default resolveDomain;
