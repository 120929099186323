import React, { FunctionComponent } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Config } from '../types';
import messages from './messages';
import errorHandler from './errorHandler';

type Props = {
    config: Config,
}

const IntlProvider: FunctionComponent<Props> = ({ config, children }) => (
    <ReactIntlProvider
        locale={config.i18n}
        messages={messages[config.i18n]}
        onError={errorHandler}
    >
        {children}
    </ReactIntlProvider>
);

export default IntlProvider;
