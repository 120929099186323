import axios from 'axios';
import interceptors from './interceptors';
import { AxiosRequestConfigWithDelay, AxiosRequestConfigWithDelayOrPromise } from '../types';

const apiPlay = axios.create({
    baseURL: '/api/',
});

apiPlay.interceptors.response.use(
    response => response,
    error => interceptors.response.handlePlayErrors(error)
);

apiPlay.interceptors.request.use((config: AxiosRequestConfigWithDelay): AxiosRequestConfigWithDelayOrPromise => {
    return interceptors.activeAt(config);
});

export default apiPlay;
