import actions from './actions';
import { mapEcommerce } from './mappers';
import { AppThunkAction } from '../../types';
import { apiEcommerce, endpoints } from '../../../api';
import exceptionTracker from '../../../exception-tracker';

const fetchFresh = (callback: () => void): AppThunkAction => async (dispatch) => {
    const path = endpoints.app.ecommerce;

    try {
        const response = await apiEcommerce(path);
        const ecommerce = mapEcommerce(response.data.data);
        dispatch(actions.fetchFresh(ecommerce));
        callback();
    } catch (e) {
        exceptionTracker.info(e, path);
        callback();
    }
};

export default {
    signIn: actions.signIn,
    signOut: actions.signOut,
    actingAs: actions.actingAs,
    fetchFresh,
};
