import React, { FunctionComponent } from 'react';
import Modals from '../../components/modals/Modals';
import { ModalPublicType } from '../../../state/ducks/modals';
import Login from '../../components/actions/public/login/Login';
import SetNewPassword from '../../components/actions/public/set-new-password/SetNewPassword';
import ResetMyPassword from '../../components/actions/public/reset-my-password/ResetMyPassword';

type Modals = {
    [key in ModalPublicType]: React.ComponentType<any>
}

const modals: Modals = {
    login: Login,
    'reset-my-password': ResetMyPassword,
    'set-new-password': SetNewPassword,
};

const PublicModals: FunctionComponent = () => {
    return <Modals components={modals} canBeHidden={false} />;
};

export default PublicModals;
