const resolveVersion = (): string => {
    const version = (window as any).version;

    if (version === '___version___') {
        return '';
    }

    return version;
};

export default resolveVersion;
