import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { authSelectors, RootState } from '../../state';

const connector = connect((state: RootState) => ({
    isEducator: authSelectors.isEducator(state),
}));

class AccountActivatedView extends React.Component<ConnectedProps<typeof connector> & RouteComponentProps> {
    componentDidMount() {
        this.props.history.push('/');
    }

    render() {
        return null;
    }
}

export default connector(withRouter(AccountActivatedView));
