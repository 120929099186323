import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import exceptionTracker from '../../../exception-tracker';
import LinkButton from '../../components/common/LinkButton';
import PageLoader from '../../components/loaders/PageLoader';
import Notification from '../../components/common/Notification';
import { ecommerceOperations } from '../../../state/ducks/ecommerce';
import { authOperations, authSelectors, RootState } from '../../../state';
import {
    apiLogin,
    endpoints,
    pageResponder,
    PageResponder,
    PageResponderType,
    PageResponderNotification,
} from '../../../api';

type Props = RouteComponentProps<{
    session: string,
}>

type State = {
    loading: boolean,
    responder: PageResponder<PageResponderType>,
}

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
    isParent: authSelectors.isParent(state),
}), {
    login: authOperations.login,
    ecommerceFetchFresh: ecommerceOperations.fetchFresh,
});

class PaymentCompleted extends React.Component<Props & ConnectedProps<typeof connector>, State> {
    state = {
        loading: true,
        responder: pageResponder(),
    }

    async componentDidMount() {
        const path = endpoints.app.paymentCompleted;

        try {
            const data = { session: this.props.match.params.session };
            const response = await apiLogin.post(path, data);

            response.data.access_token
                ? await this.completeNewUser(response.data)
                : await this.completeExistingUser(response.data);
        } catch (e) {
            exceptionTracker.info(e, path);
        }

        this.setState({ loading: false });
    }

    async completeNewUser(data: any) {
        const { login, history } = this.props;

        login(data);
        history.push(`/parent/payment-completed`);
    }

    async completeExistingUser(data: any) {
        this.props.isAuthenticated && await this.props.ecommerceFetchFresh(() => {});
        const responder = pageResponder(data);
        this.setState({ responder });
        this.redirectIfParent();
    }

    redirectIfParent() {
        const { isAuthenticated, isParent, history } = this.props;
        const { responder } = this.state;

        if (isAuthenticated && isParent && responder.data?.type === 'success') {
            history.push(`/parent/payment-completed`);
        }
    }

    render() {
        if (this.state.loading) {
            return <PageLoader/>;
        }

        const { isAuthenticated, homePath } = this.props;

        const action = {
            to: isAuthenticated ? `${homePath}/manage-students` : homePath,
            i18n: isAuthenticated ? 'manage-students' : 'login',
        };

        if (! this.state.responder.isNotification) {
            return <Notification type="warning" actions={<LinkButton {...action}/>}>
                <FormattedMessage id={`notification.on-page.payment-unknown`}/>
            </Notification>;
        }

        const responder = this.state.responder as PageResponder<PageResponderNotification>;
        const values = { ...responder.data.values, br: <br/> };

        return <Notification type={responder.data.type} actions={<LinkButton {...action}/>}>
            <FormattedMessage id={`notification.on-page.${responder.data.i18n}`} values={values}/>
        </Notification>;
    }
};

export default withRouter(connector(PaymentCompleted));
