import config, { mode } from './config';
import * as Sentry from '@sentry/browser';
import { browserStorage } from './state/utils';

(window as any).Sentry = Sentry;

const exceptionTracker = {
    init: (dsn?: string) => {
        if (mode.isBuildSnapshot()) return;

        Sentry.init({
            dsn: dsn || config.sentryDSN.app,
            environment: config.isSandbox ? 'sandbox' : config.env,
            ignoreErrors,
            beforeSend,
        });

        exceptionTracker.setSpaVersion();
        exceptionTracker.setUser();
    },

    info: (e: Error, path: string) => {
        e.message = `[info] ${e.message}: ${path}`;
        ! config.envIsProduction && ! config.envIsTest && console.info(e);
        Sentry.withScope(scope => {
            scope.setLevel(Sentry.Severity.Info);
            Sentry.captureException(e);
        });
    },

    console: (e: Error) => {
        if (mode.isBuildSnapshot()) return;

        console.error(e);
    },

    setSpaVersion: () => {
        Sentry.configureScope(scope => {
            scope.setTag('spaVersion', config.version);
        });
    },

    setGameVersion: (gameVersion: string) => {
        Sentry.configureScope(scope => {
            scope.setTag('gameVersion', gameVersion);
        });
    },

    setUser: (actingAs: { username: string } | undefined = undefined) => {
        exceptionTracker.clearUser();

        const accessToken = browserStorage.accessToken();

        if (! accessToken.original) {
            return;
        }

        const user = browserStorage.user();
        const email = actingAs ? `${user.username} | ${actingAs.username}` : user.username;

        Sentry.configureScope(scope => {
            scope.setUser({ email });
            scope.setTag('isActingAsAnotherUser', actingAs ? 'yes' : 'no');
            scope.setExtra('accessToken', { ...accessToken, original: '...' });
            scope.setExtra('user', user);

            if (actingAs) {
                scope.setExtra('actingAs', actingAs);
            }
        });
    },

    clearUser: () => Sentry.configureScope(scope => {
        scope.setUser(null);
        scope.setTag('isActingAsAnotherUser', null);
        scope.setExtra('accessToken', null);
        scope.setExtra('user', null);
        scope.setExtra('actingAs', null);
    }),
};

const ignoreErrors = [
    // permanent
    'Request failed with status code 400',
    'Request failed with status code 401',
    'Request failed with status code 422',
    'Request failed with status code 429',
    'Request aborted',
    'Network Error',
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',

    // temporary
    'getElementById', // godot games integration
    'getBoundingClientRect', // godot games integration
    'Cannot read property \'stage\' of null', // hoh
    'function signature mismatch', // academy
    'Cannot read property \'scaleX\' of null', // academy
    'null is not an object (evaluating \'moa.game.stageManager.stage\')', // hoh
];

export const beforeSend = function(event: Sentry.Event) {
    if (event.request && event.request.url && !! ~event.request.url.indexOf('studentManagement')) return null;
    if (event.request && event.request.url && !! ~event.request.url.indexOf('manageStudents')) return null;
    if (event.request && event.request.url && !! ~event.request.url.indexOf('manageChildren')) return null;
    if (event.request && event.request.url && !! ~event.request.url.indexOf('childrenProgress')) return null;

    if (event.exception
        && event.exception.values
        && event.exception.values[0].stacktrace
        && event.exception.values[0].stacktrace.frames
        && event.exception.values[0].stacktrace.frames[0].filename
        && (
            !! ~event.exception.values[0].stacktrace.frames[0].filename.indexOf('chrome-extension://') ||
            !! ~event.exception.values[0].stacktrace.frames[0].filename.indexOf('/C:/Users/') ||
            !! ~event.exception.values[0].stacktrace.frames[0].filename.indexOf('/D:/')
        )
    ) return null;

    return event;
};

export default exceptionTracker;
