import types, {
    ModalStatus,
    ModalSettings,
    ModalHideAction,
    ModalOpenAction,
    ModalShowAction,
    ModalCloseAction,
    ModalSignOutAction,
    ModalUpdateTitleAction,
    ModalUpdateStatusAction,
} from './types';

const open = (
    {
        name,
        type,
        id = undefined,
        tabulator = undefined,
        tabulatorRowCopy = undefined,
    }: ModalSettings,
    extra?: any,
): ModalOpenAction => ({
    type: types.MODAL_OPEN,
    payload: { name, type, id, tabulator, tabulatorRowCopy, extra },
});

const hide = (name: string): ModalHideAction => ({
    type: types.MODAL_HIDE,
    payload: { name },
});

const show = (name: string): ModalShowAction => ({
    type: types.MODAL_SHOW,
    payload: { name },
});

const close = (name: string): ModalCloseAction => ({
    type: types.MODAL_CLOSE,
    payload: { name },
});

const updateStatus = (name: string, status: ModalStatus): ModalUpdateStatusAction => ({
    type: types.MODAL_UPDATE_STATUS,
    payload: { name, status },
});

const updateTitle = (name: string, title: string): ModalUpdateTitleAction => ({
    type: types.MODAL_UPDATE_TITLE,
    payload: { name, title },
});

const signOut = (accessToken: any, user: any): ModalSignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

export default {
    open,
    hide,
    show,
    close,
    updateStatus,
    updateTitle,
    signOut,
};
