import axios, { CancelTokenSource } from 'axios';
import { AccessToken, ActingAsUser } from '../state/ducks/auth';

import apiPlay from './axios/api-play';
import apiHome from './axios/api-home';
import apiLogin from './axios/api-login';
import apiLogout from './axios/api-logout';
import apiActions from './axios/api-actions';
import apiActingAs from './axios/api-acting-as';
import apiTabulator from './axios/api-tabulator';
import apiHandshake from './axios/api-handshake';
import apiEcommerce from './axios/api-ecommerce';
import apiAutocomplete from './axios/api-autocomplete';

const applyAccessToken = (accessToken: AccessToken) => {
    apiPlay.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiHome.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiLogout.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiActions.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiActingAs.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiTabulator.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiHandshake.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiEcommerce.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
    apiAutocomplete.defaults.headers['Access-Token'] = `Bearer ${accessToken.original}`;
};

const applyActingAs = (actingAs: ActingAsUser) => {
    apiPlay.defaults.headers['X-Acting-As'] = actingAs.id;
    apiHome.defaults.headers['X-Acting-As'] = actingAs.id;
    apiActions.defaults.headers['X-Acting-As'] = actingAs.id;
    apiTabulator.defaults.headers['X-Acting-As'] = actingAs.id;
    apiEcommerce.defaults.headers['X-Acting-As'] = actingAs.id;
    apiAutocomplete.defaults.headers['X-Acting-As'] = actingAs.id;
};

const clearActingAs = () => {
    delete apiPlay.defaults.headers['X-Acting-As'];
    delete apiHome.defaults.headers['X-Acting-As'];
    delete apiActions.defaults.headers['X-Acting-As'];
    delete apiTabulator.defaults.headers['X-Acting-As'];
    delete apiEcommerce.defaults.headers['X-Acting-As'];
    delete apiAutocomplete.defaults.headers['X-Acting-As'];
};

const freshCancelTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
};

export {
    apiPlay,
    apiHome,
    apiLogin,
    apiLogout,
    apiActions,
    apiActingAs,
    apiTabulator,
    apiHandshake,
    apiEcommerce,
    applyActingAs,
    clearActingAs,
    apiAutocomplete,
    applyAccessToken,
    freshCancelTokenSource,
};
