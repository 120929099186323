import axios from 'axios';
import interceptors from './interceptors';

const apiActingAs =  axios.create({
    baseURL: '/api/',
});

apiActingAs.interceptors.response.use(
    response => response,
    error => interceptors.response.handleActingAsErrors(error)
);

export default apiActingAs;
