import React, { ReactText } from 'react';
import { SnackbarProvider } from 'notistack';
import { connect, ConnectedProps } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import config from '../../../config';
import { RootState } from '../../../state';
import { UtilsSnackbarConfigurator } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import styles from './_snackbars.module.sass';

const connector = connect((state: RootState) => ({
    lastTab: state.ui.lastTab,
}));

class Snackbars extends React.Component<RouteComponentProps & ConnectedProps<typeof connector>> {
    private ref = React.createRef<{ closeSnackbar: (key: ReactText) => void }>();
    private snackbarCallbacks: any[] = [];
    private listener: any;

    constructor(props: RouteComponentProps & ConnectedProps<typeof connector>) {
        super(props);

        this.handleDismiss = this.handleDismiss.bind(this);
        this.snackbarCallback = this.snackbarCallback.bind(this);
        this.http401Callback = this.http401Callback.bind(this);
    }

    componentDidMount() {
        this.listener = this.props.history.listen(location => {
            if (location.pathname !== '/') {
                this.snackbarCallbacks.forEach(callback => callback());
                this.snackbarCallbacks = [];
            }
        });
    }

    componentWillUnmount() {
        this.listener();
    }

    shouldComponentUpdate(nextProps: Readonly<RouteComponentProps & ConnectedProps<typeof connector>>): boolean {
        if (this.props.lastTab !== nextProps.lastTab && this.props.history.location.pathname !== '/') {
            this.snackbarCallbacks.forEach(callback => callback());
            this.snackbarCallbacks = [];
        }

        return false;
    }

    handleDismiss(key: ReactText) {
        this.ref?.current?.closeSnackbar(key);
    };

    snackbarCallback(snackbarCloseCallback: () => void) {
        this.snackbarCallbacks.push(snackbarCloseCallback);
    };

    http401Callback() {
        this.props.history.push('/logout');
    };

    render() {
        return <SnackbarProvider
            ref={this.ref}
            maxSnack={config.snackbars.maximumSnacksOnPage}
            autoHideDuration={config.snackbars.autoHideDuration}
            action={(key: ReactText) => (
                <IconButton onClick={() => this.handleDismiss(key)}>
                    <FontAwesomeIcon icon={faTimes} className={styles.dismissIcon} />
                </IconButton>
            )}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            iconVariant={{
                success: <FontAwesomeIcon icon={faCheck} className={styles.icon}/>,
                error: <FontAwesomeIcon icon={faExclamation} className={styles.icon}/>,
                warning: <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon}/>,
                info: <FontAwesomeIcon icon={faInfo} className={styles.icon}/>,
            }}
        >
            <UtilsSnackbarConfigurator snackbarCallback={this.snackbarCallback} http401Callback={this.http401Callback}/>
        </SnackbarProvider>;
    }
};

export default withRouter(connector(Snackbars));
