import axios from 'axios';
import interceptors from './interceptors';
import { AxiosRequestConfigWithDelay, AxiosRequestConfigWithDelayOrPromise } from '../types';

const apiEcommerce =  axios.create({
    baseURL: '/api/',
});

apiEcommerce.interceptors.request.use((config: AxiosRequestConfigWithDelay): AxiosRequestConfigWithDelayOrPromise => {
    return interceptors.activeAt(config);
});

export default apiEcommerce;
