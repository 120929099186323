import React, { SyntheticEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import MaterialDialog from '@material-ui/core/Dialog';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import DialogContext from './DialogContext';
import { modalsOperations, RootState } from '../../../state';

type Props = {
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    name: string,
    canBeHidden: boolean,
    disableClose: boolean,
    onExitedCallback?: () => void,
    onClose?: () => void,
    stickToTop?: boolean,
    fullHeight?: boolean,
    children: React.ReactNode,
}

const connector = connect((state: RootState, props: Props) => ({
    isOpen: state.modals[props.name]?.isOpen || false,
}), {
    hide: modalsOperations.hide,
    close: modalsOperations.close,
});

class Dialog extends React.PureComponent<Props & WithStyles & ConnectedProps<typeof connector>> {
    private closeOnExited = false;
    constructor(props: Props & WithStyles & ConnectedProps<typeof connector>) {
        super(props);
        this.handleHide = this.handleHide.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOnExited = this.handleOnExited.bind(this);
        this.handleEscapeKeyDown = this.handleEscapeKeyDown.bind(this);
    }

    handleHide() {
        this.props.canBeHidden && this.props.hide(this.props.name);
    }

    handleClose() {
        this.closeOnExited = true;
        this.props.hide(this.props.name);
        this.props.onClose && this.props.onClose();
    }

    handleOnExited() {
        this.closeOnExited
            && this.props.close(this.props.name)
            && this.props.onExitedCallback && this.props.onExitedCallback();
    }

    handleEscapeKeyDown(e: SyntheticEvent) {
        e.nativeEvent.stopImmediatePropagation();
        this.handleHide();
    }

    render() {
        const { isOpen, size, canBeHidden, disableClose, children, classes, stickToTop, fullHeight } = this.props;
        const dialogContext = {
            canBeHidden,
            disableClose,
            handleHide: this.handleHide,
            handleClose: this.handleClose,
        };

        const dialogClasses: any = {};
        if (stickToTop) dialogClasses.scrollPaper = classes.scrollPaper;
        if (fullHeight) dialogClasses.paper = classes.paper;

        return <DialogContext.Provider value={dialogContext}>
            <MaterialDialog
                open={isOpen}
                maxWidth={size}
                fullWidth={!! size}
                scroll="paper"
                onExited={this.handleOnExited}
                onEscapeKeyDown={this.handleEscapeKeyDown}
                classes={dialogClasses}
            >
                {children}
            </MaterialDialog>
        </DialogContext.Provider>;
    }
};

const classes = {
    scrollPaper: { alignItems: 'flex-start' },
    paper: { height: '100vh' },
};

export default connector(withStyles(classes)(Dialog));
