const parent = {
    changePassword: 'parent/change-password',
    purchaseHistory: 'parent/purchase-history',
    lessons: {
        lessons: 'parent/lessons',
        myLesson: 'parent/lessons/my-lesson',
    },
    children: {
        children: 'parent/children',
        progress: 'parent/progress',
        assignProgramActivate: 'parent/assign-program/activate',
        assignProgramRenew: 'parent/assign-program/renew',
        assignProgramSwitch: 'parent/assign-program/switch',
    },
    academy: {
        report: 'parent/academy/report',
        timePlayed: 'parent/academy/time-played',
    },
    zoou: {
        assessment: 'parent/zoou/assessment',
        skillBuilder: 'parent/zoou/report',
        skillBuilderBoard: 'parent/zoou/skill-builder-board',
        timePlayed: 'parent/zoou/time-played',
    },
    ssgrin: {
        report: 'parent/ssgrin/report',
        timePlayed: 'parent/ssgrin/time-played',
    },
    hoh: {
        assessment: 'parent/hoh/assessment',
        skillBuilder: 'parent/hoh/report',
        timePlayed: 'parent/hoh/time-played',
    },
    sim: {
        library: 'parent/sim/library',
        comic: 'parent/sim/comic',
        timePlayed: 'parent/sim/time-played',
    },
};

export default parent;
