import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './_login.module.sass';

type State = {
    position: number,
}

type Props = {
    usernameFocussed: boolean,
    passwordFocussed: boolean,
}

class LoginCustomHeader extends React.Component<Props, State> {
    private interval: any = null;
    private currentPositionIndex: number = 0;
    private timeStep = 225;
    private shouldUseBasicPosition: boolean = false;
    private basicPosition = -20;
    private usernamePositions = [-220, -420, -620, -820];
    private passwordPositions = [-1020, -1220, -1420, -1620];
    state = { position: this.basicPosition };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (! this.props.usernameFocussed && ! this.props.passwordFocussed) {
            this.shouldUseBasicPosition = true;
            return;
        }

        if (this.props.usernameFocussed) {
            this.shouldUseBasicPosition = false;
            this.animateBackground(this.usernamePositions);
        }

        if (this.props.passwordFocussed) {
            this.shouldUseBasicPosition = false;
            this.animateBackground(this.passwordPositions);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    animateBackground(positions: Array<number>) {
        clearInterval(this.interval);

        this.interval = setInterval(() => {
            if (this.currentPositionIndex === 0 && this.shouldUseBasicPosition) {
                this.setState({ position: this.basicPosition });
                clearInterval(this.interval);
                return;
            }

            const isLast = ! (this.currentPositionIndex + 1 in positions);
            this.currentPositionIndex = isLast ? 0 : this.currentPositionIndex + 1;
            this.setState({ position: positions[this.currentPositionIndex] });
        }, this.timeStep);
    }

    render() {
        return <section className={styles.customHeader}>
            <FormattedMessage id="modal.header.login"/>
            <div className={styles.olivia} style={{ backgroundPositionX: `${this.state.position}px` }}></div>
        </section>;
    }
}

export default LoginCustomHeader;
