import React, { FunctionComponent } from 'react';
import Main from '../components/layout/Main';
import Container from '../components/layout/Container';
import PaymentCanceled from './payment/PaymentCanceled';
import PublicLightHeader from './common/PublicLightHeader';
import PublicLightFooter from './common/PublicLightFooter';
import styles from './_public.module.sass';

const PaymentCanceledView: FunctionComponent = () => (
    <Container>
        <PublicLightHeader/>
        <Main classes={styles.centeredBody}>
            <PaymentCanceled/>
        </Main>
        <PublicLightFooter/>
    </Container>
);

export default PaymentCanceledView;
