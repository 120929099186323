import React, { ComponentType } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { endpoints } from '../../../api';
import Error403View from '../../public/Error403View';
import { authSelectors, RootState } from '../../../state';

type State = {
    render: boolean,
    isForbidden: boolean,
}

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
    isParent: authSelectors.isParent(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
}));

const requireAuth = <P extends RouteComponentProps>(WrappedComponent: ComponentType<P>): ComponentType<P> => {
    class RequireAuth extends React.Component<P & RouteComponentProps & ConnectedProps<typeof connector>, State> {
        state = {
            render: false,
            isForbidden: false,
        }

        guardAgainstMissingAuth(): void {
            const redirectToAnotherUser = this.shouldRedirectToAnotherUser();

            if (redirectToAnotherUser) {
                this.props.history.replace(redirectToAnotherUser);
                return;
            }

            if (this.props.isAuthenticated && ! this.isInScope()) {
                this.setState({ render: true, isForbidden: true });
                return;
            }

            this.setState({ render: true, isForbidden: false });

            if (! this.props.isAuthenticated) {
                const pathname = this.props.history.location.pathname;
                const search = pathname ? `?r=${pathname}` : '';
                this.props.history.push(`/logout${search}`);
            }
        }

        shouldRedirectToAnotherUser(): string | undefined {
            const requiredLocation = this.props.history.location.pathname;

            if (this.props.isParent && requiredLocation === `/${endpoints.educator.lessons.lessons}`) {
                return `/${endpoints.parent.lessons.lessons}`;
            }

            return undefined;
        }

        isInScope(): boolean {
            const currentScope = this.props.homePath;
            const requiredLocation = this.props.history.location.pathname;

            return (requiredLocation.substr(0, currentScope.length) === currentScope && currentScope.length >= 5);
        }

        componentDidMount() {
            this.guardAgainstMissingAuth();
        }

        render() {
            if (! this.state.render) {
                return null;
            }

            if (this.state.isForbidden) {
                return <Error403View/>;
            }

            const { homePath, isAuthenticated, ...props } = this.props;
            return <WrappedComponent {...props as P} />;
        }
    }

    //@ts-ignore
    return withRouter(connector(RequireAuth as ComponentType<any>));
};

export default requireAuth;
