import { TabulatorSegment } from './types';

export default {
    [TabulatorSegment.AccountSourcesForm]: [
        TabulatorSegment.Educators,
        TabulatorSegment.Parents,
    ],
    [TabulatorSegment.AccountSourcesReferral]: [],
    [TabulatorSegment.AccountSourcesUber]: [],
    [TabulatorSegment.Districts]: [
        TabulatorSegment.Schools,
        TabulatorSegment.Teams,
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.Schools]: [
        TabulatorSegment.Districts,
        TabulatorSegment.Teams,
        TabulatorSegment.Educators,
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.Teams]: [
        TabulatorSegment.Districts,
        TabulatorSegment.Schools,
        TabulatorSegment.Educators,
    ],
    [TabulatorSegment.Educators]: [
        TabulatorSegment.Schools,
        TabulatorSegment.Teams,
        TabulatorSegment.Students,
        TabulatorSegment.AccountSourcesUber,
        TabulatorSegment.PurchaseOverview,
    ],
    [TabulatorSegment.Parents]: [
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.TransferLicensesHistory]: [],
    [TabulatorSegment.PurchaseOverview]: [
        TabulatorSegment.PurchaseHistory,
        TabulatorSegment.Educators,
    ],
    [TabulatorSegment.PurchaseHistory]: [
        TabulatorSegment.PurchaseOverview,
        TabulatorSegment.Educators,
    ],
    [TabulatorSegment.ActivityHistory]: [
        TabulatorSegment.Schools,
        TabulatorSegment.Teams,
        TabulatorSegment.Educators,
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.SiteLicense]: [],
    [TabulatorSegment.Students]: [
        TabulatorSegment.Schools,
        TabulatorSegment.Educators,
        TabulatorSegment.Parents,
    ],
    [TabulatorSegment.SelCheckIns]: [],
    [TabulatorSegment.StudentsBulkUpload]: [
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.StudentsBulkUploadRow]: [],
    [TabulatorSegment.AssignProgramHistory]: [
        TabulatorSegment.Students,
    ],
    [TabulatorSegment.EcommerceHistory]: [],
    [TabulatorSegment.MetricsEducator]: [],
    [TabulatorSegment.MetricsAdmin]: [],
    [TabulatorSegment.MetricsOutOfLicense]: [],
    [TabulatorSegment.MetricsGiftsInviteDetails]: [],
    [TabulatorSegment.MetricsGiftsInviteAggregated]: [],
    [TabulatorSegment.Lessons]: [],
    [TabulatorSegment.Temp]: [],
};
