import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './_layout.module.sass';

type Props = {
    classes?: string | undefined,
}

const Card: FunctionComponent<Props> = ({ children, classes = undefined }) => (
    <section className={classNames(styles.card, styles.shadow, classes)}>
        {children}
    </section>
);

export default Card;
