import React, { FunctionComponent, SyntheticEvent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import styles from './_dialog.module.sass';

type Props = {
    handleClose: (e: SyntheticEvent) => void,
    disableClose?: boolean,
}

const CloseButton: FunctionComponent<Props> = ({ handleClose, disableClose = false }) => (
    <IconButton
        onClick={handleClose}
        className={styles.iconButton}
        disabled={disableClose}
    >
        <FontAwesomeIcon icon={faTimes} className={styles.icon}/>
    </IconButton>
);

export default CloseButton;
