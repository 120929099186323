import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import LinkButton from '../common/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import styles from './_errors.module.sass';

const Error403: FunctionComponent = () => (
    <section className={styles.error403}>
        <FontAwesomeIcon icon={faBan}/>
        <FormattedMessage id="error-pages.forbidden"/>
        <LinkButton i18n="go-home"/>
    </section>
);

export default Error403;
