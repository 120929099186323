import { createReducer } from '../../utils';
import types, {
    ModalsState,
    ModalStatus,
    ModalHideAction,
    ModalOpenAction,
    ModalShowAction,
    ModalCloseAction,
    ModalUpdateTitleAction,
    ModalUpdateStatusAction,
} from "./types";

const initialState: ModalsState = {};

const open = (state: ModalsState, action: ModalOpenAction): ModalsState => {
    const modal = state[action.payload.name];

    if (modal) {
        return { ...state, [action.payload.name]: { ...modal, isOpen: true } };
    }

    return { ...state, [action.payload.name]: {
        isOpen: true,
        type: action.payload.type,
        id: action.payload.id,
        status: ModalStatus.Open,
        tabulator: action.payload.tabulator,
        tabulatorRowCopy: action.payload.tabulatorRowCopy,
        title: '',
        extra: action.payload.extra,
    }};
};

const hide = (state: ModalsState, action: ModalHideAction): ModalsState => {
    const modal = state[action.payload.name];

    return { ...state, [action.payload.name]: { ...modal, isOpen: false } };
};

const show = (state: ModalsState, action: ModalShowAction): ModalsState => {
    const modal = state[action.payload.name];

    return { ...state, [action.payload.name]: { ...modal, isOpen: true } };
};

const close = (state: ModalsState, action: ModalCloseAction): ModalsState => {
    const { [action.payload.name]: settings, ...rest } = state;

    return { ...rest };
};

const updateStatus = (state: ModalsState, action: ModalUpdateStatusAction): ModalsState => {
    const modal = state[action.payload.name];

    return { ...state, [action.payload.name]: { ...modal, status: action.payload.status } };
};

const updateTitle = (state: ModalsState, action: ModalUpdateTitleAction): ModalsState => {
    const modal = state[action.payload.name];

    return { ...state, [action.payload.name]: { ...modal, title: action.payload.title } };
};

const signOut = (): ModalsState => {
    return { ...initialState };
};

const reducers = createReducer(initialState, {
    [types.MODAL_OPEN]: open,
    [types.MODAL_HIDE]: hide,
    [types.MODAL_SHOW]: show,
    [types.MODAL_CLOSE]: close,
    [types.MODAL_UPDATE_STATUS]: updateStatus,
    [types.MODAL_UPDATE_TITLE]: updateTitle,
    [types.SIGN_OUT]: signOut,
} as any);

export default reducers;
