import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './_layout.module.sass';

type Props = {
    classes?: string | undefined,
}

const Header: FunctionComponent<Props> = ({ children, classes = undefined }) => (
    <header className={classNames(styles.header, styles.shadow, classes)}>
        {children}
    </header>
);

export default Header;
