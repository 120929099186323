import { FormValues } from './types';
import { createValidation, LabelOverrides, Rules } from '../../../../../validation';

const rules: Rules<FormValues> = {
    password: ['required'],
    password_confirmation: ['required'],
};

export const labelOverrides: LabelOverrides<FormValues> = {
};

const validation = createValidation<FormValues>(rules, labelOverrides);

export default validation;
