import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import styles  from './_common.module.sass';

type Props = {
    type: 'success' | 'warning' | 'info' | 'default' | 'error',
    actions?: JSX.Element,
    inline?: boolean
    big?: boolean
}

const Notification: FunctionComponent<Props> = ({ children, type, actions, inline, big }) => (
    <section className={classNames(styles.notification, { [styles.inline]: inline, [styles.big]: big })}>
        <div className={classNames({
            [styles.success]: type === 'success',
            [styles.warning]: type === 'warning',
            [styles.info]: type === 'info',
            [styles.default]: type === 'default',
            [styles.error]: type === 'error',
        })}>
            <FontAwesomeIcon icon={{
                success: faCheck,
                warning: faExclamationTriangle,
                info: faInfo,
                default: faInfo,
                error: faExclamation,
            }[type]}/>
            <p>{children}</p>
        </div>
        {actions}
    </section>
);

export default Notification;
