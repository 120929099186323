import React from 'react';
import { mode } from '../../config';
import PublicFooter from './common/PublicFooter';
import PublicHeaderLogo from './common/PublicHeaderLogo';
import PublicFooterTaiwan from './common/taiwan/PublicFooterTaiwan';
import PublicHeaderLogoTaiwan from './common/taiwan/PublicHeaderLogoTaiwan';

const publicFooter = () => {
    if (mode.isTaiwan()) return <PublicFooterTaiwan/>;
    return <PublicFooter/>;
};

const publicHeaderLogo = () => {
    if (mode.isTaiwan()) return <PublicHeaderLogoTaiwan/>;
    return <PublicHeaderLogo/>;
};

export default {
    publicFooter,
    publicHeaderLogo,
};
