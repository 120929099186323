import types, {
    SidebarId,
    SidebarType,
    SidebarData,
    SidebarTaskData,
    SidebarActionAdd,
    SidebarActionSeen,
    SidebarActionTask,
    SidebarActionRemove,
    DashboardNotification,
    DashboardDismissAction,
    SidebarActionSingleton,
    DashboardFetchPageAction,
    NotificationsSignInAction,
    NotificationsSignOutAction,
    NotificationsActingAsAction,
    DashboardLoadingBeginAction,
    DashboardLoadingCommitAction,
} from './types';

const sidebarAdd = (type: SidebarType, id: SidebarId, data: SidebarData): SidebarActionAdd => ({
    type: types.SIDEBAR_ADD,
    payload: { type, id, data },
});

const sidebarSeen = (id: SidebarId): SidebarActionSeen => ({
    type: types.SIDEBAR_SEEN,
    payload: { id },
});

const sidebarRemove = (id: SidebarId): SidebarActionRemove => ({
    type: types.SIDEBAR_REMOVE,
    payload: { id },
});

const sidebarSingleton = (type: SidebarType, id: SidebarId, data: SidebarData): SidebarActionSingleton => ({
    type: types.SIDEBAR_SINGLETON,
    payload: { type, id, data },
});

const sidebarTask = (
    type: SidebarType,
    id: SidebarId,
    data: SidebarTaskData,
    isFromApiResponse: boolean,
): SidebarActionTask => ({
    type: types.SIDEBAR_TASK,
    payload: { type, id, data, isFromApiResponse },
});

const signIn = (accessToken: any, user: any, ecommerce: any, notifications: number = 0): NotificationsSignInAction => ({
    type: types.SIGN_IN,
    payload: { accessToken, user, ecommerce, notifications },
});

const signOut = (accessToken: any, user: any): NotificationsSignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

const actingAs = (actingAsUser: any, ecommerce: any, notifications: number = 0): NotificationsActingAsAction => ({
    type: types.ACTING_AS,
    payload: { actingAsUser, ecommerce, notifications },
});

const dashboardLoadingBegin = (): DashboardLoadingBeginAction => ({
    type: types.DASHBOARD_LOADING_BEGIN,
    payload: { loading: true },
});

const dashboardLoadingCommit = (): DashboardLoadingCommitAction => ({
    type: types.DASHBOARD_LOADING_COMMIT,
    payload: { loading: false },
});

const dashboardFetchPage = (data: DashboardNotification[], total: number): DashboardFetchPageAction => ({
    type: types.DASHBOARD_FETCH_PAGE,
    payload: { data, total },
});

const dashboardDismiss = (id: number): DashboardDismissAction => ({
    type: types.DASHBOARD_DISMISS,
    payload: { id },
});

export default {
    sidebarAdd,
    sidebarSeen,
    sidebarRemove,
    sidebarSingleton,
    sidebarTask,
    signIn,
    signOut,
    actingAs,
    dashboardLoadingBegin,
    dashboardLoadingCommit,
    dashboardFetchPage,
    dashboardDismiss,
};
