import React, { ComponentType } from 'react';
import { AnyObject, FormApi } from 'final-form';
import { FormPageDefaultSettings } from './types';
import { handleSuccessful, handleValidationFailed, runApiAction } from './utils';
import { extractFormErrorsOrFail, mapToApiValues, pageResponder, PageResponder, PageResponderType } from '../../../api';

type State = {
    responder: PageResponder<PageResponderType>,
}

const withFormPageDefault = <FormValues extends AnyObject, P = any>(
    WrappedComponent: ComponentType<P>,
    settingsCallback: FormPageDefaultSettings<FormValues, P>
): ComponentType<P> => {
    class WithFormPageDefault extends React.Component<P, State> {
        private settings: ReturnType<FormPageDefaultSettings<FormValues, P>>;

        constructor(props: P) {
            super(props);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.settings = settingsCallback(props);

            this.state = { responder: pageResponder() };
        }

        async handleSubmit(values: FormValues, form: FormApi<FormValues>) {
            try {
                const response = await runApiAction(this.settings, this.settings.endpoint, mapToApiValues(values));
                this.setState({ responder: pageResponder(response.data) });
                return handleSuccessful(this.settings, {}, {}, response.data);
            } catch (error) {
                const errors = extractFormErrorsOrFail(error, form.getRegisteredFields());
                return handleValidationFailed(this.settings, errors);
            }
        }

        render() {
            const props = {
                handleSubmit: this.handleSubmit,
                pageResponder: this.state.responder,
            };

            return <WrappedComponent {...this.props} {...props} />;
        }
    }

    return WithFormPageDefault;
};

export default withFormPageDefault;
