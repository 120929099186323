import global from './../types';
import { CancelTokenSource } from 'axios';
import { ApiItem, TabulatorSegment } from './segments/types';

export const SUBSET = '_subset';
export const SUBSET_SELECTED = 'selected';
export const SUBSET_UNCHECKED = 'unchecked';

const NAME_ADD = 'tabulator/name/add';
const NAME_ACTIVATE = global.ACTIVATE_TAB;
const NAME_REMOVE = 'tabulator/name/remove';
const FETCH_PAGE = 'tabulator/fetch/page';
const FETCH_FRESH = 'tabulator/fetch/fresh';
const PAGE_CHANGE = 'tabulator/page/change';
const FILTER_CHANGE = 'tabulator/filter/change';
const FILTER_CLEAR_ALL = 'tabulator/filter/clear-all';
const SORTING_CHANGE = 'tabulator/sorting/change';
const LOADING_BEGIN = 'tabulator/loading/begin';
const LOADING_COMMIT = 'tabulator/loading/commit';
const DOWNLOADING_BEGIN = 'tabulator/downloading/begin';
const DOWNLOADING_COMMIT = 'tabulator/downloading/commit';
const SET_CURRENT = 'tabulator/current/set';
const FORCE_FRESH = 'tabulator/force/fresh';
const SELECT_ROW = 'tabulator/select/row';
const SELECT_ALL = 'tabulator/select/all';
const SELECT_BULK = 'tabulator/select/bulk';
const DESELECT_ALL = 'tabulator/deselect/all';
const SIGN_OUT = global.SIGN_OUT;

export default {
    NAME_ADD: NAME_ADD as typeof NAME_ADD,
    NAME_ACTIVATE: NAME_ACTIVATE as typeof NAME_ACTIVATE,
    NAME_REMOVE: NAME_REMOVE as typeof NAME_REMOVE,
    FETCH_PAGE: FETCH_PAGE as typeof FETCH_PAGE,
    FETCH_FRESH: FETCH_FRESH as typeof FETCH_FRESH,
    PAGE_CHANGE: PAGE_CHANGE as typeof PAGE_CHANGE,
    FILTER_CHANGE: FILTER_CHANGE as typeof FILTER_CHANGE,
    FILTER_CLEAR_ALL: FILTER_CLEAR_ALL as typeof FILTER_CLEAR_ALL,
    SORTING_CHANGE: SORTING_CHANGE as typeof SORTING_CHANGE,
    LOADING_BEGIN: LOADING_BEGIN as typeof LOADING_BEGIN,
    LOADING_COMMIT: LOADING_COMMIT as typeof LOADING_COMMIT,
    DOWNLOADING_BEGIN: DOWNLOADING_BEGIN as typeof DOWNLOADING_BEGIN,
    DOWNLOADING_COMMIT: DOWNLOADING_COMMIT as typeof DOWNLOADING_COMMIT,
    SET_CURRENT: SET_CURRENT as typeof SET_CURRENT,
    FORCE_FRESH: FORCE_FRESH as typeof FORCE_FRESH,
    SELECT_ROW: SELECT_ROW as typeof SELECT_ROW,
    SELECT_ALL: SELECT_ALL as typeof SELECT_ALL,
    SELECT_BULK: SELECT_BULK as typeof SELECT_BULK,
    DESELECT_ALL: DESELECT_ALL as typeof DESELECT_ALL,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
};

export * from './../../types';
export * from './segments/types';

export type TabulatorName = string;
export type TabulatorEndpoint = string;
export type TabulatorMapper = (item: ApiItem) => ApiItem;
export type TabulatorPage = number;
export type TabulatorFilter = { [key: string]: string | TabulatorFilterAutocompleteOption };
export type TabulatorFilterAutocompleteOption = { id: number, label: string };
export type TabulatorSorting = { [key: string]: TabulatorSortingDir };
export type TabulatorSortingDir = 'asc' | 'desc';
export type TabulatorLoading = false | CancelTokenSource;
export type TabulatorDownloading = boolean;
export type TabulatorIsCurrent = boolean;
export type TabulatorSelected = { [key in number]: ApiItem };
export type TabulatorSelectableLimit = number | undefined;

export type TabulatorSingleNameState = {
    segment: TabulatorSegment,
    endpoint: TabulatorEndpoint,
    byId: { [key in number]: ApiItem },
    selected: TabulatorSelected,
    selectableLimit: TabulatorSelectableLimit,
    pages: { [key in number]: number[] },
    filtered: number,
    total: number,
    totalFromCache: boolean,
    page: TabulatorPage,
    filters: TabulatorFilter,
    sorting: TabulatorSorting,
    loading: TabulatorLoading,
    downloading: TabulatorDownloading,
    isCurrent: TabulatorIsCurrent,
    lastFreshFetchAt: number,
    forceFreshFetchAfter: number,
    forceFresh: boolean,
    loadedAt: { [page: number]: number },
    shareFiltersWith: TabulatorName[],
}

export interface TabulatorState {
    [name: string]: TabulatorSingleNameState,
}

export interface NameAddAction {
    type: typeof NAME_ADD,
    payload: {
        name: TabulatorName,
        segment: TabulatorSegment,
        endpoint: TabulatorEndpoint,
        forceFreshFetchAfter: number,
        selectableLimit: TabulatorSelectableLimit,
        shareFiltersWith: TabulatorName[],
        filters: TabulatorFilter,
        sorting: TabulatorSorting,
    }
}

export interface NameActivateAction {
    type: typeof NAME_ACTIVATE,
    payload: {
        id: any,
        group: any
        tab: TabulatorName,
        filters: TabulatorFilter,
        sorting?: TabulatorSorting,
    }
}

export interface NameRemoveAction {
    type: typeof NAME_REMOVE,
    payload: {
        name: TabulatorName,
    }
}

export interface FetchPageAction {
    type: typeof FETCH_PAGE,
    payload: {
        name: TabulatorName,
        mapper: TabulatorMapper,
        data: ApiItem[],
        filtered: number,
        total: number,
        totalFromCache: boolean,
        at: number,
    }
}

export interface FetchFreshAction {
    type: typeof FETCH_FRESH,
    payload: {
        name: TabulatorName,
        mapper: TabulatorMapper,
        data: ApiItem[],
        filtered: number,
        total: number,
        totalFromCache: boolean,
        at: number,
    }
}

export interface PageChangeAction {
    type: typeof PAGE_CHANGE,
    payload: {
        name: TabulatorName,
        page: TabulatorPage,
    }
}

export interface FilterChangeAction {
    type: typeof FILTER_CHANGE,
    payload: {
        name: TabulatorName,
        filter: TabulatorFilter,
    }
}

export interface FilterClearAllAction {
    type: typeof FILTER_CLEAR_ALL,
    payload: {
        name: TabulatorName,
    }
}

export interface SortingChangeAction {
    type: typeof SORTING_CHANGE,
    payload: {
        name: TabulatorName,
        column: string,
        first: TabulatorSortingDir,
    }
}

export interface LoadingBeginAction {
    type: typeof LOADING_BEGIN,
    payload: {
        name: TabulatorName,
        cancelTokenSource: CancelTokenSource,
    }
}

export interface LoadingCommitAction {
    type: typeof LOADING_COMMIT,
    payload: {
        name: TabulatorName,
    }
}

export interface DownloadingBeginAction {
    type: typeof DOWNLOADING_BEGIN,
    payload: {
        name: TabulatorName,
    }
}

export interface DownloadingCommitAction {
    type: typeof DOWNLOADING_COMMIT,
    payload: {
        name: TabulatorName,
    }
}

export interface SetCurrentAction {
    type: typeof SET_CURRENT,
    payload: {
        name: TabulatorName,
    }
}

export interface ForceFreshAction {
    type: typeof FORCE_FRESH,
    payload: {
        name: TabulatorName,
    }
}

export interface SelectRowAction {
    type: typeof SELECT_ROW,
    payload: {
        name: TabulatorName,
        id: number,
    }
}

export interface SelectAllAction {
    type: typeof SELECT_ALL,
    payload: {
        name: TabulatorName,
        items: ApiItem[],
    }
}

export interface SelectBulkAction {
    type: typeof SELECT_BULK,
    payload: {
        name: TabulatorName,
        items: ApiItem[],
    }
}

export interface DeselectAllAction {
    type: typeof DESELECT_ALL,
    payload: {
        name: TabulatorName,
    }
}

export interface TabulatorSignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: any,
        user: any,
    }
}
