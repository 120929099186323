import * as excelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { intl } from '../i18n';
import { ApiItem } from '../state/ducks/tabulator';
import { Column, XlsxCell } from '../views/components/tabulator';

const downloadXlsx = (
    name: string,
    data: any[],
    columns: Column[],
    mapper: (item: ApiItem) => ApiItem = item => item,
    callback?: () => void
) => {
    const columnsToXlsx = columns.filter(column => {
        return (! column.hasOwnProperty('xlsx') || (column.hasOwnProperty('xlsx') && column.xlsx !== undefined))
            && (! column.hasOwnProperty('csv') || (column.hasOwnProperty('csv') && column.csv !== undefined));
    });

    const sheetColumns = columnsToXlsx.map(column => {
        const header = intl.formatMessage({ id: `tabulator.thead.${column.i18n || column.field}` });

        return {
            header,
            key: column.key || column.field,
            width: Math.ceil(header.length * 1.2),
        };
    });

    const workbook = new excelJS.Workbook();
    const sheet = workbook.addWorksheet(name.toUpperCase());
    sheet.columns = sheetColumns;

    data.map((item: ApiItem) => mapItemToXlsx(item, columnsToXlsx, mapper)).forEach((dataRow) => {
        // @ts-ignore
        const values = Object.keys(dataRow).map(key => dataRow[key].value);

        const row = sheet.addRow(values);
        row.height = 20;

        Object.keys(dataRow).forEach(key => {
            // @ts-ignore
            const dataCell = dataRow[key] as XlsxCell;

            sheet.getColumn(key).width = Math.max(
                sheet.getColumn(key).width as number,
                dataCell.value ? dataCell.value.toString().length : 0,
            );

            row.getCell(key).alignment = {
                ...dataCell.alignment,
                vertical: 'middle',
            };

            if (dataCell.hasOwnProperty('fillColor')) {
                row.getCell(key).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor: { argb: dataCell.fillColor },
                };
            }
        })
    });

    sheet.getRow(1).height = 20;
    sheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        callback && callback();
        saveAs(blob, `${name}.xlsx`);
    });
};

const mapItemToXlsx = (item: ApiItem, columnsToXlsx: Column[], mapper: (item: ApiItem) => ApiItem) => {
    const mappedItem = mapper(item) as any;

    return columnsToXlsx.reduce((result, column) => {
        const value = column.hasOwnProperty('xlsx')
            ? (column.xlsx as any)(column.field, mappedItem)
            : (column.hasOwnProperty('csv')
                ? { value: (column.csv as any)(column.field, mappedItem) }
                : { value: mappedItem[column.field] }
            )

        return { ...result, [column.key || column.field]: value };
    }, {});
};

export default downloadXlsx;
