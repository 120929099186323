import React, { FunctionComponent, useEffect } from 'react';
import Blank from '../components/layout/Blank';
import Error403 from '../components/errors/Error403';
import exceptionTracker from '../../exception-tracker';

const Error403View: FunctionComponent = () => {
    useEffect(() => {
        exceptionTracker.info(new Error('Permission Denied'), window.location.pathname);
    }, []);

    return <Blank>
        <Error403/>
    </Blank>;
};

export default Error403View;
