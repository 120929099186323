import { FormApi } from 'final-form';
import { AutocompleteOption } from './types';

const extractPatch = (values: { [key: string]: any }, dirtyFields: { [key: string]: boolean }) => {
    const dirty = Object.keys(dirtyFields).filter(field => dirtyFields[field]);

    return dirty.reduce((result, field) => {
        if (values.hasOwnProperty(field)) {
            return { ...result, [field]: values[field] };
        }

        return { ...result, [field]: '' };
    }, {});
};

const resetForm = <FormValues>(form: FormApi<FormValues>) => {
    setTimeout(() => {
        setTimeout(form.reset);
        Object.keys(form.getState().touched || {}).forEach(field => {
            form.mutators.setFieldTouched(field, false);
        });
    }, 0);
};

const touchFieldsWithSubmitErrors = <FormValues>(form: FormApi<FormValues>) => {
    Object.keys(form.getState().submitErrors || {}).forEach(field => {
        form.mutators.setFieldTouched(field, true);
    });
};

const uniqueAutocompleteOptions = (values: AutocompleteOption[]): AutocompleteOption[] => {
    return Object.values(values.reduce((result, value) => {
        return { ...result, [value.id]: value };
    }, {}));
}

export {
    extractPatch,
    resetForm,
    touchFieldsWithSubmitErrors,
    uniqueAutocompleteOptions,
};
