export enum Programs {
    academy = 'academy',
    zoou = 'zoou',
    ssgrin = 'ssgrin',
    hoh = 'hoh',
    sim = 'sim',
};

export enum CV {
    name = 'cv'
};

export enum CVMode {
    cdc = 'CoolDownCorner',
    eci = 'EmotionCheckIn',
}

export enum CVPath {
    cdc = 'cv/cdc',
    eci = 'cv/eci',
}
