import { Dispatch, Middleware } from 'redux';
import global from './../ducks/types';
import { mapEcommerce } from '../ducks/ecommerce/mappers';

const ecommerceMapMiddleware: Middleware = () => (next: Dispatch) => action => {
    if (~[global.SIGN_IN, global.ACTING_AS, global.HANDSHAKE_LITE].indexOf(action.type)) {
        return next({ ...action, payload: {
            ...action.payload,
            ecommerce: mapEcommerce(action.payload.ecommerce),
        }});
    }

    return next(action);
};

export {
    ecommerceMapMiddleware,
};
