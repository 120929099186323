import moment, { Moment } from 'moment';

const toDateTime = (dateOrDateTime: string): string => {
    if (! dateOrDateTime) {
        return '';
    }

    return moment(dateOrDateTime).toISOString();
};

const isValidDate = (date: string | null | undefined): boolean => {
    return date ? moment(date).isValid() : false;
};

const toExpirationDate = (date: string | null | undefined) => {
    if (! date) {
        return {
            isOK: false,
            isExpiring: false,
            isExpired: false,
            isValid: false,
            left: 0,
        };
    }

    const left = moment(date).diff(moment(), 'day');

    return {
        isOK: left >= 30,
        isExpiring: left < 30 && left >= 0,
        isExpired: left < 0,
        isValid: left >= 0,
        left: left >= 0 ? left + 1 : 0,
    };
};

const beginningOfCurrentSchoolYearDate = (dateTime: Moment | null = null): Moment => {
    const date = (dateTime || moment());

    const july = 6;
    const month = date.month();
    const year = month < july ? date.year() - 1 : date.year();

    return moment(`${year}-07-01 00:00:00`);
};

export {
    toDateTime,
    isValidDate,
    toExpirationDate,
    beginningOfCurrentSchoolYearDate,
};
