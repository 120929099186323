const home = {
    'home.welcome.header': 'Welcome to the Centervention Dashboard',
    'home.welcome.body.1': 'You are logged in at {url1}.',
    'home.welcome.body.2': 'Your Free Trial expires on {freeTrial}',
    'home.welcome.body.3': 'Student Licenses Available: {individual}',
    'home.welcome.body.4': 'Students should log separately at {url4}.',
    'home.back-to-school-tips.action': 'Back to School Tips for Using Centervention Programs',
    'home.purchase.header': 'Purchase Licenses',
    'home.purchase.body': 'Purchase individual program licenses.',
    'home.purchase.action': 'Purchase',
    'home.purchase-history.header': 'Purchase History',
    'home.purchase-history.body': 'Review individual program licenses\' purchase history.',
    'home.purchase-history.action': 'Review',
    'home.download-w-9-form.header': 'Download W-9 Form',
    'home.download-w-9-form.body': 'Download our latest W-9 form.',
    'home.download-w-9-form.action': 'Download',
    'home.quote.header': 'Request Price Quote',
    'home.quote.body': 'Do you need pricing information about Centervention programs?',
    'home.quote.action': 'Request Quote',
    'home.invite.header': 'Invite a Friend',
    'home.invite.body': 'Create a Free Centervention account for a colleague at your school, district or practice.',
    'home.invite.action': 'Invite Friend',
    'home.community.header': 'Join Our Community',
    'home.community.body': `Join our Facebook Group to help navigate social and emotional challenges
        in your school, community, and home.`,
    'home.returning.header': 'Returning Educators',
    'home.returning.link': 'Click here for tips',
    'home.returning.body': '{url} to prepare your Centervention class roster for the start of school.',
};

export default home;
