import React from 'react';
import { FormattedMessage } from 'react-intl';
import snackbars from '../../../views/components/snackbars/utils';

const handle500 = () => {
    snackbars.errorPersist(
        <FormattedMessage id="notification.http.5xx" values={{ br: <br /> }}/>
    );
};

export default handle500;
