import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import appMode from '../app-mode';
import Main from '../../components/layout/Main';
import PublicHeader from '../common/PublicHeader';
import { authSelectors, RootState } from '../../../state';
import Container from '../../components/layout/Container';
import styles from './_welcome.module.sass';

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
}));

class WelcomeApp extends React.Component<RouteComponentProps & ConnectedProps<typeof connector>> {
    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.props.history.push(this.props.homePath);
        }
    }

    render() {
        return <Container classes={styles.background}>
            <PublicHeader/>
            <Main classes={styles.main}></Main>
            {appMode.publicFooter()}
        </Container>;
    }
};

export default withRouter(connector(WelcomeApp));
