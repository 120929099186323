import modal from './modal';
import program from './program';
import options from './options';
import { optionValues } from '../../views/components/form/select';
import { StudentColumn } from '../../views/components/actions/uber/students/bulk-upload/types';

const tabulator = {
    'tabulator.tools.download': 'Download Spreadsheet',
    'tabulator.tools.refresh': 'Refresh View',
    'tabulator.tools.clear-filters': 'Clear Filters',
    'tabulator.empty-rows': 'No matching records found',
    'tabulator.showing-rows': 'Showing {filtered} of {total} rows',
    'tabulator.showing-fresh-now': 'Page refreshed just now',
    'tabulator.showing-fresh-recently': 'Page refreshed less than a minute ago',
    'tabulator.showing-fresh-minutes': 'Page refreshed {minutes, plural, one {# minute} other {# minutes}} ago',
    'tabulator.selected-rows.action': 'Choose action for selected students',
    'tabulator.selected-rows.clear': 'Uncheck all',
    'tabulator.selected-rows': `{selected} {countSelected, plural, one {student} other {students}} selected`,
    'tabulator.selected-rows.limit': `{selected} {countSelected, plural, one {student} other {students}} selected
        from the selection limit of {allowed}`,

    'tabulator.tooltip.select-all': 'Select All',
    'tabulator.tooltip.select-current-page': 'Select Current Page',
    'tabulator.tooltip.select-all-filtered': 'Select All Filtered',
    'tabulator.tooltip.uncheck-all': 'Uncheck All',
    'tabulator.tooltip.show-only-selected': 'Show Only Selected',
    'tabulator.tooltip.show-only-unchecked': 'Show Only Unchecked',
    'tabulator.tooltip.refresh-view': 'Refresh View',
    'tabulator.tooltip.header.active_individual': 'Total number of assigned individual licenses that did not expire',
    'tabulator.tooltip.header.active_individual_or_site': 'Total number of assigned individual and site licenses that did not expire',
    'tabulator.tooltip.header.expiring_individual': 'Active Individual Licenses that will expire in given number of days',
    'tabulator.tooltip.header.expiring_individual_or_site': 'Active Paid Licenses that will expire in given number of days',
    'tabulator.tooltip.header.created_students': 'My Students filtered by Limit Student Data',
    'tabulator.tooltip.header.total_hours_played': 'Total number of hours students played the games filtered by Limit Student Data',
    'tabulator.tooltip.header.admin-metrics': `Not live data.
        The data was calculated in the morning of the day when the report was generated`,

    'tabulator.actions.district-add': modal['modal.header.district-add'],
    'tabulator.actions.school-add': modal['modal.header.school-add'],
    'tabulator.actions.team-add': modal['modal.header.team-add'],
    'tabulator.actions.educator-add': modal['modal.header.educator-add'],
    'tabulator.actions.account-rollback-deactivate': modal['modal.header.account-rollback-deactivate'],
    'tabulator.actions.educator-create-account': modal['modal.header.educator-create-account'],
    'tabulator.actions.quote-create': modal['modal.header.quote-create'],
    'tabulator.actions.purchase-order-individual-add': modal['modal.header.purchase-order-individual-add'],
    'tabulator.actions.purchase-order-site-add': modal['modal.header.purchase-order-site-add'],
    'tabulator.actions.purchase-order-site-upgrade': modal['modal.header.purchase-order-site-upgrade'],
    'tabulator.actions.student-add': modal['modal.header.student-add'],
    'tabulator.actions.student-create-account': modal['modal.header.student-create-account'],
    'tabulator.actions.child-create-account-free-trial': `${modal['modal.header.child-create-account']} with Free Trial`,
    'tabulator.actions.child-create-account-individual': `${modal['modal.header.child-create-account']} with License`,
    'tabulator.actions.child-create-account': modal['modal.header.child-create-account'],
    'tabulator.actions.student-bulk-upload': 'Bulk Upload',
    'tabulator.actions.demo': 'Game Preview',
    'tabulator.actions.assign-program-free-trial': 'Assign Program with Free Trial',
    'tabulator.actions.assign-program-individual': 'Assign Program with License',
    'tabulator.actions.assign-program': 'Assign Program',
    'tabulator.actions.mass-assign-license': modal['modal.header.mass-assign-license'],
    'tabulator.actions.mass-switch-program': 'Switch Program',
    'tabulator.actions.mass-archive': 'Archive Students',
    'tabulator.actions.mass-unarchive': 'Unarchive Students',
    'tabulator.actions.mass-grade': 'Change Grade',
    'tabulator.actions.mass-teacher': 'Change Teacher',
    'tabulator.actions.mass-password': 'Change Password',
    'tabulator.actions.mass-reset-password': 'Reset Password',
    'tabulator.actions.mass-all-programs-report': modal['modal.header.mass-all-programs-report'],
    'tabulator.actions.mass-academy-report': modal['modal.header.mass-academy-report'],
    'tabulator.actions.mass-zoou-assessment': modal['modal.header.mass-zoou-assessment'],
    'tabulator.actions.mass-zoou-skill-builder': modal['modal.header.mass-zoou-skill-builder'],
    'tabulator.actions.mass-ssgrin-report': modal['modal.header.mass-ssgrin-report'],
    'tabulator.actions.mass-hoh-assessment': modal['modal.header.mass-hoh-assessment'],
    'tabulator.actions.mass-hoh-skill-builder': modal['modal.header.mass-hoh-skill-builder'],
    'tabulator.actions.mass-restart-active-game': modal['modal.header.mass-restart-active-game'],
    'tabulator.actions.mass-restart-academy': modal['modal.header.mass-restart-game'],
    'tabulator.actions.mass-restart-zoou': modal['modal.header.mass-restart-game'],
    'tabulator.actions.mass-restart-ssgrin': modal['modal.header.mass-restart-game'],
    'tabulator.actions.mass-restart-hoh': modal['modal.header.mass-restart-game'],
    'tabulator.actions.mass-unlock-assessment': modal['modal.header.mass-unlock-assessment'],
    'tabulator.actions.purchase-licenses': modal['modal.header.purchase-licenses'],
    'tabulator.actions.purchase-new-student': modal['modal.header.purchase-new-student'],
    'tabulator.actions.share-or-transfer-students': modal['modal.header.share-or-transfer-students'],
    'tabulator.actions.transfer-students': modal['modal.header.transfer-students'],
    'tabulator.actions.transfer-licenses': modal['modal.header.transfer-licenses'],
    'tabulator.actions.view-comic': 'View Comic',
    'tabulator.actions.view-your-comic': 'Your Comic',
    'tabulator.actions.activate': 'Activate',
    'tabulator.actions.purchase': 'Purchase',
    'tabulator.actions.renew': 'Renew',
    'tabulator.actions.try-it': 'Free Trial',
    'tabulator.actions.licenses-available': modal['modal.header.licenses-available'],
    'tabulator.actions.student-login-page': modal['modal.header.student-login-page'],
    'tabulator.actions.receipt': 'Receipt',
    'tabulator.actions.lesson-add': modal['modal.header.lesson-add'],
    'tabulator.actions.site-license-usage-report': modal['modal.header.site-license-usage-report'],

    'tabulator.filter.placeholder.id': 'id',
    'tabulator.filter.placeholder.text': 'search',
    'tabulator.filter.placeholder.from': 'from',
    'tabulator.filter.placeholder.to': 'to',
    'tabulator.filter.description.expiring-licenses': 'exp in days',
    'tabulator.filter.autocomplete.no-options.empty': 'type to search',
    'tabulator.filter.chart.title': 'Emotion Check Ins',
    'tabulator.filter.chart.button.day': 'Day',
    'tabulator.filter.chart.button.week': 'Week',
    'tabulator.filter.chart.button.month': 'Month',
    'tabulator.filter.chart.button.custom': 'Custom',
    'tabulator.filter.chart.today': 'Today',
    'tabulator.filter.chart.this-week': 'This Week',
    'tabulator.filter.chart.this-month': 'This Month',
    'tabulator.filter.chart.mad': 'Mad',
    'tabulator.filter.chart.sad': 'Sad',
    'tabulator.filter.chart.worried': 'Worried',
    'tabulator.filter.chart.okay': 'Okay',
    'tabulator.filter.chart.happy': 'Happy',

    'tabulator.formatters.status.not-activated': options['options.status.not-activated'],
    'tabulator.formatters.status.not-verified': options['options.status.not-verified'],
    'tabulator.formatters.status.on-hold': options['options.status.on-hold'],
    'tabulator.formatters.status.verified': options['options.status.verified'],
    'tabulator.formatters.status.deactivated': options['options.status.deactivated'],
    'tabulator.formatters.status.pending': options['options.status.pending'],
    'tabulator.formatters.status.created': options['options.status.created'],
    'tabulator.formatters.status.updated': options['options.status.updated'],
    'tabulator.formatters.status.failed': options['options.status.failed'],
    'tabulator.formatters.status.unknown': 'unknown',
    'tabulator.formatters.account-type.school': 'Educator',
    'tabulator.formatters.account-type.home': 'Parent',
    'tabulator.formatters.status.true': 'on',
    'tabulator.formatters.status.false': 'off',
    'tabulator.formatters.status.current': 'current',
    'tabulator.formatters.status.rolledBack': 'rolled back',
    'tabulator.formatters.status.is-used': 'played',
    'tabulator.formatters.status.student-active': 'Current Roster',
    'tabulator.formatters.status.student-archived': 'Archived',
    'tabulator.formatters.grade.p': options['options.grade.p'],
    'tabulator.formatters.grade.k': options['options.grade.k'],
    'tabulator.formatters.not-activated': 'Not Activated',
    'tabulator.formatters.well-above': 'Well Above',
    'tabulator.formatters.above': 'Above',
    'tabulator.formatters.meets': 'Meets',
    'tabulator.formatters.below': 'Below',
    'tabulator.formatters.well-below': 'Well Below',
    'tabulator.formatters.account.source.form': 'Form',
    'tabulator.formatters.account.source.admin': 'Admin',
    'tabulator.formatters.account.source.invite': 'Colleague',
    'tabulator.formatters.account.source.uber': 'Uber',
    'tabulator.formatters.issuer.fortress_user': options['options.fortress_user'],
    'tabulator.formatters.issuer.stripe': options['options.stripe'],
    'tabulator.formatters.issuer.account_activation': options['options.account_activation'],
    'tabulator.formatters.issuer.account_verification': options['options.account_verification'],
    'tabulator.formatters.issuer.internal_script': options['options.internal_script'],
    'tabulator.formatters.purchase-created': 'Plan Change',
    'tabulator.formatters.purchase-updated': 'Plan Change',
    'tabulator.formatters.give-for-free-created': 'Plan Change',
    'tabulator.formatters.give-for-free-updated': 'Plan Change',
    'tabulator.formatters.purchase-order-individual-created': 'PO Indiv',
    'tabulator.formatters.purchase-order-site-created': 'PO Site',
    'tabulator.formatters.purchase-created-for-distribution': 'PO Distribution',
    'tabulator.formatters.purchase-updated-for-distribution': 'PO Distribution',
    'tabulator.formatters.request-quote-individual-created': 'Quote Indiv / Self',
    'tabulator.formatters.request-quote-site-created': 'Quote Site / Self',
    'tabulator.formatters.quote-individual-created': 'Quote Indiv / Uber',
    'tabulator.formatters.quote-site-created': 'Quote Site / Uber',
    'tabulator.formatters.use-product-assigned': 'Assign',
    'tabulator.formatters.use-product-reassigned': 'Assign',
    'tabulator.formatters.use-product-rollback-unassigned': 'Rollback',
    'tabulator.formatters.purchase-created-for-transfer': 'Transfer',
    'tabulator.formatters.purchase-updated-for-transfer': 'Transfer',
    'tabulator.formatters.purchase-gift-invitee-given': 'Free License / Invitee',
    'tabulator.formatters.purchase-gift-invitor-given': 'Free License / Invitor',
    'tabulator.formatters.expired': 'Expired',
    'tabulator.formatters.emotion-check-in.mad': 'Mad',
    'tabulator.formatters.emotion-check-in.sad': 'Sad',
    'tabulator.formatters.emotion-check-in.worried': 'Worried',
    'tabulator.formatters.emotion-check-in.okay': 'Okay',
    'tabulator.formatters.emotion-check-in.happy': 'Happy',
    'tabulator.formatters.yes': options['options.yes'],
    'tabulator.formatters.downloaded': 'Downloaded',
    [`tabulator.formatters.${optionValues.ACTIVITY_ADD_EDUCATOR}`]: options[`options.${optionValues.ACTIVITY_ADD_EDUCATOR}`],
    [`tabulator.formatters.${optionValues.ACTIVITY_EDUCATOR_DEACTIVATE}`]: options[`options.${optionValues.ACTIVITY_EDUCATOR_DEACTIVATE}`],
    [`tabulator.formatters.${optionValues.ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR}`]: options[`options.${optionValues.ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR}`],
    [`tabulator.formatters.${optionValues.ACTIVITY_SITE_LICENSE_GRANT_ACCESS}`]: options[`options.${optionValues.ACTIVITY_SITE_LICENSE_GRANT_ACCESS}`],
    [`tabulator.formatters.${optionValues.ACTIVITY_SITE_LICENSE_REVOKE_ACCESS}`]: options[`options.${optionValues.ACTIVITY_SITE_LICENSE_REVOKE_ACCESS}`],
    'tabulator.formatters.deactivate-user': 'deactivate user',
    'tabulator.formatters.detach-from-admin': 'detach from admin',
    'tabulator.formatters.transfer-student': 'transfer',
    'tabulator.formatters.share-student-access': 'share access',
    'tabulator.formatters.activity-details': 'to: {to}',
    'tabulator.formatters.activity-details-csv': 'to: {to}',
    'tabulator.formatters.elementary': 'Elementary',
    'tabulator.formatters.middle': 'Middle',
    'tabulator.formatters.all': 'All',
    'tabulator.formatters.cdc': program['program.cdc'],
    [`tabulator.formatters.resource-type.${optionValues.RESOURCE_TYPE_WORKSHEET}`]: options[`options.${optionValues.RESOURCE_TYPE_WORKSHEET}`],
    [`tabulator.formatters.resource-type.${optionValues.RESOURCE_TYPE_VIDEO}`]: options[`options.${optionValues.RESOURCE_TYPE_VIDEO}`],
    [`tabulator.formatters.resource-type.${optionValues.RESOURCE_TYPE_ACTIVITY}`]: options[`options.${optionValues.RESOURCE_TYPE_ACTIVITY}`],
    [`tabulator.formatters.resource-type.${optionValues.RESOURCE_TYPE_QUIZ}`]: options[`options.${optionValues.RESOURCE_TYPE_QUIZ}`],
    [`tabulator.formatters.resource-type.${optionValues.RESOURCE_TYPE_ARTICLE}`]: options[`options.${optionValues.RESOURCE_TYPE_ARTICLE}`],
    [`tabulator.formatters.${optionValues.USAGE_GROUP}`]: options[`options.${optionValues.USAGE_GROUP}`],
    [`tabulator.formatters.${optionValues.USAGE_INDIVIDUAL}`]: options[`options.${optionValues.USAGE_INDIVIDUAL}`],

    [`tabulator.formatters.cv-goal.${optionValues.CV_GOAL_NOT_SURE}`]: options[`options.${optionValues.CV_GOAL_NOT_SURE}`],
    [`tabulator.formatters.cv-goal.${optionValues.CV_GOAL_PURCHASE}`]: options[`options.${optionValues.CV_GOAL_PURCHASE}`],
    [`tabulator.formatters.cv-goal.${optionValues.CV_GOAL_FUTURE}`]: options[`options.${optionValues.CV_GOAL_FUTURE}`],

    'tabulator.values.reports.progress': '{completed} of {total}',
    'tabulator.row.missing-student': `Student can not be assigned`,
    'tabulator.row.missing-valid-purchase': `Valid license for student not found`,
    'tabulator.row.not-applicable': `not applicable`,

    'tabulator.ecommerce-wallet.licenses': `Licenses Available`,
    'tabulator.ecommerce-wallet.free-trial': `Free Trial Expires`,
    'tabulator.ecommerce-wallet.free-trial.count': `in {days} {count, plural, one {day} other {days}}`,
    'tabulator.ecommerce-wallet.is.i': `unused {site, plural, one {license} other {licenses}} {br} in current site license`,
    'tabulator.ecommerce-wallet.is.s': `unused {br} individual {individual, plural, one {license} other {licenses}}`,

    'tabulator.thead.counter': '#',
    'tabulator.thead.id': 'Id',
    'tabulator.thead.actions': ' ',
    'tabulator.thead.empty': ' ',
    'tabulator.thead.name': 'Name',
    'tabulator.thead.district': 'District',
    'tabulator.thead.school': 'School',
    'tabulator.thead.school-name': 'School Name',
    'tabulator.thead.team': 'Group Name',
    'tabulator.thead.state': 'State',
    'tabulator.thead.prefix': 'Prefix',
    'tabulator.thead.created_at': 'Created',
    'tabulator.thead.updated_at': 'Updated',
    'tabulator.thead.activated_at': 'Activated',
    'tabulator.thead.reached_out_at': 'Last Reached Out',
    'tabulator.thead.high_priority': 'High Priority',
    'tabulator.thead.date': 'Date',
    'tabulator.thead.schools': 'Schools',
    'tabulator.thead.teams': 'Groups',
    'tabulator.thead.admins': 'Admins',
    'tabulator.thead.valid_license_students': 'Active Students',
    'tabulator.thead.active_students': 'Current Roster',
    'tabulator.thead.active_students_accessed_last_month': 'Monthly Active Students',
    'tabulator.thead.archived_students': 'Archived Students',
    'tabulator.thead.pool_status_individual_remaining': 'Pool Indiv Remaining',
    'tabulator.thead.site_licenses': 'Site Licenses',
    'tabulator.thead.pool_status_site_initial': 'Pool Site Initial',
    'tabulator.thead.pool_status_site_remaining': 'Pool Site Remaining',
    'tabulator.thead.owner': 'Owner',
    'tabulator.thead.members': 'Members',
    'tabulator.thead.username': 'Username',
    'tabulator.thead.first_name': 'First Name',
    'tabulator.thead.last_name': 'Last Name',
    'tabulator.thead.full_name': 'Name',
    'tabulator.thead.accounts': 'Schools',
    'tabulator.thead.free_trial_ends_at': 'Free Trial',
    'tabulator.thead.individual_remaining': 'Licenses Remaining',
    'tabulator.thead.remaining': 'Licenses Remaining',
    'tabulator.thead.individual_quantity': 'Licenses Total',
    'tabulator.thead.reclaimable_licenses': 'Reclaimable Licenses',
    'tabulator.thead.current_site_license': 'Current Site License',
    'tabulator.thead.quantity': 'Quantity',
    'tabulator.thead.used': 'Licenses Used',
    'tabulator.thead.grade': 'Grade',
    'tabulator.thead.teacher': 'Teacher',
    'tabulator.thead.student_id': 'Student Id',
    'tabulator.thead.educators': 'Educators',
    'tabulator.thead.bulk_uploads': 'Bulk Uploads',
    'tabulator.thead.academy': program['program.academy.title'],
    'tabulator.thead.zoou': program['program.zoou.title'],
    'tabulator.thead.ssgrin': program['program.ssgrin.title'],
    'tabulator.thead.hoh': program['program.hoh.title'],
    'tabulator.thead.sim': program['program.sim.title'],
    'tabulator.thead.academy_ends_at': program['program.academy.title'],
    'tabulator.thead.zoou_ends_at': program['program.zoou.title'],
    'tabulator.thead.ssgrin_ends_at': program['program.ssgrin.title'],
    'tabulator.thead.hoh_ends_at': program['program.hoh.title'],
    'tabulator.thead.sim_ends_at': program['program.sim.title'],
    'tabulator.thead.progress': 'Progress',
    'tabulator.thead.scenes': 'Progress',
    'tabulator.thead.comics': 'Progress',
    'tabulator.thead.assessment_scenes': 'Assessment',
    'tabulator.thead.current_assessment_scenes': 'Current Assessment',
    'tabulator.thead.skill_builder_scenes': 'Skill Builder',
    'tabulator.thead.composite': 'Assessment Percentile',
    'tabulator.thead.composite_performance': 'Assessment',
    'tabulator.thead.impulse_control': 'Impulse Control Percentile',
    'tabulator.thead.impulse_control_performance': 'Impulse Control',
    'tabulator.thead.communication': 'Communication Percentile',
    'tabulator.thead.communication_performance': 'Communication',
    'tabulator.thead.cooperation': 'Cooperation Percentile',
    'tabulator.thead.cooperation_performance': 'Cooperation',
    'tabulator.thead.social_initiation': 'Social Initiation Percentile',
    'tabulator.thead.social_initiation_performance': 'Social Initiation',
    'tabulator.thead.empathy': 'Empathy Percentile',
    'tabulator.thead.empathy_performance': 'Empathy',
    'tabulator.thead.emotion_regulation': 'Emotion Regulation Percentile',
    'tabulator.thead.emotion_regulation_performance': 'Emotion Regulation',
    'tabulator.thead.comm_distance': 'Communication Completed',
    'tabulator.thead.comm_overall': 'Communication Success Rate',
    'tabulator.thead.impcon_distance': 'Impulse Control Completed',
    'tabulator.thead.impcon_overall': 'Impulse Control Success Rate',
    'tabulator.thead.coop_distance': 'Cooperation Completed',
    'tabulator.thead.coop_overall': 'Cooperation Success Rate',
    'tabulator.thead.socini_distance': 'Social Initiation Completed',
    'tabulator.thead.socini_overall': 'Social Initiation Success Rate',
    'tabulator.thead.emp_distance': 'Empathy Completed',
    'tabulator.thead.emp_overall': 'Empathy Success Rate',
    'tabulator.thead.emoreg_distance': 'Emotion Regulation Completed',
    'tabulator.thead.emoreg_overall': 'Emotion Regulation Success Rate',
    'tabulator.thead.skill-builder-divider': 'Skill Builder Data',
    'tabulator.thead.report': 'Report',
    'tabulator.thead.assessment_report': 'Assessment Report',
    'tabulator.thead.skill_builder_report': 'Skill Builder Report',
    'tabulator.thead.skill_builder_board': 'Skill Builder Board',
    'tabulator.thead.time_played': 'Daily Usage',
    'tabulator.thead.time-played.date': 'Date',
    'tabulator.thead.time-played.program': 'Time in Intervention',
    'tabulator.thead.time-played.cv': 'Cool Down Corner',
    'tabulator.thead.time-played.total': 'Total Time Played',
    'tabulator.thead.time-played.program.min': 'Time in Intervention (Min)',
    'tabulator.thead.time-played.cv.min': 'Cool Down Corner (Min)',
    'tabulator.thead.time-played.total.min': 'Total Time Played (Min)',
    'tabulator.thead.time-played.active.min': 'Active Program Time Played (Min)',
    'tabulator.thead.library': 'Library',
    'tabulator.thead.comic': 'Comic',
    'tabulator.thead.program': 'Program',
    'tabulator.thead.my_role': 'My Role',
    'tabulator.thead.invited_by': 'Invitor',
    'tabulator.thead.account_type': 'Account Type',
    'tabulator.thead.status': 'Status',
    'tabulator.thead.last_accessed': 'Last Accessed',
    'tabulator.thead.site-starts-at': 'Starts At',
    'tabulator.thead.site-ends-at': 'Ends At',
    'tabulator.thead.ends_at': 'License Expiration',
    'tabulator.thead.active_program': 'Active Program',
    'tabulator.thead.expected_ends_at': 'New Expiration Date Review',
    'tabulator.thead.assigned_ends_at': 'New Expiration Date Summary',
    'tabulator.thead.educator_username': 'Educator Username',
    'tabulator.thead.student_username': 'Student Username',
    'tabulator.thead.activate_license': 'Activate License',
    'tabulator.thead.assign_program': 'Assign Program',
    'tabulator.thead.created_id': 'Id',
    'tabulator.thead.errors': 'Errors',
    'tabulator.thead.child_assessment_progress': 'Assessment Progress',
    'tabulator.thead.child_skill_builder_progress': 'Skill Builder Progress',
    'tabulator.thead.number_of_licenses': '# Licenses',
    'tabulator.thead.from_name': 'Transfer From',
    'tabulator.thead.from_username': 'Transfer From',
    'tabulator.thead.to_name': 'Transfer To',
    'tabulator.thead.to_username': 'Transfer To',
    'tabulator.thead.transferred_at': 'Transferred',
    'tabulator.thead.is_used': 'Student Access',
    'tabulator.thead.issuer': 'Issuer',
    'tabulator.thead.issuer_user_and_issuer_uber': 'User Issuer / Uber Acting As Issuer',
    'tabulator.thead.issuer_educator_and_issuer_uber': 'Educator / Uber',
    'tabulator.thead.rollback_issuer_and_rollback_uber': 'Rollback Issuer / Uber Acting As Issuer',
    'tabulator.thead.ecommerce-change-type': 'Change Type',
    'tabulator.thead.quantity-change': 'Quantity Change',
    'tabulator.thead.starts-at-change': 'Starts At Change',
    'tabulator.thead.ends-at-change': 'Ends At Change',
    'tabulator.thead.changed-at': 'Change Created',
    'tabulator.thead.changed-date': 'Date Changed',
    'tabulator.thead.purchase_order_date': 'PO Date',
    'tabulator.thead.purchase_order_number': 'PO Number',
    'tabulator.thead.student': 'Student',
    'tabulator.thead.plan': 'Plan',
    'tabulator.thead.plan_owner': 'Plan Owner',
    'tabulator.thead.change_owner': 'Plan, PO, Quote Owner',
    'tabulator.thead.product': 'Program',
    'tabulator.thead.assigned_at': 'Assigned',
    'tabulator.thead.switched_at': 'Activated / Switched',
    'tabulator.thead.rolled_back_at': 'Rolled Back',
    'tabulator.thead.last_30_days_access': 'Last 30 Days Access',
    'tabulator.thead.access.date': 'Date',
    'tabulator.thead.access.access': 'Access',
    'tabulator.thead.account_source': 'How Created',
    'tabulator.thead.checkout_completed': 'CC Purchased',
    'tabulator.thead.last_quote_created_at': 'Last Quote Created',
    'tabulator.thead.last_quote_quantity': 'Last Quote Quantity',
    'tabulator.thead.active_individual': 'Active Individual Licenses',
    'tabulator.thead.active_individual_or_site': 'Active Paid Licenses',
    'tabulator.thead.expiring_individual': 'Expiring Individual Licenses',
    'tabulator.thead.expiring_individual_or_site': 'Expiring Paid Licenses',
    'tabulator.thead.limit-student-data': 'Limit Student Data',
    'tabulator.thead.generated_at': 'Report Generated',
    'tabulator.thead.created_students': 'Student Accounts Created',
    'tabulator.thead.total_hours_played': 'Total Student Time (Hours)',
    'tabulator.thead.expiring_individual_or_site_in_30_days': 'Expiring Paid Licenses, In 30 Days',
    'tabulator.thead.expiring_individual_or_site_in_7_days': 'Expiring Paid Licenses, In 7 Days',
    'tabulator.thead.created_students_this_school_year': 'Student Accounts Created, This School Year',
    'tabulator.thead.created_students_last_30_days': 'Student Accounts Created, Last 30 Days',
    'tabulator.thead.total_hours_played_this_school_year': 'Total Student Time (Hours), This School Year',
    'tabulator.thead.total_hours_played_last_30_days': 'Total Student Time (Hours), Last 30 Days',
    'tabulator.thead.teams_owner_with_open_pool': 'Groups Owner With Open Pool',
    'tabulator.thead.last_license_used_at': 'Last License Used',
    'tabulator.thead.last_license_used_change': 'Last License Used Type',
    'tabulator.thead.license_activated_extended': 'New License Expiration',
    'tabulator.thead.free_trial_activated': 'Free Trial Expiration',
    'tabulator.thead.last_emotion_check_in': 'Most Recent Check In',
    'tabulator.thead.invitee_username': 'Invitee',
    'tabulator.thead.invitee_created_at': 'Invitee Created',
    'tabulator.thead.invitee_verified_at': 'Invitee Verified',
    'tabulator.thead.given_at': 'Free License Given',
    'tabulator.thead.gift-was-given': 'All 4 Steps Completed (Licenses Given)',
    'tabulator.thead.invitees_verified': 'Invitees Verified',
    'tabulator.thead.invitees_licenses_given': 'Invitees with Free License Given',
    'tabulator.thead.invitees_percent_of_completed': '% of Verified with Free License Given',
    'tabulator.thead.all_licenses_given': 'Total Free Licenses Given (Invitee + Invitor)',
    'tabulator.thead.last_given_at': 'Last Free License Given',
    'tabulator.thead.site_access': 'Site License Access',
    'tabulator.thead.limit-purchase-data': 'Limit CC, PO, Quotes',
    'tabulator.thead.purchase_total_quantity': 'CC + PO + Quotes',
    'tabulator.thead.checkout_completed_quantity': 'CC Licenses',
    'tabulator.thead.purchase_order_quantity': 'PO Licenses',
    'tabulator.thead.request_quote_quantity': 'Self Quote Licenses',
    'tabulator.thead.quote_quantity': 'Uber Quote Licenses',
    'tabulator.thead.school_year_ends_at': 'School Year Ends At',
    'tabulator.thead.last_purchase': 'Last CC or PO',
    'tabulator.thead.last_request_quote': 'Last Self Quote',
    'tabulator.thead.last_quote': 'Last Uber Quote',
    'tabulator.thead.allow_duplicates': 'Allow Duplicates',
    'tabulator.thead.archive_not_affected': 'Archive Not Affected',
    'tabulator.thead.archive_not_affected_exclude_from': 'Do Not Archive If Created From',
    'tabulator.thead.never_detach_other_providers': 'Do Not Remove From Other Rosters',
    'tabulator.thead.rows': 'CSV Rows',
    'tabulator.thead.pending': 'Pending',
    'tabulator.thead.created': 'Created',
    'tabulator.thead.updated': 'Updated',
    'tabulator.thead.failed': 'Failed',
    'tabulator.thead.out_of_license': 'Out Of License',
    'tabulator.thead.archived': 'Archived Students',
    'tabulator.thead.affected': 'Affected Students',
    'tabulator.thead.bulk-upload.first_name': StudentColumn.FirstName,
    'tabulator.thead.bulk-upload.last_name': StudentColumn.LastName,
    'tabulator.thead.bulk-upload.grade': StudentColumn.Grade,
    'tabulator.thead.bulk-upload.teacher': StudentColumn.Teacher,
    'tabulator.thead.bulk-upload.student_id': StudentColumn.StudentId,
    'tabulator.thead.bulk-upload.educator_username': StudentColumn.EducatorUsername,
    'tabulator.thead.bulk-upload.student_username': StudentColumn.StudentUsername,
    'tabulator.thead.bulk-upload.activate_license': StudentColumn.ActivateLicense,
    'tabulator.thead.bulk-upload.assign_program': StudentColumn.AssignProgram,
    'tabulator.thead.bulk-upload.clever_user_id': StudentColumn.CleverUserId,
    'tabulator.thead.bulk-upload.classlink_login_id': StudentColumn.ClassLinkLoginId,
    'tabulator.thead.bulk-upload.classlink_tenant_id': StudentColumn.ClassLinkTenantId,
    'tabulator.thead.pawn_id': 'Pawn Id',
    'tabulator.thead.result_created_username': 'Created Username',
    'tabulator.thead.result_license_activated_extended': 'License Expiration',
    'tabulator.thead.result_free_trial_activated': 'Free Trial Expiration',
    'tabulator.thead.result_out_of_license': 'Out Of License',
    'tabulator.thead.result_errors': 'Errors',
    'tabulator.thead.total': 'Total',
    'tabulator.thead.recent_emotion_check_ins': 'Recent Check Ins',
    'tabulator.thead.activity-change-type': 'Change Type',
    'tabulator.thead.activity-change-subtype': 'Change SubType',
    'tabulator.thead.details': 'Details',
    'tabulator.thead.affected_schools': 'Affected Schools',
    'tabulator.thead.affected_teams': 'Affected Groups',
    'tabulator.thead.affected_admins': 'Affected Educators',
    'tabulator.thead.affected_students': 'Affected Students',
    'tabulator.thead.group-data-ic': 'Impulse Control',
    'tabulator.thead.group-data-com': 'Communication',
    'tabulator.thead.group-data-coop': 'Cooperation',
    'tabulator.thead.group-data-ini': 'Social Initiation',
    'tabulator.thead.group-data-emp': 'Empathy',
    'tabulator.thead.group-data-er': 'Emotion Regulation',
    'tabulator.thead.skill_e1': 'Respect',
    'tabulator.thead.skill_e2': 'Self Management',
    'tabulator.thead.skill_e3': 'Responsibility',
    'tabulator.thead.skill_e4': 'Communication',
    'tabulator.thead.skill_e5': 'Self Awareness',
    'tabulator.thead.skill_e6': 'Friendship',
    'tabulator.thead.skill_e7': 'Cooperating',
    'tabulator.thead.skill_e8': 'Emotion Regulation',
    'tabulator.thead.skill_e9': 'Review',
    'tabulator.thead.game_debug_mode': 'Game Debug Mode',
    'tabulator.thead.clever_user_id': 'Clever Id',
    'tabulator.thead.classlink_login_id': 'ClassLink Login Id',
    'tabulator.thead.classlink_tenant_id': 'ClassLink Tenant Id',
    'tabulator.thead.happy': 'Happy',
    'tabulator.thead.okay': 'Okay',
    'tabulator.thead.worried': 'Worried',
    'tabulator.thead.sad': 'Sad',
    'tabulator.thead.mad': 'Mad',
    'tabulator.thead.lesson': 'Lesson Title',
    'tabulator.thead.duration': 'Duration',
    'tabulator.thead.overview': 'Overview',
    'tabulator.thead.resource_type': 'Resource Type',
    'tabulator.thead.usage': 'Usage',
    'tabulator.thead.skills': 'SEL Skills',
    'tabulator.thead.grade_levels': 'Recommended Grade Level',
    'tabulator.thead.programs_evaluation_goal_option': 'Programs Evaluation Goal',
};

export default tabulator;
