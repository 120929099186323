import enApp from '../en/app';

let app = { ...enApp, ...{
    'button.login': '登入',
    'button.logout': '登出',
    'button.program-logout': '不是{name}嗎? 請登出',
    'button.go-home': '回到主頁',
    'button.play': '玩中文版',

    'app.welcome-back.line1': '歡迎回來，{name}',
    'app.welcome-back.line2': '很高興見到你。',
    'app.welcome-user': '歡迎，{name}',

    'footer.copyright': '著作權 {year}',
    'footer.marketing-website-name': '酷樂福',
    'footer.terms': '使用條款',
    'footer.privacy': '隱私權政策',

    'error-pages.page-not-found': '找不到此頁',
    'error-pages.forbidden': '沒有權限',
    'error-boundary.chunk-load.line-1': '網絡錯誤',
    'error-boundary.chunk-load.line-2': '重新加載此頁面以重試',
}};

app = { ...app, ...{
    'button.play.academy-en': 'Play in English',
    'button.play.academy-zh': app['button.play'],
    'button.play.zoou-en': 'Play in English',
    'button.play.zoou-zh': app['button.play'],
}};

export default app;
