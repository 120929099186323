import { DashboardType } from '../../state/ducks/notifications';

const dashboard = {
    'dashboard.notifications.title': `My Notifications`,
    'dashboard.notifications.missing': `There are no recent updates to display`,

    'dashboard.notification.button.dismiss': `Dismiss`,

    [`dashboard.notification.${DashboardType.Welcome}.title`]: `Welcome to Centervention`,
    [`dashboard.notification.${DashboardType.Welcome}.body`]: `Your first step is to create one or more student accounts
        and assign each student to a program. {br}{br}
        {button} {br}{br}
        If you have any questions, please click on Chat in the lower left corner, and we'll be happy to help! {br}{br}
        And to learn more about your Centervention Educator Account, please read our {link}.`,
    [`dashboard.notification.${DashboardType.Welcome}.link`]: `Educator Quick Start Guide`,

    [`dashboard.notification.${DashboardType.GamesPreview}.title`]: `Game Preview`,
    [`dashboard.notification.${DashboardType.GamesPreview}.body`]: `To preview the student experience,
        please click one of the following links: {academy} {zoou} {ssgrin} {hoh} {sim}`,
    [`dashboard.notification.${DashboardType.GamesPreview}.academy.link`]: `Zoo Academy (Grades K-1)`,
    [`dashboard.notification.${DashboardType.GamesPreview}.zoou.link`]: `Zoo U (Grades 2-4)`,
    [`dashboard.notification.${DashboardType.GamesPreview}.ssgrin.link`]: `SS GRIN (Grades 4-5)`,
    [`dashboard.notification.${DashboardType.GamesPreview}.hoh.link`]: `Hall of Heroes (Middle School)`,
    [`dashboard.notification.${DashboardType.GamesPreview}.sim.link`]: `Stories in Motion (Students with Autism)`,

    [`dashboard.notification.${DashboardType.StudentLoginWithoutLicense}.title`]: `Expired License`,
    [`dashboard.notification.${DashboardType.StudentLoginWithoutLicense}.body`]: `The following 
        {countStudents, plural, one {student} other {students}} attempted to login with an expired or invalid license:
        {usernames}.`,

    [`dashboard.notification.${DashboardType.StudentMassTransferToAnotherEducator}.title`]: `Student Account
        Shared or Transferred`,
    [`dashboard.notification.${DashboardType.StudentMassTransferToAnotherEducator}.body`]: `{issuer} has
        {wasShared, select, 1 {shared} other {transferred}} the following student
        {countStudents, plural, one {account} other {accounts}}:
        {names}
        {more, select, 0 {} other {and {more} more.}}`,

    [`dashboard.notification.${DashboardType.WeeklyEmotionCheckIn}.title`]: `Emotion Check-Ins From Last Week`,
    [`dashboard.notification.${DashboardType.WeeklyEmotionCheckIn}.body`]: `Here's a recap
        of Emotion Check-Ins from last week: {emotions}
        {button}`,
    [`dashboard.notification.${DashboardType.WeeklyEmotionCheckIn}.button`]: `Review Emotion Check-Ins`,

    [`dashboard.notification.${DashboardType.WeeklyTopStudents}.title`]: `Top Students From Last Week`,
    [`dashboard.notification.${DashboardType.WeeklyTopStudents}.body`]: `Total student minutes last week:
        {totalMinutes} {br}{br}
        Students with the most minutes last week: {names}
        {button}`,
    [`dashboard.notification.${DashboardType.WeeklyTopStudents}.button`]: `Review Top Students`,

    [`dashboard.notification.${DashboardType.LicensesExpiringNextMonth}.title`]: `Expiring Licenses`,
    [`dashboard.notification.${DashboardType.LicensesExpiringNextMonth}.body`]: `{licenses}
        student {licenses, plural, one {license} other {licenses}} will expire in the next 30 days. {br}{br}
        {button}`,
    [`dashboard.notification.${DashboardType.LicensesExpiringNextMonth}.button`]: `Review Expiring Licenses`,

    [`dashboard.notification.${DashboardType.SiteLicenseUsageReport}.title`]: `Centervention Usage Report`,
    [`dashboard.notification.${DashboardType.SiteLicenseUsageReport}.body`]: `You have a new Centervention Usage Report
        available. Please click the button below to view the report. {br}{br}
        {button}`,
    [`dashboard.notification.${DashboardType.SiteLicenseUsageReport}.button`]: `View Usage Report`,

    [`dashboard.notification.global.back-to-school.title`]: `Back to School Tips`,
    [`dashboard.notification.global.back-to-school.body`]: `Check out our Back To School Tips PDF
        for helpful instructions to get your roster updated for this school year. {br}{br}
        {button}`,
    [`dashboard.notification.global.back-to-school.button`]: `View Back to School Tips`,
};

export default dashboard;
