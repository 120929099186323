import { createSelector } from 'reselect';
import { ModalsState } from './types';
import { RootState } from '../../types';

const modals = (state: RootState): ModalsState => state.modals;

const modalsCount = createSelector(
    [modals],
    (modals: ModalsState): number => {
        return Object.keys(modals).length;
    }
);

export default {
    modalsCount,
};
