import React, { FunctionComponent } from 'react';
import 'typeface-roboto';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppContent from './AppContent';
import Snackbars from './components/snackbars/Snackbars';
import ReplaceAccessTokenListener from './components/common/ReplaceAccessTokenListener';

const App: FunctionComponent = () => (
    <>
        <CssBaseline/>
        <BrowserRouter>
            <Snackbars/>
            <ReplaceAccessTokenListener/>
            <AppContent/>
        </BrowserRouter>
    </>
);

export default App;
