import React, { FunctionComponent } from 'react';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import { endpoints } from '../../../../../api';
import LinkExternal from '../../../common/LinkExternal';
import styles from '../login/_login.module.sass';

type Props = {
    submitting: boolean,
    switchToLogin: () => void,
}

const ResetMyPasswordAdditionalFooter: FunctionComponent<Props> = ({ submitting, switchToLogin }) => (
    <div className={styles.bottomContainer}>
        <p>
            <LinkExternal to={endpoints.external.support}>
                <FormattedMessage id="app.contact-us-for-assistance" />
            </LinkExternal>
        </p>
        <p>
            <Link component="button" onClick={() => ! submitting && switchToLogin()}>
                <FormattedMessage id="app.go-back-to-login" />
            </Link>
        </p>
    </div>
);

export default ResetMyPasswordAdditionalFooter;
