import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Footer from '../../components/layout/Footer';
import PublicFooterLinks from './../common/PublicFooterLinks';
import LinkExternal from '../../components/common/LinkExternal';
import styles from './_common.module.sass';

type Props = {
    classes?: string | undefined,
}

const PublicFooter: FunctionComponent<Props> = ({ classes = undefined }) => (
    <Footer classes={classNames(styles.footer, classes)}>
        <div>
            <PublicFooterLinks/>
        </div>
        <div>
            Email: <LinkExternal to="mailto:info@centervention.com" newWindow={false}>
                info@centervention.com
            </LinkExternal>
        </div>
    </Footer>
);

export default PublicFooter;
