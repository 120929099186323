import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinkMaterial from '@material-ui/core/Link';
import appMode from './app-mode';
import config from '../../../../../config';
import LinkExternal from '../../../common/LinkExternal';
import styles from './_login.module.sass';

type Props = {
    submitting: boolean,
    closeLogin: () => void,
    switchToResetPassword: () => void,
}

const LoginAdditionalFooter: FunctionComponent<Props> = ({ submitting, closeLogin, switchToResetPassword }) => {
    if (appMode.hideLoginAdditionalFooter()) {
        return null;
    }

    const path = () => {
        const path = '/account/request';

        if (config.isStudentSubdomain) {
            return `${path}?key=student-login-modal`;
        }

        return `${path}?key=login-modal`;
    }

    if (config.isStudentSubdomain) {
        return <>
            <div className={styles.bottomContainer}>
                <strong><FormattedMessage id="app.need-educator-account"/></strong>
                &nbsp;
                <LinkExternal newWindow={false} to={`https://${config.dashboardSubdomain}.${config.domain}${path()}`}>
                    <FormattedMessage id="app.click-here"/>
                </LinkExternal>
            </div>
        </>;
    }

    return <>
        <div className={styles.bottomContainer}>
            <p className={styles.password}>
                <FormattedMessage id="app.forgot-password"/>
                &nbsp;
                <LinkMaterial component="button" onClick={() => ! submitting && switchToResetPassword()}>
                    <FormattedMessage id="app.reset-password"/>
                </LinkMaterial>
            </p>
            <Divider className={styles.divider}/>
            <div className={styles.requestAccount}>
                <h3><FormattedMessage id="app.need-educator-account"/></h3>
                <Link to={path()} onClick={closeLogin}>
                    <Button variant="contained" color="primary">
                        <FormattedMessage id="app.request-educator-account"/>
                    </Button>
                </Link>
            </div>
        </div>
    </>;
};

export default LoginAdditionalFooter;
