const resolveClever = (isSandbox: boolean): { clientId: string, redirectDomain: string } => {
    const clientId = process.env.REACT_APP_CLEVER_CLIENT_ID || '';
    const productionRedirectDomain = process.env.REACT_APP_PRODUCTION_CLEVER_REDIRECT_DOMAIN || '';
    const sandboxRedirectDomain = process.env.REACT_APP_SANDBOX_CLEVER_REDIRECT_DOMAIN || '';

    if (isSandbox) {
        return {
            clientId,
            redirectDomain: sandboxRedirectDomain,
        };
    }

    return {
        clientId,
        redirectDomain: productionRedirectDomain,
    };
};

export default resolveClever;
