import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './_layout.module.sass';

type Props = {
    classes?: string | undefined,
}

const Footer: FunctionComponent<Props> = ({ children, classes = undefined }) => (
    <footer className={classNames(styles.footer, styles.shadow, classes)}>
        {children}
    </footer>
);

export default Footer;
