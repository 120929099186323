import { AuthLoginFormValues } from '../../../../../state/ducks/auth';
import { createValidation, LabelOverrides, Rules } from '../../../../../validation';

const rules: Rules<AuthLoginFormValues> = {
    username: ['required'],
    password: ['required'],
};

export const labelOverrides: LabelOverrides<AuthLoginFormValues> = {
    username: 'email',
};

const appValidation = createValidation<AuthLoginFormValues>(rules, labelOverrides);
const studentValidation = createValidation<AuthLoginFormValues>(rules);

export default {
    appValidation,
    studentValidation,
};
