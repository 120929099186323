import { createReducer } from '../../utils';
import types, {
    ApiItem,
    NameAddAction,
    TabulatorName,
    TabulatorState,
    SelectAllAction,
    SelectRowAction,
    FetchPageAction,
    FetchFreshAction,
    ForceFreshAction,
    NameRemoveAction,
    PageChangeAction,
    SelectBulkAction,
    SetCurrentAction,
    TabulatorSegment,
    TabulatorSorting,
    DeselectAllAction,
    TabulatorSelected,
    FilterChangeAction,
    LoadingBeginAction,
    NameActivateAction,
    LoadingCommitAction,
    SortingChangeAction,
    FilterClearAllAction,
    DownloadingBeginAction,
    DownloadingCommitAction,
    TabulatorSingleNameState,
} from './types';

const initialState: TabulatorState = {};
const nextTabulatorByName = (state: TabulatorState, name: TabulatorName): TabulatorSingleNameState => {
    const tabulator = state[name];
    const next = {} as TabulatorSingleNameState;

    next.byId = { ...tabulator.byId };
    next.selected = { ...tabulator.selected };
    next.pages = { ...tabulator.pages };
    next.filters = { ...tabulator.filters };
    next.sorting = { ...tabulator.sorting };
    next.loadedAt = { ...tabulator.loadedAt };
    next.loading = tabulator.loading ? { ...tabulator.loading } : false;

    return { ...tabulator, ...next };
};
const nameInit = (action: NameAddAction): TabulatorSingleNameState => ({
    segment: action.payload.segment,
    endpoint: action.payload.endpoint,
    byId: {},
    selected: {},
    selectableLimit: action.payload.selectableLimit,
    pages: {},
    filtered: 0,
    total: 0,
    totalFromCache: false,
    page: 1,
    filters: action.payload.filters,
    sorting: action.payload.sorting,
    loading: false,
    downloading: false,
    isCurrent: false,
    lastFreshFetchAt: 0,
    forceFreshFetchAfter: action.payload.forceFreshFetchAfter,
    loadedAt: {},
    forceFresh: false,
    shareFiltersWith: action.payload.shareFiltersWith,
});
const nameTempAction = (action: NameActivateAction): NameAddAction => ({
    type: types.NAME_ADD,
    payload: {
        name: action.payload.tab,
        segment: TabulatorSegment.Temp,
        endpoint: '/api/temp',
        selectableLimit: undefined,
        filters: action.payload.filters,
        sorting: action.payload.sorting || {},
        forceFreshFetchAfter: 0,
        shareFiltersWith: [],
    },
});

const nameAdd = (state: TabulatorState, action: NameAddAction) => {
    if (! state[action.payload.name]) {
        return { ...state, [action.payload.name]: nameInit(action) };
    }

    if (state[action.payload.name].segment === TabulatorSegment.Temp) {
        return { ...state, [action.payload.name]: {
            ...nameInit(action),
            filters: state[action.payload.name].filters,
            sorting: state[action.payload.name].sorting,
        }};
    }

    return state;
};

const nameActivate = (state: TabulatorState, action: NameActivateAction) => {
    return nameAdd(state, nameTempAction(action));
};

const nameRemove = (state: TabulatorState, action: NameRemoveAction) => {
    const { [action.payload.name]: next, ...rest } = state;

    return { ...rest };
};

const fetchPage = (state: TabulatorState, action: FetchPageAction | FetchFreshAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.pages[next.page] = [];

    action.payload.data.forEach(item => {
        next.byId[item.id] = action.payload.mapper(item);
        next.pages[next.page].push(item.id);
    });

    next.filtered = action.payload.filtered;
    next.total = action.payload.total;
    next.totalFromCache = action.payload.totalFromCache;
    next.loadedAt[next.page] = action.payload.at;
    next.forceFresh = false;

    return { ...state, [action.payload.name]: next };
};

const fetchFresh = (state: TabulatorState, action: FetchFreshAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.byId = {};
    next.pages = {};
    next.lastFreshFetchAt = action.payload.at;

    return fetchPage({ ...state, [action.payload.name]: next }, action);
};

const pageChange = (state: TabulatorState, action: PageChangeAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.page = action.payload.page;

    const shareFiltersWith = next.shareFiltersWith.reduce((result, name) => {
        const shareWith = nextTabulatorByName(state, name);
        shareWith.page = action.payload.page;
        return { ...result, [name]: shareWith };
    }, {});

    return { ...state, [action.payload.name]: next, ...shareFiltersWith };
};

const filterChange = (state: TabulatorState, action: FilterChangeAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.filters = { ...next.filters, ...action.payload.filter };
    Object.keys(next.filters).filter(key => next.filters[key] === '' && delete next.filters[key]);

    return { ...state, [action.payload.name]: next };
};

const filterClearAll = (state: TabulatorState, action: FilterClearAllAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.filters = {};

    return { ...state, [action.payload.name]: next };
};

const sortingChange = (state: TabulatorState, action: SortingChangeAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    const toggle = (current: TabulatorSorting, action: SortingChangeAction): TabulatorSorting => {
        if (current[action.payload.column] === 'asc') {
            return { [action.payload.column]: 'desc' };
        }

        if (current[action.payload.column] === 'desc') {
            return { [action.payload.column]: 'asc' };
        }

        return { [action.payload.column]: action.payload.first };
    };

    next.sorting = toggle(next.sorting, action);

    return { ...state, [action.payload.name]: next };
};

const loadingBegin = (state: TabulatorState, action: LoadingBeginAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.loading = action.payload.cancelTokenSource;

    return { ...state, [action.payload.name]: next };
};

const loadingCommit = (state: TabulatorState, action: LoadingCommitAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.loading = false;

    return { ...state, [action.payload.name]: next };
};

const downloadingBegin = (state: TabulatorState, action: DownloadingBeginAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.downloading = true;

    return { ...state, [action.payload.name]: next };
};

const downloadingCommit = (state: TabulatorState, action: DownloadingCommitAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.downloading = false;

    return { ...state, [action.payload.name]: next };
};

const setCurrent = (state: TabulatorState, action: SetCurrentAction): TabulatorState => {
    const tabulators = Object.keys(state).reduce((result, name) => {
        const next = nextTabulatorByName(state, name);
        next.isCurrent = false;
        return { ...result, [name]: next };
    }, {});

    const next = nextTabulatorByName(state, action.payload.name);
    next.isCurrent = true;

    return { ...tabulators, [action.payload.name]: next };
};

const forceFresh = (state: TabulatorState, action: ForceFreshAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.forceFresh = true;

    return { ...state, [action.payload.name]: next };
};

const selectRow = (state: TabulatorState, action: SelectRowAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    const toggle = (selected: TabulatorSelected, action: SelectRowAction) => {
        if (selected.hasOwnProperty(action.payload.id)) {
            return (({ [action.payload.id]: del, ...rest } = selected) => rest)();
        }

        if (Object.keys(selected).length >= Math.min(next.selectableLimit as number, next.total)) return selected;
        return { ...selected, ...{ [action.payload.id]: next.byId[action.payload.id] } };
    };

    next.selected = toggle(next.selected, action);

    return { ...state, [action.payload.name]: next };
};

const selectAll = (state: TabulatorState, action: SelectAllAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    const toggle = (currentlySelected: TabulatorSelected) => {
        return action.payload.items.reduce((selected, item: ApiItem) => {
            return Object.keys(selected).length >= (next.selectableLimit as number)
                ? selected
                : { ...selected, [item.id]: item };
        }, currentlySelected);
    };

    const selected = toggle(next.selected);
    next.selected = selected;

    return { ...state, [action.payload.name]: next };
};

const selectBulk = (state: TabulatorState, action: SelectBulkAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.selected = action.payload.items.reduce((result, item) => ({ ...result, [item.id]: item }), next.selected);

    return { ...state, [action.payload.name]: next };
};

const deselectAll = (state: TabulatorState, action: DeselectAllAction): TabulatorState => {
    const next = nextTabulatorByName(state, action.payload.name);

    next.selected = {};

    return { ...state, [action.payload.name]: next };
};

const signOut = (): TabulatorState => {
    return { ...initialState };
};

const reducers = createReducer(initialState, {
    [types.NAME_ADD]: nameAdd,
    [types.NAME_ACTIVATE]: nameActivate,
    [types.NAME_REMOVE]: nameRemove,
    [types.FETCH_PAGE]: fetchPage,
    [types.FETCH_FRESH]: fetchFresh,
    [types.PAGE_CHANGE]: pageChange,
    [types.FILTER_CHANGE]: filterChange,
    [types.FILTER_CLEAR_ALL]: filterClearAll,
    [types.SORTING_CHANGE]: sortingChange,
    [types.LOADING_BEGIN]: loadingBegin,
    [types.LOADING_COMMIT]: loadingCommit,
    [types.DOWNLOADING_BEGIN]: downloadingBegin,
    [types.DOWNLOADING_COMMIT]: downloadingCommit,
    [types.SET_CURRENT]: setCurrent,
    [types.FORCE_FRESH]: forceFresh,
    [types.SELECT_ROW]: selectRow,
    [types.SELECT_ALL]: selectAll,
    [types.SELECT_BULK]: selectBulk,
    [types.DESELECT_ALL]: deselectAll,
    [types.SIGN_OUT]: signOut,
} as any);

export default reducers;
