import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import appMode from './../app-mode';
import routes from '../../../routes/utils';
import Header from '../../components/layout/Header';
import { authSelectors, modalsOperations, RootState } from '../../../state';
import createModalSettings from '../../components/modals/create-modal-settings';
import styles from './_common.module.sass';

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

const connector = connect((state: RootState) => ({
    user: authSelectors.user(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
    isActingAsAnotherUser: authSelectors.isActingAsAnotherUser(state),
}), {
    handleModalOpen: modalsOperations.open,
});

const StudentHeader: FunctionComponent<Props> = ({
    user,
    handleModalOpen,
    isAuthenticated,
    isActingAsAnotherUser,
    history,
}) => {
    const handleLoginModalOpen = useCallback(() => {
        handleModalOpen(createModalSettings('login'));
    }, [handleModalOpen]);

    const handleLogout = useCallback(() => {
        routes.logoutStudent(history, isActingAsAnotherUser, false);
    }, [history, isActingAsAnotherUser]);

    return <Header classes={styles.header}>
        {appMode.publicHeaderLogo()}
        <>
            {! isAuthenticated && <Button
                variant="outlined"
                size="small"
                onClick={handleLoginModalOpen}
            >
                <FormattedMessage id="button.login"/>
            </Button>}

            {isAuthenticated && <section>
                {isActingAsAnotherUser && <span className={styles.actingAs}>
                    <FormattedMessage id="app.you-are-acting-as" /> {user.username}
                </span>}
                <span>
                    <FormattedMessage id="app.welcome-user" values={{ name: user.fullName || user.username }} />
                </span>
                <span>|</span>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleLogout}
                >
                    <FormattedMessage id="button.logout"/>
                </Button>
            </section>}
        </>
    </Header>;
};

export default withRouter(connector(StudentHeader));
