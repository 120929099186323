import { createSelector } from 'reselect';
import { RootState } from '../../types';
import {
    SidebarType,
    SidebarStatus,
    DashboardState,
    SidebarSingleItemState,
    SidebarAccountRequestsPendingVerificationData,
} from './types';

const sidebar = (state: RootState): SidebarSingleItemState[] => state.notifications.sidebar;
const dashboard = (state: RootState): DashboardState => state.notifications.dashboard;

const sidebarList = createSelector(
    [sidebar],
    (sidebar: SidebarSingleItemState[]): SidebarSingleItemState[] => {
        return sidebar.filter(item => item.isListable);
    }
);

const sidebarCountNew = createSelector(
    [sidebar],
    (sidebar: SidebarSingleItemState[]): number => {
        return sidebar.filter(item => item.isNew).length;
    }
);

const sidebarIsAnyProcessing = createSelector(
    [sidebar],
    (sidebar: SidebarSingleItemState[]): boolean => {
        return sidebar.filter(item => item.status === SidebarStatus.Processing).length > 0;
    }
);

const sidebarAccountRequestsPendingVerification = createSelector(
    [sidebar],
    (sidebar: SidebarSingleItemState[]): number => {
        const item = sidebar.find(item => item.type === SidebarType.AccountRequestsPendingVerification);

        return item ? (item.data as SidebarAccountRequestsPendingVerificationData).count : 0;
    }
);

const dashboardTotal = createSelector(
    [dashboard],
    (dashboard: DashboardState): number => {
        return dashboard.total < 0 ? 0 : dashboard.total;
    }
);

export default {
    sidebarList,
    sidebarCountNew,
    sidebarIsAnyProcessing,
    sidebarAccountRequestsPendingVerification,
    dashboardTotal,
};
