import React, { FunctionComponent } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './_dialog.module.sass';

const Footer: FunctionComponent = ({ children }) => (
    <DialogActions key="footer" className={styles.footer}>
        {children}
    </DialogActions>
);

export default Footer;
