import { optionValues } from '../../views/components/form/select';

const options = {
    [`options.account-source.${optionValues.SOURCE_FORM}`]: 'Form',
    [`options.account-source.${optionValues.SOURCE_ADMIN}`]: 'Admin',
    [`options.account-source.${optionValues.SOURCE_INVITE}`]: 'Colleague',
    [`options.account-source.${optionValues.SOURCE_UBER}`]: 'Uber',

    'options.status.not-activated': 'pending activation',
    'options.status.not-verified': 'pending verification',
    'options.status.on-hold': 'on hold',
    'options.status.verified': 'active and verified',
    'options.status.deactivated': 'deactivated',
    'options.status.without-deactivated': 'without deactivated',
    'options.status.pending': 'pending',
    'options.status.created': 'created',
    'options.status.updated': 'updated',
    'options.status.failed': 'failed',

    'options.grade.p': 'Pre-K',
    'options.grade.pre-kindergarten': 'Pre-Kindergarten',
    'options.grade.k': 'K',
    'options.grade.kindergarten': 'Kindergarten',

    'options.group-type.district': 'District',
    'options.group-type.school': 'School',

    [`options.${optionValues.MY_ROLE_EDUCATOR}`]: 'Educator',
    [`options.${optionValues.MY_ROLE_ADMINISTRATOR}`]: 'Administrator',
    [`options.${optionValues.MY_ROLE_CLINICIAN}`]: 'Clinician',
    [`options.${optionValues.MY_ROLE_HOME}`]: 'Parent or Homeschool Educator',
    [`options.${optionValues.MY_ROLE_STUDENT}`]: 'Student',
    [`options.${optionValues.MY_ROLE_OTHER}`]: 'Other',

    [`options.${optionValues.CV_GOAL_NOT_SURE}`]: 'I\'m not sure, but I\'d like to explore the programs with a few students',
    [`options.${optionValues.CV_GOAL_PURCHASE}`]: 'I\'m actively considering Centervention for purchase',
    [`options.${optionValues.CV_GOAL_FUTURE}`]: 'I\'m not actively considering purchasing, but I might in the future',

    [`options.${optionValues.ISSUER_USER}`]: 'User',
    [`options.${optionValues.ISSUER_STRIPE}`]: 'Stripe',
    [`options.${optionValues.ISSUER_ACCOUNT_ACTIVATION}`]: 'Activation',
    [`options.${optionValues.ISSUER_ACCOUNT_VERIFICATION}`]: 'Verification',
    [`options.${optionValues.ISSUER_INTERNAL_SCRIPT}`]: 'Internal Script',

    [`options.${optionValues.PLAN_FREE_TRIAL}`]: 'Free Trial',
    [`options.${optionValues.PLAN_INDIVIDUAL}`]: 'Individual',
    [`options.${optionValues.PLAN_SITE}`]: 'Site',

    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_PLAN_CHANGE}`]: 'Plan Change',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_PO}`]: 'PO',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_QUOTE}`]: 'Quote',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_CHANGE}`]: 'Plan Change, PO, Quote',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_ASSIGN}`]: 'Assign',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_ROLLBACK}`]: 'Rollback',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_TRANSFER}`]: 'Transfer',
    [`options.${optionValues.ECOMMERCE_CHANGE_TYPE_GIFT}`]: 'Free License',

    [`options.${optionValues.ACTIVITY_ADD_EDUCATOR}`]: 'Create Educator Account',
    [`options.${optionValues.ACTIVITY_EDUCATOR_DEACTIVATE}`]: 'Deactivate Educator',
    [`options.${optionValues.ACTIVITY_STUDENT_MASS_TRANSFER_TO_ANOTHER_EDUCATOR}`]: 'Transfer Student',
    [`options.${optionValues.ACTIVITY_SITE_LICENSE_GRANT_ACCESS}`]: 'Site License Grant Access',
    [`options.${optionValues.ACTIVITY_SITE_LICENSE_REVOKE_ACCESS}`]: 'Site License Revoke Access',

    'options.yes': 'Yes',
    'options.no': 'No',

    'options.active': 'Active',
    'options.inactive': 'Inactive',

    [`options.${optionValues.EMOTION_CHECK_IN_MAD}`]: 'Mad',
    [`options.${optionValues.EMOTION_CHECK_IN_SAD}`]: 'Sad',
    [`options.${optionValues.EMOTION_CHECK_IN_WORRIED}`]: 'Worried',
    [`options.${optionValues.EMOTION_CHECK_IN_OKAY}`]: 'Okay',
    [`options.${optionValues.EMOTION_CHECK_IN_HAPPY}`]: 'Happy',

    'options.site-tier.custom': 'Custom',
    'options.site-tier.up-to': 'Up To {quantity} Students',

    'options.group-data.emerging': 'Emerging',
    'options.group-data.improving': 'Improving',
    'options.group-data.proficient': 'Proficient',

    'options.elementary': 'Elementary',
    'options.middle': 'Middle',

    'options.ic': 'Impulse Control',
    'options.com': 'Communication',
    'options.coop': 'Cooperation',
    'options.ini': 'Social Initiation',
    'options.emp': 'Empathy',
    'options.er': 'Emotion Regulation',

    [`options.${optionValues.RESOURCE_TYPE_WORKSHEET}`]: 'Worksheet',
    [`options.${optionValues.RESOURCE_TYPE_VIDEO}`]: 'Video',
    [`options.${optionValues.RESOURCE_TYPE_ACTIVITY}`]: 'Activity',
    [`options.${optionValues.RESOURCE_TYPE_QUIZ}`]: 'Quiz',
    [`options.${optionValues.RESOURCE_TYPE_ARTICLE}`]: 'Article',

    [`options.${optionValues.USAGE_GROUP}`]: 'Group',
    [`options.${optionValues.USAGE_INDIVIDUAL}`]: 'Individual',

    [`options.${optionValues.DAY}`]: 'Day',
    [`options.${optionValues.WEEK}`]: 'Week',
    [`options.${optionValues.MONTH}`]: 'Month',
    [`options.${optionValues.CUSTOM}`]: 'Custom',

    'options.cdc': 'Cool Down Corner',
};

export default options;
