import React from 'react';
import { AnyObject } from 'final-form';
import { FormRenderProps } from 'react-final-form';

type Props<FormValues extends AnyObject> = {
    formRenderProps: FormRenderProps<FormValues>,
    children: React.ReactNode,
    classes?: string | undefined,
}

const FormContainer = <FormValues extends AnyObject>({
    formRenderProps,
    children,
    classes = undefined,
}: Props<FormValues>) => (
    <form onSubmit={formRenderProps.handleSubmit} noValidate autoComplete="off" className={classes}>
        {children}
    </form>
);

export default FormContainer;
