import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
    show: boolean,
    color?: 'primary' | 'secondary'
    mr?: number,
}

const ButtonLoader: FunctionComponent<Props> = ({ show, children, color = 'primary', mr = 2 }) => (
    <>
        {show && <Box height={22} mr={mr}>
            <CircularProgress size={22} color={color} />
        </Box>}
        {children}
    </>
);

export default ButtonLoader;
