import { browserStorage } from '../../../state/utils';
import { AxiosRequestConfigWithDelay, AxiosRequestConfigWithDelayOrPromise } from '../../types';

const activeAt = (config: AxiosRequestConfigWithDelay): AxiosRequestConfigWithDelayOrPromise => {
    browserStorage.setActiveNow();

    return config;
};

export default activeAt;
