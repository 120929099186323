import React from 'react';

type Props = {
    to: string,
}

class RedirectExternal extends React.Component<Props> {
    componentDidMount() {
        window.location.href = this.props.to;
    }

    render() {
        return null;
    }
}

export default RedirectExternal;
