import { UIState } from './ducks/ui';
import { AuthState } from './ducks/auth';
import { applyAccessToken } from '../api';
import { browserStorage, tabStorage } from './utils/index';

const createDefaultAuthState = (): AuthState => {
    const accessToken = browserStorage.accessToken();
    const user = browserStorage.user();
    applyAccessToken(accessToken);

    return { accessToken, user, actingAs: undefined };
};

const createDefaultUIState = (): UIState => {
    const { sidebar, ...rest } = tabStorage.ui() || browserStorage.ui();
    return { sidebar: { ...sidebar, uberToolsIsOpen: false }, lastTab: undefined, confetti: false, ...rest };
};

const createDefaultState = () => {
    return {
        auth: createDefaultAuthState(),
        ui: createDefaultUIState(),
    };
};

export default createDefaultState;
