import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import customersLeft from '../../assets/app/customers-left.png';
import customersRight from '../../assets/app/customers-right.png';
import styles from './_common.module.sass';

const CenterventionPartners: FunctionComponent = () => (
    <Grid container className={styles.centerventionPartners}>
        <Grid container justify="center" className={styles.partnersTitle}>
            <FormattedMessage id="footer.account-request"/>
        </Grid>
        <Grid container>
            <Grid item xs={12} sm={6} className={styles.partnersLeft}>
                <img src={customersLeft} alt="" className={styles.partnersImg}/>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.partnersRight}>
                <img src={customersRight} alt="" className={styles.partnersImg}/>
            </Grid>
        </Grid>
    </Grid>
);

export default CenterventionPartners;
