export enum TabulatorSegment {
    AccountSourcesForm = 'account-sources-form',
    AccountSourcesReferral = 'account-sources-referral',
    AccountSourcesUber = 'account-sources-uber',
    Districts = 'districts',
    Schools = 'schools',
    Teams = 'teams',
    Educators = 'educators',
    Parents = 'parents',
    TransferLicensesHistory = 'transfer-licenses-history',
    PurchaseOverview = 'purchase-overview',
    PurchaseHistory = 'purchase-history',
    ActivityHistory = 'activity-history',
    SiteLicense = 'site-license',
    Students = 'students',
    SelCheckIns = 'sel-check-ins',
    StudentsBulkUpload = 'students-bulk-upload',
    StudentsBulkUploadRow = 'students-bulk-upload-row',
    AssignProgramHistory = 'assign-program-history',
    EcommerceHistory = 'ecommerce-history',
    MetricsEducator = 'metrics-educator',
    MetricsAdmin = 'metrics-admin',
    MetricsOutOfLicense = 'metrics-out-of-license',
    MetricsGiftsInviteDetails = 'metrics-gifts-invite-details',
    MetricsGiftsInviteAggregated = 'metrics-gifts-invite-aggregated',
    Lessons = 'lessons',
    Temp = 'temp',
};

export enum TabulatorNames {
    AccountSourcesForm = 'account-sources-form',
    AccountSourcesReferral = 'account-sources-referral',
    AccountSourcesUber = 'account-sources-uber',
    Districts = 'districts',
    Schools = 'schools',
    Teams = 'teams',
    Educators = 'educators',
    Parents = 'parents',
    TransferLicensesHistory = 'transfer-licenses-history',
    StudentsActive = 'students-active',
    StudentsArchived = 'students-archived',
    StudentsBulkUpload = 'students-bulk-upload',
    StudentsBulkUploadRow = 'students-bulk-upload-row',
    ChildrenActive = 'children-active',
    ChildrenArchived = 'children-archived',
    PurchaseOverview = 'purchase-overview',
    PurchaseHistory = 'purchase-history',
    ActivityHistory = 'activity-history',
    EducatorMetrics = 'educator-metrics',
    AdminMetrics = 'admin-metrics',
    OutOfLicenseMetrics = 'out-of-license-metrics',
    GiftsInviteDetailsMetrics = 'gifts-invite-details-metrics',
    GiftsInviteAggregatedMetrics = 'gifts-invite-aggregated-metrics',
    Lessons = 'lessons',
    Academy = 'academy',
    AcademyArchived = 'academy-archived',
    Zoou = 'zoou',
    ZoouArchived = 'zoou-archived',
    Ssgrin = 'ssgrin',
    SsgrinArchived = 'ssgrin-archived',
    Hoh = 'hoh',
    HohArchived = 'hoh-archived',
    Sim = 'sim',
    SimArchived = 'sim-archived',
    MyStudentsActive = 'my-students-active',
    MyStudentsArchived = 'my-students-archived',
    AllStudentsActive = 'all-students-active',
    AllStudentsArchived = 'all-students-archived',
    MySelCheckInsActive = 'my-sel-check-ins-active',
    MySelCheckInsArchived = 'my-sel-check-ins-archived',
    AllSelCheckInsActive = 'all-sel-check-ins-active',
    AllSelCheckInsArchived = 'all-sel-check-ins-archived',
    MyReportsActive = 'my-reports-active',
    MyReportsArchived = 'my-reports-archived',
    AllReportsActive = 'all-reports-active',
    AllReportsArchived = 'all-reports-archived',
    MyGroupDataAcademyActive = 'my-group-data-academy-active',
    MyGroupDataAcademyArchived = 'my-group-data-academy-archived',
    AllGroupDataAcademyActive = 'all-group-data-academy-active',
    AllGroupDataAcademyArchived = 'all-group-data-academy-archived',
    MyGroupDataZoouActive = 'my-group-data-zoou-active',
    MyGroupDataZoouArchived = 'my-group-data-zoou-archived',
    AllGroupDataZoouActive = 'all-group-data-zoou-active',
    AllGroupDataZoouArchived = 'all-group-data-zoou-archived',
    MyGroupDataSsgrinActive = 'my-group-data-ssgrin-active',
    MyGroupDataSsgrinArchived = 'my-group-data-ssgrin-archived',
    AllGroupDataSsgrinActive = 'all-group-data-ssgrin-active',
    AllGroupDataSsgrinArchived = 'all-group-data-ssgrin-archived',
    MyGroupDataHohActive = 'my-group-data-hoh-active',
    MyGroupDataHohArchived = 'my-group-data-hoh-archived',
    AllGroupDataHohActive = 'all-group-data-hoh-active',
    AllGroupDataHohArchived = 'all-group-data-hoh-archived',
    MyChildren = 'my-children',
    ChildrenProgress = 'children-progress',
    AssignProgramHistory = 'assign-program-history',
    EcommerceHistory = 'ecommerce-history',
}

export interface ApiItem {
    id: number,
}

export interface District extends ApiItem {
    id: number,
    name: string,
    state: string,
    prefix: string,
    created_at: string,
    schools: number,
    teams: number,
}

export interface School extends ApiItem {
    id: number,
    parent_id: number,
    name: string,
    state: string,
    district: string,
    prefix: string,
    created_at: string,
    teams: number,
    admins: number,
    active_students: number,
    archived_students: number,
    active_students_accessed_last_month: number,
}

export interface Team extends ApiItem {
    id: number,
    name: string,
    pool_status_individual_remaining: boolean,
    pool_status_site_initial: boolean,
    pool_status_site_remaining: boolean,
    site_ends_at: string,
    site_licenses: number,
    district: {
        id: number,
        name: string,
    },
    school: {
        id: number,
        name: string,
    },
    owner: {
        id: number,
        username: string
        first_name: string,
        last_name: string,
    },
    admins: number,
    members: number,
    created_at: string,
}

export interface Educator extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    created_at: string,
    activated_at: string,
    verified: boolean,
    accounts: number,
    school: string,
    teams: number,
    site_access: boolean,
    valid_license_students: number,
    active_students: number,
    archived_students: number,
    free_trial_ends_at: string,
    individual_remaining: number,
    individual_quantity: number,
    individual_used: number,
    reclaimable_licenses: number,
    last_accessed: string,
    status: string,
    is_uber: boolean,
    is_self: boolean,
}

export interface Parent extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    created_at: string,
    activated_at: string,
    active_students: number,
    archived_students: number,
    free_trial_ends_at: string,
    individual_remaining: number,
    individual_quantity: number,
    individual_used: number,
    last_accessed: string,
}

export interface TransferLicensesHistory extends ApiItem {
    id: number,
    quantity: number,
    from_username: string,
    from_first_name: string,
    from_last_name: string,
    from_name: string,
    to_username: string,
    to_first_name: string,
    to_last_name: string,
    to_name: string,
    transferred_at: string,
}

export interface AccountSourceForm extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    district: string,
    school: string,
    role: string,
    programs_evaluation_goal: string,
    programs_evaluation_goal_option: string,
    referrer: string,
    status: string,
    created_at: string,
    activated_at: string,
    account_type: string
}

export interface AccountSourceReferral extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    invited_by: {
        id: number,
        name: string,
    },
    district: string,
    school: string,
    source: string,
    status: string,
    created_at: string,
    activated_at: string,
    account_type: string
}

export interface AccountSourceUber extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    status: string,
    created_at: string,
    activated_at: string,
}

export interface PurchaseOverview extends ApiItem {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
    created_at: string,
    activated_at: string,
    total_quantity: number,
    checkout_completed_quantity: number,
    purchase_order_quantity: number,
    request_quote_quantity: number,
    quote_quantity: number,
    last_purchase: string,
    last_request_quote_id: number
    last_request_quote: string,
    last_quote_id: number
    last_quote: string,
    individual_remaining: number,
    individual_quantity: number,
    individual_used: number,
    current_site_license: number,
}

export interface ActivityHistory extends ApiItem {
    id: number,
    issuer_user_and_issuer_uber: string,
    type: string,
    subtype: string,
    data: any,
    affected_schools: number,
    affected_teams: number,
    affected_admins: number,
    affected_students: number,
    created_at: string,
}

export interface Student extends ApiItem {
    id: number,
    username: string,
    full_name: string,
    created_at: string,
    updated_at: string,
    last_accessed: string,
    grade: number,
    teacher: string,
    student_id: string,
    game_debug_mode: boolean,
    clever_user_id: string,
    classlink_login_id: string,
    classlink_tenant_id: string,
    archived: boolean,
    school: string,
    district: string,
    educators: number,
    bulk_uploads: number,
    ends_at: string,
    active_program: string,
    time_played: number,
    cv_time_played: number,
    total_time_played: number,
    academy_time_played: number,
    zoou_time_played: number,
    ssgrin_time_played: number,
    hoh_time_played: number,
    sim_time_played: number,
    progress: any,
    academy_reports: {
        current_scenes: number,
        max_scenes: number,
    },
    zoou_reports: {
        current_scenes_total: number,
        current_assessment_scenes: number,
        current_unlocked_assessment: number,
        max_assessment_scenes: number,
        current_skill_builder_scenes: number,
        max_skill_builder_scenes: number,
        any_silo_completed: boolean,
    },
    ssgrin_reports: {
        current_scenes: number,
        max_scenes: number,
    },
    hoh_reports: {
        current_scenes_total: number,
        current_assessment_scenes: number,
        max_assessment_scenes: number,
        current_skill_builder_scenes: number,
        max_skill_builder_scenes: number,
    },
    sim_reports: {
        comics: number,
    },
    academy_ends_at: string,
    zoou_ends_at: string,
    ssgrin_ends_at: string,
    hoh_ends_at: string,
    sim_ends_at: string,
    academy_rollback: boolean,
    zoou_rollback: boolean,
    ssgrin_rollback: boolean,
    hoh_rollback: boolean,
    sim_rollback: boolean,
    assessment_ic: number,
    assessment_com: number,
    assessment_coop: number,
    assessment_ini: number,
    assessment_emp: number,
    assessment_er: number,
    skill_builder_ic: number,
    skill_builder_com: number,
    skill_builder_coop: number,
    skill_builder_ini: number,
    skill_builder_emp: number,
    skill_builder_er: number,
    skill_ic: number,
    skill_com: number,
    skill_coop: number,
    skill_ini: number,
    skill_emp: number,
    skill_er: number,
    skill_e1: number,
    skill_e2: number,
    skill_e3: number,
    skill_e4: number,
    skill_e5: number,
    skill_e6: number,
    skill_e7: number,
    skill_e8: number,
    skill_e9: number,
}

export interface SelCheckIn extends ApiItem {
    id: number,
    username: string,
    full_name: string,
    total: number,
    happy: number,
    okay: number,
    worried: number,
    sad: number,
    mad: number,
    active_program: string,
}

export interface StudentBulkUpload extends ApiItem {
    id: number,
    provider: string,
    school: string,
    rows: number,
    pending: number,
    created: number,
    updated: number,
    failed: number,
    out_of_license: number,
    never_detach_other_providers: boolean,
    allow_duplicates: boolean,
    archive_not_affected: boolean,
    archive_not_affected_exclude_from: string,
    archived: number,
    affected: number,
    created_at: string,
}

export interface StudentBulkUploadRow extends ApiItem {
    id: number,
    first_name: string,
    last_name: string,
    grade: string,
    teacher: string,
    student_id: string,
    student_username: string,
    educator_username: string,
    activate_license: string,
    assign_program: string,
    clever_user_id: string,
    classlink_login_id: string,
    classlink_tenant_id: string,
    status: string,
    pawn_id: number,
    result_created_username: string,
    result_license_activated_extended: string,
    result_out_of_license: boolean,
    result_free_trial_activated: string,
    result_errors: string,
}

export interface AssignProgramHistory extends ApiItem {
    id: number,
    plan: string,
    plan_owner: string,
    program: string,
    is_used: boolean,
    is_current: boolean,
    assign_issuer_and_assign_uber: string,
    rollback_issuer_and_rollback_uber: string,
    ends_at: string,
    assigned_at: string,
    switched_at: string,
    deleted_at: string,
    rollback: boolean,
}

export interface EcommerceHistory extends ApiItem {
    id: number,
    issuer: string,
    issuer_user_and_issuer_uber: string,
    change: string,
    plan: string,
    change_owner: string,
    quantity: number,
    starts_at: string,
    ends_at: string,
    purchase_order_amount: number,
    purchase_order_date: string,
    purchase_order_number: string,
    quote_id: number,
    quote_amount: number,
    quote_downloaded: number,
    school_year_ends_at: string,
    product: string,
    student: string,
    created_at: string,
}

export interface SiteLicense extends ApiItem {
    id: number,
    starts_at: string,
    ends_at: string,
    remaining: number,
    quantity: number,
    used: number,
    created_at: string,
}

export interface MetricsEducator extends ApiItem {
    id: number,
    username: string,
    accounts: number,
    created_at: string,
    activated_at: string,
    reached_out_at: string,
    high_priority: boolean,
    last_30_days_access: number,
    account_source: string,
    checkout_completed: number,
    last_quote_created_at: string,
    last_quote_quantity: number,
    last_quote_downloaded: boolean,
    active_individual: number,
    expiring_individual: number,
    created_students: number,
    total_hours_played: number,
}

export interface MetricsAdmin extends ApiItem {
    id: number,
    acting_as_id: number,
    username: string,
    created_at: string,
    activated_at: string,
    reached_out_at: string,
    high_priority: boolean,
    last_30_days_access: number,
    generated_at: string,
    educators: number,
    active_individual: number,
    expiring_individual_in_30_days: number,
    expiring_individual_in_7_days: number,
    created_students: number,
    created_students_last_30_days: number,
    total_hours_played: number,
    total_hours_played_last_30_days: number,
}

export interface MetricsOutOfLicense extends ApiItem {
    id: number,
    username: string,
    created_at: string,
    activated_at: string,
    active_individual: number,
    teams: number,
    teams_owner_with_open_pool: number,
    individual_remaining: number,
    individual_quantity: number,
    individual_used: number,
    last_license_used_at: string,
    last_license_used_change: string,
}

export interface MetricsGiftsInviteDetails extends ApiItem {
    id: number,
    given_at: string,
    invitee: string,
    invitee_created_at: string,
    invitee_verified_at: string,
    invitor: string,
}

export interface MetricsGiftsInviteAggregated extends ApiItem {
    id: number,
    invitor: string,
    invitees_verified: number,
    invitees_licenses_given: number,
    invitees_percent_of_completed: number,
    all_licenses_given: number,
    last_given_at: string,
}

export interface Lesson extends ApiItem {
    id: number,
    name: string,
    overview: string,
    materials: string,
    student_materials_anchor: string,
    duration: number,
    resource_type: string,
    usage: string[],
    skills: string[],
    prep_levels: string[],
    grade_levels: string[],
    challenges: string[],
    image_url: string,
    pdf_educator_url: string,
    pdf_student_url: string,
    is_my_lesson: boolean,
    created_at: string,
}
