import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './_loaders.module.sass';

const ModalInlineLoader = () => (
    <section className={styles.modalLoader}>
        <CircularProgress size={75}/>
    </section>
);

export default ModalInlineLoader;
