import React from 'react';
import { FormattedMessage } from 'react-intl';

type State = {
    hasError: boolean,
}

class ChunkLoadErrorBoundary extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): State {
        if (error.name === 'ChunkLoadError') {
            return { hasError: true };
        }

        return { hasError: false };
    }

    render() {
        if (this.state.hasError) {
            return <>
                <h1 style={{ textAlign: 'center' }}>
                    <FormattedMessage id="error-boundary.chunk-load.line-1"/>
                </h1>
                <h1 style={{ textAlign: 'center' }}>
                    <FormattedMessage id="error-boundary.chunk-load.line-2"/>
                </h1>
            </>;
        }

        return this.props.children;
    }
}

export default ChunkLoadErrorBoundary;
