import React from 'react';
import { FormattedMessage } from 'react-intl';
import { intl } from '../i18n';
import config from '../config';
import { Student } from '../state';
import { CV, Programs } from './types';
import hohLogo from '../views/assets/hoh/logo.png';
import simLogo from '../views/assets/sim/logo.png';
import zoouLogo from '../views/assets/zoou/logo.png';
import ssgrinLogo from '../views/assets/ssgrin/logo.png';
import academyLogo from '../views/assets/academy/logo.png';

const all = (): Programs[] => {
    return [
        Programs.academy,
        Programs.zoou,
        Programs.ssgrin,
        Programs.hoh,
        Programs.sim,
    ];
};

const missAcademyReport = (row: Student): boolean => {
    return row.academy_reports.max_scenes < 6;
};

const missZoouSingleReport = (row: Student): boolean => {
    return missZoouAssessment(row);
};

const missZoouAssessment = (row: Student): boolean => {
    return row.zoou_reports.max_assessment_scenes < 6;
};

const missZoouSkillBuilder = (row: Student): boolean => {
    return ! row.zoou_reports.any_silo_completed || row.zoou_reports.max_skill_builder_scenes < 1;
};

const isZoouSkillBuilderClickable = (row: Student): boolean => {
    return row.zoou_reports.current_skill_builder_scenes > 0;
};

const missSsgrinReport = (row: Student): boolean => {
    return row.ssgrin_reports.max_scenes < 1;
};

const missHohSingleReport = (row: Student): boolean => {
    return missHohAssessment(row);
};

const missHohAssessment = (row: Student): boolean => {
    return row.hoh_reports.max_assessment_scenes < 6;
};

const missHohSkillBuilder = (row: Student): boolean => {
    return row.hoh_reports.max_skill_builder_scenes < 8;
};

const missSimLibrary = (row: Student): boolean => {
    return row.sim_reports.comics < 1;
};

const sentryDSN = (program: Programs | CV): string => {
    return config.sentryDSN[program];
};

const styles = (styles: any, style: string, program: Programs | CV): string => {
    const programUpperCase = firstToUpperCase(program);
    const styleOfProgram = `${style}${programUpperCase}`;

    return styles[styleOfProgram];
};

const logo = (program: Programs, img?: string): React.ReactNode => {
    const logo = img || {
        [Programs.academy]: academyLogo,
        [Programs.zoou]: zoouLogo,
        [Programs.ssgrin]: ssgrinLogo,
        [Programs.hoh]: hohLogo,
        [Programs.sim]: simLogo,
    }[program];
    const alt = intl.formatMessage({ id: `program.${program}.title` });

    return <img src={logo} alt={alt}/>;
};

const title = (program: Programs): string => {
    return config.programs[program].title;
};

const targetGroupDescription = (program: Programs): JSX.Element => {
    return <FormattedMessage id={`${program}.target.group.description`}/>;
};

const targetGroupSuggestion = (program: Programs): JSX.Element => {
    return <FormattedMessage id={`${program}.target.group.suggestion`}/>;
};

const hostname = (): string => {
    const subdomain = config.studentSubdomain;

    return `${subdomain}.${config.domain}`;
};

const url = (): string => {
    return `https://${hostname()}`;
};

const demoUrl = (program: Programs): string => {
    return `/educator/demo/${program}`;
};

const firstToUpperCase = (name: string = ''): string => {
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};

export default {
    all,
    missAcademyReport,
    missZoouSingleReport,
    missZoouAssessment,
    missZoouSkillBuilder,
    isZoouSkillBuilderClickable,
    missSsgrinReport,
    missHohSingleReport,
    missHohAssessment,
    missHohSkillBuilder,
    missSimLibrary,
    sentryDSN,
    styles,
    logo,
    title,
    targetGroupDescription,
    targetGroupSuggestion,
    hostname,
    url,
    demoUrl,
};
