import actions from './actions';
import config from '../../../config';
import { authSelectors, Scope } from './index';
import { browserStorage, tabStorage } from '../../utils';
import exceptionTracker from '../../../exception-tracker';
import { AuthLoginFormValues, AppThunkAction } from './types';
import { mapAccessToken, mapActingAsUser, mapProfile, mapUser, nullAccessToken, nullUser } from './mappers';
import {
    url,
    apiLogin,
    endpoints,
    apiLogout,
    apiActingAs,
    apiHandshake,
    applyActingAs,
    clearActingAs,
    applyAccessToken,
    extractFormErrorsOrFail,
} from '../../../api';

const signIn = (values: AuthLoginFormValues, redirect: () => void): AppThunkAction => async (dispatch) => {
    try {
        const response = await apiLogin.post(endpoints.app.login, values);
        const accessToken = mapAccessToken(response.data.access_token);
        const user = mapUser(response.data.user);
        const ecommerce = response.data.ecommerce;
        const notifications = response.data.notifications;
        dispatch(actions.signIn(accessToken, user, ecommerce, notifications));
        applyAccessToken(accessToken);
        clearActingAs();
        browserStorage.setUser(user);
        browserStorage.setAccessToken(accessToken);
        browserStorage.setActiveNow();
        tabStorage.signOut();
        exceptionTracker.setUser();
        redirect();
        return {};
    } catch (error) {
        const accessToken = nullAccessToken();
        const user = nullUser();
        dispatch(actions.signIn(accessToken, user));
        applyAccessToken(accessToken);
        clearActingAs();
        browserStorage.signOut();
        tabStorage.signOut();
        exceptionTracker.clearUser();

        try {
            return extractFormErrorsOrFail(error);
        } catch (e) {
        }

        return { password: 'validation.password-incorrect' };
    }
};

const handshake = (callback: () => void): AppThunkAction => async (dispatch) => {
    const path = endpoints.app.handshake;

    try {
        const response = await apiHandshake.get(path);
        const accessToken = mapAccessToken(response.data.access_token);
        const user = mapUser(response.data.user);
        const ecommerce = response.data.ecommerce;
        const notifications = response.data.notifications;
        dispatch(actions.signIn(accessToken, user, ecommerce, notifications));
        applyAccessToken(accessToken);
        clearActingAs();
        browserStorage.setUser(user);
        browserStorage.setAccessToken(accessToken);
        tabStorage.signOut();
        exceptionTracker.setUser();
        callback();
    } catch (e) {
        const accessToken = nullAccessToken();
        const user = nullUser();
        dispatch(actions.signIn(accessToken, user));
        applyAccessToken(accessToken);
        clearActingAs();
        browserStorage.signOut();
        tabStorage.signOut();
        exceptionTracker.clearUser();
        exceptionTracker.info(e, path);
        callback();
    }
};

const handshakeLite = (callback: () => void): AppThunkAction => async (dispatch) => {
    const path = endpoints.app.handshakeLite;

    try {
        const response = await apiHandshake.get(path);
        const ecommerce = response.data.ecommerce;
        const notifications = response.data.notifications;
        dispatch(actions.handshakeLite(ecommerce, notifications));
        callback();
    } catch (e) {
        callback();
    }
};

const login = (data: any): AppThunkAction => async (dispatch) => {
    const accessToken = mapAccessToken(data.access_token);
    const user = mapUser(data.user);
    const ecommerce = data.ecommerce;
    const notifications = data.notifications;
    dispatch(actions.signIn(accessToken, user, ecommerce, notifications));
    applyAccessToken(accessToken);
    clearActingAs();
    browserStorage.setUser(user);
    browserStorage.setAccessToken(accessToken);
    tabStorage.signOut();
};

const replaceAccessToken = (originalAccessToken: string, username: string): AppThunkAction => async (dispatch, getState) => {
    if (getState().auth.user.username !== username) return;
    if (authSelectors.isStudent(getState())) return;
    if (! authSelectors.isAuthenticated(getState())) return;

    const accessToken = mapAccessToken(originalAccessToken);
    dispatch(actions.replaceAccessToken(accessToken));
    applyAccessToken(accessToken);
};

const signOut = (redirect: () => void): AppThunkAction => async (dispatch) => {
    const path = endpoints.app.logout;

    try {
        await apiLogout.delete(path);
    } catch (e) {
    }

    const accessToken = nullAccessToken();
    const user = nullUser();
    dispatch(actions.signOut(accessToken, user));
    applyAccessToken(accessToken);
    clearActingAs();
    browserStorage.signOut();
    tabStorage.signOut();
    exceptionTracker.clearUser();
    redirect();
};

const actingAs = (
    id: number,
    type: string,
    success: () => void,
    failed: () => void
): AppThunkAction => async (dispatch) => {
    const path = url.actingAs(endpoints.uber.actingAs, type, id);

    try {
        const response = await apiActingAs.get(path);
        const user = mapActingAsUser(response.data.data.user);
        const ecommerce = response.data.data.ecommerce;
        const notifications = response.data.data.notifications;
        dispatch(actions.actingAs(user, ecommerce, notifications));
        applyActingAs(user);
        exceptionTracker.setUser(user);
        config.subdomain = type;
        config.isStudentSubdomain = user.scope === Scope.Student;
        document.title = user.scope === Scope.Student ? user.username : user.fullName;
        success();
    } catch (e) {
        failed();
    }
};

const refreshProfile = (callback: () => void): AppThunkAction => async (dispatch) => {
    const path = endpoints.app.profile;

    try {
        const response = await apiHandshake.get(path);
        const profile = mapProfile(response.data.data);
        dispatch(actions.refreshProfile(profile));
        browserStorage.setProfile(profile);
        callback();
    } catch (e) {
        callback();
    }
};

export default {
    signIn,
    handshake,
    handshakeLite,
    login,
    replaceAccessToken,
    signOut,
    actingAs,
    refreshProfile,
};
