import { TabUser } from '../ducks/auth';
import { UIPersist } from '../ducks/ui';
import exceptionTracker from '../../exception-tracker';

const TAB_USER = 'tab_user';
const TAB_UI = 'tab_ui';

const setUser = (user: TabUser): void => {
    try {
        sessionStorage.setItem(TAB_USER, JSON.stringify(user));
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const removeUser = (): void => {
    try {
        sessionStorage.removeItem(TAB_USER);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const user = (): TabUser | undefined => {
    let user = undefined;
    try {
        user = sessionStorage.getItem(TAB_USER);
    } catch (e) {
        exceptionTracker.console(e);
    }

    if (user) {
        return JSON.parse(user);
    }

    return undefined;
};

const setUI = (uiPersist: UIPersist): void => {
    try {
        sessionStorage.setItem(TAB_UI, JSON.stringify(uiPersist));
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const ui = (): UIPersist | undefined => {
    let persisted = undefined;
    try {
        persisted = sessionStorage.getItem(TAB_UI);
    } catch (e) {
        exceptionTracker.console(e);
    }

    const initialPersist: UIPersist = {
        sidebar: {
            isCollapsed: false,
            uberIsOpen: true,
            studentsIsOpen: true,
            accountSettingsIsOpen: true,
        },
        tabs: {},
        video: {
            isOverviewCollapsed: false,
        },
    };

    if (persisted) {
        persisted = JSON.parse(persisted);

        return {
            sidebar: { ...initialPersist.sidebar, ...persisted.sidebar },
            tabs: { ...initialPersist.tabs, ...persisted.tabs },
            video: { ...initialPersist.video, ...persisted.video },
        };
    }

    return undefined;
};

const signOut = (): void => {
    removeUser();
};

export default {
    setUser,
    removeUser,
    user,
    setUI,
    ui,
    signOut,
};
