import React, { FunctionComponent } from 'react';
import { OptionsObject, useSnackbar, WithSnackbarProps } from 'notistack';

type Props = {
    setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void,
};

const InnerUtilsSnackbarConfigurator: FunctionComponent<Props> = (props) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
    useSnackbarRef = useSnackbarRefProp;
};

type InnerSnackbarCallback = (snackbarCloseCallback: () => void) => void;
type InnerHttp401Callback = () => void;
type UtilsProps = {
    snackbarCallback: InnerSnackbarCallback,
    http401Callback: InnerHttp401Callback,
};

let innerSnackbarCallback: InnerSnackbarCallback;
let innerHttp401Callback: InnerHttp401Callback;
export const UtilsSnackbarConfigurator: FunctionComponent<UtilsProps> = ({ snackbarCallback, http401Callback }) => {
    innerSnackbarCallback = snackbarCallback;
    innerHttp401Callback = http401Callback;
    return <InnerUtilsSnackbarConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const persisted: OptionsObject = {
    persist: true,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
};

type SnackbarMsg = JSX.Element | string;

const snackbars = {
    success(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'success' });
    },
    successPersist(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'success', ...persisted });
    },
    warning(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'warning' });
    },
    warningPersist(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'warning', ...persisted });
    },
    info(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'info' });
    },
    infoPersist(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'info', ...persisted });
    },
    error(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'error' });
    },
    errorPersist(msg: SnackbarMsg, options: OptionsObject = {}) {
        this.toast(msg, { ...options, variant: 'error', ...persisted });
    },
    error401Persist(msg: SnackbarMsg, options: OptionsObject = {}) {
        innerHttp401Callback();
        this.toast(msg, { ...options, variant: 'error', ...persisted });
    },
    toast(msg: SnackbarMsg, options: OptionsObject = {}) {
        const key = useSnackbarRef.enqueueSnackbar(msg, options);
        innerSnackbarCallback(() => useSnackbarRef.closeSnackbar(key));
    },
};

export default snackbars;
