import { AutocompleteOption } from '../../../../form/types';

export type FormValues = {
    educator_selected: AutocompleteOption,
    school_selected: AutocompleteOption,
    csv_file: File,
    archive_not_affected: boolean,
    archive_not_affected_exclude_from: string,
    never_detach_other_providers: boolean,
    allow_duplicates: boolean,
}

export enum StudentColumn {
    FirstName = 'first_name',
    LastName = 'last_name',
    Grade = 'grade',
    Teacher = 'teacher',
    StudentId = 'student_id',
    EducatorUsername = 'educator_username',
    StudentUsername = 'student_username',
    ActivateLicense = 'activate_license',
    AssignProgram = 'assign_program',
    CleverUserId = 'clever_user_id',
    ClassLinkLoginId = 'classlink_login_id',
    ClassLinkTenantId = 'classlink_tenant_id',
}
