const resolveClasslink = (isSandbox: boolean): { clientId: string, redirectDomain: string } => {
    const productionClientId = process.env.REACT_APP_PRODUCTION_CLASSLINK_CLIENT_ID || '';
    const productionRedirectDomain = process.env.REACT_APP_PRODUCTION_CLASSLINK_REDIRECT_DOMAIN || '';
    const sandboxClientId = process.env.REACT_APP_SANDBOX_CLASSLINK_CLIENT_ID || '';
    const sandboxRedirectDomain = process.env.REACT_APP_SANDBOX_CLASSLINK_REDIRECT_DOMAIN || '';

    if (isSandbox) {
        return {
            clientId: sandboxClientId,
            redirectDomain: sandboxRedirectDomain,
        };
    }

    return {
        clientId: productionClientId,
        redirectDomain: productionRedirectDomain,
    };
};

export default resolveClasslink;
