import { ValidationMethod, ValidationMethods } from './types';
import { InputEducatorAccount, InputSwitchGroup } from '../views/components/form/types';

const required: ValidationMethod = (field, values) => {
    const value = values[field];

    if (typeof value === 'string' && !! value.trim()) {
        return undefined;
    }

    if (typeof value === 'number' && ! isNaN(value)) {
        return undefined;
    }

    if (typeof value === 'boolean' && value === true) {
        return undefined;
    }

    if (isInputEducatorAccount(value)) {
        const valid = (value as InputEducatorAccount[]).filter((account: InputEducatorAccount) => {
            return account.connect
                && account.school_selected
                && account.school_selected.hasOwnProperty('id');
        });

        if (!! valid.length) {
            return undefined;
        }
    }

    if (! isInputEducatorAccount(value) && Array.isArray(value) && value.length > 0) {
        return undefined;
    }

    if (typeof value === 'object' && value?.hasOwnProperty('id')) {
        return undefined;
    }

    if (value instanceof File) {
        return undefined;
    }

    return 'validation.required';
};

const integer: ValidationMethod = (field, values) => {
    const value = values[field];

    if (String(parseInt(value as string)) === String(value)) {
        return undefined;
    }

    return 'validation.integer';
};

const greaterThanZero: ValidationMethod = (field, values) => {
    const value = values[field];

    if (parseInt(value as string) > 0) {
        return undefined;
    }

    return 'validation.greater-than-zero';
};

const maxCCPurchase: ValidationMethod = (field, values) => {
    const value = values[field];

    if (parseInt(value as string) < 100) {
        return undefined;
    }

    return 'validation.max-cc-purchase';
};

const max1000: ValidationMethod = (field, values) => {
    const value = values[field];

    if (parseInt(value as string) <= 1000) {
        return undefined;
    }

    return 'validation.max-1000';
};

const max50000: ValidationMethod = (field, values) => {
    const value = values[field];

    if (parseInt(value as string) <= 50000) {
        return undefined;
    }

    return 'validation.max-50000';
};

const requiredAssignProgram: ValidationMethod = (field, values) => {
    const requiredValue = required(field, values);

    if (requiredValue === undefined) {
        return undefined;
    }

    if (requiredValue !== undefined && values[`${field}_later`]) {
        return undefined;
    }

    return 'validation.required-assign-program';
};

const requiredSwitchGroup: ValidationMethod = (field, values) => {
    if (typeof values[field] !== 'object') {
        return 'validation.required';
    }

    if (typeof (values[field] as []).filter !== 'function') {
        return 'validation.required';
    }

    const count = (values[field] as InputSwitchGroup[]).filter(value => value.value);

    if (! count.length) {
        return 'validation.required';
    }

    return undefined;
};

const validationMethods: ValidationMethods = {
    required,
    integer,
    greaterThanZero,
    maxCCPurchase,
    max1000,
    max50000,
    requiredAssignProgram,
    requiredSwitchGroup,
};

const isInputEducatorAccount = (value: any) => {
    return Array.isArray(value)
        && !! value.length
        && value[0].hasOwnProperty('connect')
        && value[0].hasOwnProperty('school_selected');
};

export default validationMethods;
