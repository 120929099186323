import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@material-ui/core/Select';
import { FieldRenderProps } from 'react-final-form';
import { Field, FieldProps } from 'react-final-form';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Option } from './select/types';
import styles from './_form.module.sass';

type Props = {
    options: Option[],
    withoutEmpty?: boolean
}

const InputDropdown: FunctionComponent<Props & FieldProps<any, FieldRenderProps<any>>> = (props) => (
    <Field
        label={<FormattedMessage id={`form.label.${props.i18n || props.name}`} />}
        {...props}
        render={({ input: { name, value, onChange, ...restInput }, meta, label, options, withoutEmpty, ...rest }) => {
            const showError = ((meta.submitError && ! meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

            return <FormControl variant="outlined" fullWidth error={showError} className={styles.field}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
                <Select
                    {...rest}
                    name={name}
                    onChange={onChange}
                    inputProps={restInput}
                    value={value}
                    label={label}
                    native
                >
                    {! withoutEmpty && <option key="" value=""></option>}
                    {options.map((option: Option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </Select>

                {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
            </FormControl>;
        }}
    />
);

export default InputDropdown;
