import React from 'react';
import { RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import config from '../config';
import RedirectExternal from '../views/components/common/RedirectExternal';

const legacy: RouteProps[] = [
    { path: '/fortress/login', component: () => <Redirect to="/"/>, exact: true },
    { path: '/passwordResetLink', component: () => <Redirect to="/"/>, exact: true },
    { path: '/dashboard', component: () => <Redirect to="/"/>, exact: true },
    { path: '/manageEducators/tabulator/admins/999999', component: () => <Redirect to="/"/>, exact: true },
    { path: '/account/success', component: () => <Redirect to="/"/>, exact: true },
    { path: '/library', component: () => <Redirect to="/"/>, exact: true },
    { path: '/play/library', component: () => <Redirect to="/"/>, exact: true },
    { path: '/playLegacy', component: () => <Redirect to="/"/>, exact: true },
    { path: '/student/play/academy', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/student/play/zoou', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/student/play/ssgrin', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/student/play/hoh', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/student/play/sim', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/student/play/cv', component: () => <Redirect to="/student/play"/>, exact: true },
    { path: '/play', component: () => <Redirect to="/student/play"/>, exact: true },
    {
        path: '/cart/buyHomeLicense',
        component: () => <RedirectExternal
            to={`https://${config.dashboardSubdomain}.${config.domain}/payment-family-subscription`}
        />,
        exact: true,
    },
    { path: '/index.php/', component: () => <Redirect to="/"/>, exact: true },
    { path: '/ ', component: () => <Redirect to="/"/>, exact: true },
    { path: '/--Once', component: () => <Redirect to="/"/>, exact: true },
    { path: '/about-Zoo-U', component: () => <Redirect to="/"/>, exact: true },
    { path: '/school', component: () => <Redirect to="/"/>, exact: true },
    { path: '/educator/academy', component: () => <Redirect to="/educator/reports"/>, exact: true },
    { path: '/educator/zoou', component: () => <Redirect to="/educator/reports"/>, exact: true },
    { path: '/educator/ssgrin', component: () => <Redirect to="/educator/reports"/>, exact: true },
    { path: '/educator/hoh', component: () => <Redirect to="/educator/reports"/>, exact: true },
    { path: '/educator/sim', component: () => <Redirect to="/educator/reports"/>, exact: true },
];

export default legacy;
