import global from '../types';

const SIDEBAR_ADD = 'notifications/sidebar/add';
const SIDEBAR_SEEN = 'notifications/sidebar/seen';
const SIDEBAR_REMOVE = 'notifications/sidebar/remove';
const SIDEBAR_SINGLETON = 'notifications/sidebar/singleton';
const SIDEBAR_TASK = 'notifications/sidebar/task';
const SIGN_IN = global.SIGN_IN;
const HANDSHAKE_LITE = global.HANDSHAKE_LITE;
const SIGN_OUT = global.SIGN_OUT;
const ACTING_AS = global.ACTING_AS;
const DASHBOARD_LOADING_BEGIN = 'notifications/dashboard/begin';
const DASHBOARD_LOADING_COMMIT = 'notifications/dashboard/commit';
const DASHBOARD_FETCH_PAGE = 'notifications/dashboard/fetch-page';
const DASHBOARD_DISMISS = 'notifications/dashboard/dismiss';

export default {
    SIDEBAR_ADD: SIDEBAR_ADD as typeof SIDEBAR_ADD,
    SIDEBAR_SEEN: SIDEBAR_SEEN as typeof SIDEBAR_SEEN,
    SIDEBAR_REMOVE: SIDEBAR_REMOVE as typeof SIDEBAR_REMOVE,
    SIDEBAR_SINGLETON: SIDEBAR_SINGLETON as typeof SIDEBAR_SINGLETON,
    SIDEBAR_TASK: SIDEBAR_TASK as typeof SIDEBAR_TASK,
    SIGN_IN: SIGN_IN as typeof SIGN_IN,
    HANDSHAKE_LITE: HANDSHAKE_LITE as typeof HANDSHAKE_LITE,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
    ACTING_AS: ACTING_AS as typeof ACTING_AS,
    DASHBOARD_LOADING_BEGIN: DASHBOARD_LOADING_BEGIN as typeof DASHBOARD_LOADING_BEGIN,
    DASHBOARD_LOADING_COMMIT: DASHBOARD_LOADING_COMMIT as typeof DASHBOARD_LOADING_COMMIT,
    DASHBOARD_FETCH_PAGE: DASHBOARD_FETCH_PAGE as typeof DASHBOARD_FETCH_PAGE,
    DASHBOARD_DISMISS: DASHBOARD_DISMISS as typeof DASHBOARD_DISMISS,
};

export * from './../../types';

export type SidebarId = string | undefined

export enum SidebarStatus {
    Processing = 'processing',
    Success = 'success',
    Failed = 'failed',
}

export enum SidebarType {
    DeployedApp = 'deployed-app',
    AccountRequestsPendingVerification = 'account-requests-pending-verification',
    TaskStudentCloneIntoSandbox = 'task-student-clone-into-sandbox',
    TaskStudentBulkUpload = 'task-student-bulk-upload',
}

export type SidebarDeployedAppData = { version: string }
export type SidebarAccountRequestsPendingVerificationData = { count: number }
export type SidebarTaskData = { task: { uuid: string, done: number, total: number, failed: boolean }, data: any }

export type SidebarData =
    SidebarDeployedAppData |
    SidebarAccountRequestsPendingVerificationData |
    SidebarTaskData

export type SidebarSingleItemState = {
    type: SidebarType,
    id: SidebarId,
    data: SidebarData,
    status: SidebarStatus,
    isFromApiResponse: boolean,
    isListable: boolean,
    isNew: boolean,
}

export type DashboardState = {
    notifications: DashboardNotification[],
    loading: boolean,
    total: number,
}

export type DashboardNotification = {
    id: number,
    type: DashboardType,
    data: any,
    created_at: string,
}

export enum DashboardType {
    Welcome = 'welcome',
    GamesPreview = 'games-preview',
    StudentLoginWithoutLicense = 'student-login-without-license',
    StudentMassTransferToAnotherEducator = 'student-mass-transfer-to-another-educator',
    WeeklyEmotionCheckIn = 'weekly-emotion-check-in',
    WeeklyTopStudents = 'weekly-top-students',
    LicensesExpiringNextMonth = 'licenses-expiring-next-month',
    SiteLicenseUsageReport = 'site-license-usage-report',
}

export interface NotificationsState {
    sidebar: SidebarSingleItemState[],
    dashboard: DashboardState,
}

export interface SidebarActionAdd {
    type: typeof SIDEBAR_ADD,
    payload: {
        type: SidebarType,
        id: SidebarId,
        data: SidebarData,
    },
}

export interface SidebarActionSeen {
    type: typeof SIDEBAR_SEEN,
    payload: {
        id: SidebarId,
    },
}

export interface SidebarActionRemove {
    type: typeof SIDEBAR_REMOVE,
    payload: {
        id: SidebarId,
    },
}

export interface SidebarActionSingleton {
    type: typeof SIDEBAR_SINGLETON,
    payload: {
        type: SidebarType,
        id: SidebarId,
        data: SidebarData,
    },
}

export interface SidebarActionTask {
    type: typeof SIDEBAR_TASK,
    payload: {
        type: SidebarType,
        id: SidebarId,
        data: SidebarTaskData,
        isFromApiResponse: boolean,
    },
}

export interface NotificationsSignInAction {
    type: typeof SIGN_IN,
    payload: {
        accessToken: any,
        user: any,
        ecommerce: any,
        notifications: number,
    }
}

export interface NotificationsSignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: any,
        user: any,
    }
}

export interface NotificationsActingAsAction {
    type: typeof ACTING_AS,
    payload: {
        actingAsUser: any,
        ecommerce: any,
        notifications: number,
    }
}

export interface DashboardLoadingBeginAction {
    type: typeof DASHBOARD_LOADING_BEGIN,
    payload: {
        loading: boolean,
    }
}

export interface DashboardLoadingCommitAction {
    type: typeof DASHBOARD_LOADING_COMMIT,
    payload: {
        loading: boolean,
    }
}

export interface DashboardFetchPageAction {
    type: typeof DASHBOARD_FETCH_PAGE,
    payload: {
        data: DashboardNotification[],
        total: number,
    }
}

export interface DashboardDismissAction {
    type: typeof DASHBOARD_DISMISS,
    payload: {
        id: number,
    }
}
