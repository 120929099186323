import React from 'react';
import { FormattedMessage } from 'react-intl';
import { apiActions } from '../../../api';
import snackbars from '../snackbars/utils';

const runApiAction = (settings: any, path: string, data: any = undefined) => {
    if (settings.method === 'delete') {
        return apiActions.delete(path);
    }

    if (settings.method === 'post') {
        return apiActions.post(path, data, settings.config);
    }

    return apiActions.patch(path, data);
};

const handleSuccessful = async (settings: any, props?: any, notifyValues?: object, responseData?: any) => {
    settings.i18nSuccessful && snackbars.success(
        <FormattedMessage id={settings.i18nSuccessful} values={{ br: <br />, ...notifyValues }}/>
    );

    settings.callback && await settings.callback(responseData);

    settings.name && props.close && props.close(settings.name);

    settings.refreshAfterAction && props.refresh && props.refresh(settings.refreshAfterAction);
};

const handleValidationFailed = (settings: any, errors: any) => {
    return settings.validation.errorsWithMessages(errors);
};

export {
    runApiAction,
    handleSuccessful,
    handleValidationFailed,
};
