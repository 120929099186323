import React, { FunctionComponent } from 'react';
import { FieldProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { TextField } from 'final-form-material-ui';
import { Field, FieldRenderProps } from 'react-final-form';
import styles from './_form.module.sass';

const InputText: FunctionComponent<FieldProps<any, FieldRenderProps<any>>> = (props) => (
    <Field
        type="text"
        component={TextField}
        variant="outlined"
        fullWidth
        label={<FormattedMessage id={`form.label.${props.i18n || props.name}`} />}
        className={styles.field}
        {...props}
    />
);

export default InputText;
