const educator = {
    changePassword: 'educator/change-password',
    classCode: 'educator/class-code',
    purchaseHistory: 'educator/purchase-history',
    inviteColleague: 'educator/invite-colleague',
    licensesNeeded: 'educator/licenses-needed',
    lessons: {
        lessons: 'educator/lessons',
        myLesson: 'educator/lessons/my-lesson',
    },
    dashboard: {
        notifications: 'educator/notifications',
    },
    requestQuote: {
        add: 'educator/request-quote',
        view: 'educator/request-quote-view',
    },
    educators: {
        educators: 'educator/educators',
        transferLicenses: 'educator/transfer-licenses',
        reclaimLicenses: 'educator/reclaim-licenses',
        deactivate: 'educator/deactivate',
        createAccount: 'educator/create-educator-account',
        ofStudentsList: 'educator/educators-of-students-list',
        siteLicenseGrantAccess: 'educator/site-license-grant-access',
        siteLicenseRevokeAccess: 'educator/site-license-revoke-access',
    },
    schools: {
        schoolsList: 'educator/schools-list',
        schoolsOfAdminList: 'educator/schools-of-admin-list',
        schoolsAndSchoolsOfAdminList: 'educator/schools-and-schools-of-admin-list',
        schoolsOfAdminAndGroupList: 'educator/schools-of-admin-and-group-list',
        schoolsOfEducatorsOfAdminList: 'educator/schools-of-educator-of-admin-list',
    },
    autocomplete: {
        educatorsOfSchool: 'educator/educators-of-school/autocomplete',
        educatorsWithThemselfOfSchool: 'educator/educators-with-themself-of-school/autocomplete',
    },
    printableReports: {
        siteLicenseUsage: 'educator/printable-reports/site-license-usage',
    },
    students: {
        students: 'educator/students',
        myActive: 'educator/my-students-active',
        myArchived: 'educator/my-students-archived',
        my: 'educator/my-students',
        allActive: 'educator/all-students-active',
        allArchived: 'educator/all-students-archived',
        all: 'educator/all-students',
        unarchive: 'educator/students/unarchive',
        massArchive: 'educator/students/mass-archive',
        massUnarchive: 'educator/students/mass-unarchive',
        massTransfer: 'educator/students/mass-transfer',
        massTransferByAdmin: 'educator/students/mass-transfer-by-admin',
        massGrade: 'educator/students/mass-grade',
        massTeacher: 'educator/students/mass-teacher',
        massPassword: 'educator/students/mass-password',
        rollbackMyIndividual: 'educator/assign-program/my-rollback',
        rollbackAllIndividual: 'educator/assign-program/all-rollback',
        assignProgramActivate: 'educator/assign-program/activate',
        assignProgramRenew: 'educator/assign-program/renew',
        assignProgramSwitch: 'educator/assign-program/switch',
        assignProgramMassIndividual: 'educator/assign-program/mass-individual',
        assignProgramMassSwitch: 'educator/assign-program/mass-switch',
        assignProgramMassDefaultSwitch: 'educator/assign-program/mass-default-switch',
        timePlayed: 'educator/time-played',
        massRestartActiveGame: 'educator/mass-restart-active-game',
        massAllProgramsReportDownload: 'educator/mass-all-programs-report-download',
    },
    selCheckIns: {
        myActive: 'educator/my-sel-check-ins-active',
        myActiveChart: 'educator/my-sel-check-ins-active/chart',
        myArchived: 'educator/my-sel-check-ins-archived',
        myArchivedChart: 'educator/my-sel-check-ins-archived/chart',
        allActive: 'educator/all-sel-check-ins-active',
        allActiveChart: 'educator/all-sel-check-ins-active/chart',
        allArchived: 'educator/all-sel-check-ins-archived',
        allArchivedChart: 'educator/all-sel-check-ins-archived/chart',
    },
    reports: {
        myActive: 'educator/my-reports-active',
        myArchived: 'educator/my-reports-archived',
        allActive: 'educator/all-reports-active',
        allArchived: 'educator/all-reports-archived',
    },
    academy: {
        myActive: 'educator/academy/my-students-active',
        myArchived: 'educator/academy/my-students-archived',
        allActive: 'educator/academy/all-students-active',
        allArchived: 'educator/academy/all-students-archived',
        report: 'educator/academy/report',
        massReportDownload: 'educator/academy/report/download',
        massRestartGame: 'educator/academy/restart-game',
        timePlayed: 'educator/academy/time-played',
    },
    zoou: {
        myActive: 'educator/zoou/my-students-active',
        myArchived: 'educator/zoou/my-students-archived',
        allActive: 'educator/zoou/all-students-active',
        allArchived: 'educator/zoou/all-students-archived',
        singleReport: 'educator/zoou/single-report',
        assessment: 'educator/zoou/assessment',
        skillBuilder: 'educator/zoou/report',
        skillBuilderBoard: 'educator/zoou/skill-builder-board',
        massAssessmentDownload: 'educator/zoou/assessment/download',
        massSkillBuilderDownload: 'educator/zoou/report/download',
        massUnlockAssessment: 'educator/zoou/unlock-assessment',
        massRestartGame: 'educator/zoou/restart-game',
        timePlayed: 'educator/zoou/time-played',
    },
    ssgrin: {
        myActive: 'educator/ssgrin/my-students-active',
        myArchived: 'educator/ssgrin/my-students-archived',
        allActive: 'educator/ssgrin/all-students-active',
        allArchived: 'educator/ssgrin/all-students-archived',
        report: 'educator/ssgrin/report',
        massReportDownload: 'educator/ssgrin/report/download',
        massRestartGame: 'educator/ssgrin/restart-game',
        timePlayed: 'educator/ssgrin/time-played',
    },
    hoh: {
        myActive: 'educator/hoh/my-students-active',
        myArchived: 'educator/hoh/my-students-archived',
        allActive: 'educator/hoh/all-students-active',
        allArchived: 'educator/hoh/all-students-archived',
        singleReport: 'educator/hoh/single-report',
        assessment: 'educator/hoh/assessment',
        skillBuilder: 'educator/hoh/report',
        massAssessmentDownload: 'educator/hoh/assessment/download',
        massSkillBuilderDownload: 'educator/hoh/report/download',
        massRestartGame: 'educator/hoh/restart-game',
        timePlayed: 'educator/hoh/time-played',
    },
    sim: {
        myActive: 'educator/sim/my-students-active',
        myArchived: 'educator/sim/my-students-archived',
        allActive: 'educator/sim/all-students-active',
        allArchived: 'educator/sim/all-students-archived',
        library: 'educator/sim/library',
        comic: 'educator/sim/comic',
        timePlayed: 'educator/sim/time-played',
    },
};

export default educator;
