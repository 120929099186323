import enApp from './en/app';
import enForm from './en/form';
import enHome from './en/home';
import enModal from './en/modal';
import enProgram from './en/program';
import enOptions from './en/options';
import enSidebar from './en/sidebar';
import enDashboard from './en/dashboard';
import enTabulator from './en/tabulator';
import enNotification from './en/notification';

import zhApp from './zh/app';
import zhForm from './zh/form';
import zhModal from './zh/modal';
import zhProgram from './zh/program';

export default {
    en: {
        ...enApp,
        ...enForm,
        ...enHome,
        ...enModal,
        ...enProgram,
        ...enOptions,
        ...enSidebar,
        ...enDashboard,
        ...enTabulator,
        ...enNotification,
    },
    zh: {
        ...zhApp,
        ...zhForm,
        ...enHome,
        ...zhModal,
        ...zhProgram,
        ...enOptions,
        ...enSidebar,
        ...enDashboard,
        ...enTabulator,
        ...enNotification,
    },
};
