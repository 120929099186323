import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps } from 'react-redux';
import { authSelectors, RootState } from '../../../state';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
}));

class ButtonPlay extends React.Component<RouteComponentProps & ConnectedProps<typeof connector>> {
    render() {
        return <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => this.props.history.push(this.props.homePath)}
        >
            <FormattedMessage id="button.play"/>
        </Button>;
    }
};

export default withRouter(connector(ButtonPlay));
