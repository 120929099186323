import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import MaterialSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './_form.module.sass';

type Props = {
    checked?: boolean,
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    i18n: string,
}

const Switch: FunctionComponent<Props> = ({ checked, handleChange, i18n }) => (
    <FormControlLabel
        label={<FormattedMessage id={`form.label.${i18n}`} />}
        className={styles.switch}
        control={
            <MaterialSwitch
                checked={checked}
                onChange={handleChange}
                color="primary"
            />
        }
    />
);

export default Switch;
