const logoutApp = (history: any, isActingAsAnotherUser: boolean) => {
    isActingAsAnotherUser
        ? window.close()
        : history.push('/logout');
};

const logoutStudent = (history: any, isActingAsAnotherUser: boolean, isPlayPage: boolean) => {
    if (! isPlayPage) {
        return logoutApp(history, isActingAsAnotherUser);
    }

    isActingAsAnotherUser
        ? window.close()
        : window.location.href = '/logout';
};

const redirectToPlayPage = (program: string | undefined = undefined, keepCurrentPageInBrowserHistory: boolean = false) => {
    const path = program
        ? `/student/play/${program}`
        : '/student/play';

    keepCurrentPageInBrowserHistory
        ? window.location.href = path
        : window.location.replace(path);
};

export default {
    logoutApp,
    logoutStudent,
    redirectToPlayPage,
};
