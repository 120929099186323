import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import config from '../../config';
import WelcomeApp from './welcome/WelcomeApp';
import WelcomeStudent from './welcome/WelcomeStudent';
import { authSelectors } from '../../state/ducks/auth';
import { modalsOperations, RootState } from '../../state/ducks/modals';
import createModalSettings from '../components/modals/create-modal-settings';

const connector = connect((state: RootState) => ({
    isAuthenticated: authSelectors.isAuthenticated(state),
}), {
    handleModalOpen: modalsOperations.open,
});

class WelcomeView extends React.Component<ConnectedProps<typeof connector>> {
    componentDidMount() {
        ! this.props.isAuthenticated && this.props.handleModalOpen(createModalSettings('login'));
    }

    render() {
        if (config.isStudentSubdomain) {
            return <WelcomeStudent/>;
        }

        return <WelcomeApp/>;
    }
};

export default connector(WelcomeView);
