import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import LinkButton from '../common/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import styles from './_errors.module.sass';

const Error404: FunctionComponent = () => (
    <section className={styles.error404}>
        <FontAwesomeIcon icon={faExclamationCircle}/>
        <FormattedMessage id="error-pages.page-not-found"/>
        <LinkButton i18n="go-home"/>
    </section>
);

export default Error404;
