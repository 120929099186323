import tabulator from './tabulator';

const form = {
    'form.label.username': 'Username',
    'form.label.admin-mode': 'admin mode',
    'form.label.password': 'Password',
    'form.label.account-password': 'Your Centervention Account Password',
    'form.label.password_confirmation': 'Confirm Password',
    'form.label.current_password': 'Current Password',
    'form.label.new_username': 'New Username',
    'form.label.new_username_confirmation': 'Confirm New Username',
    'form.label.current_username': 'Current Username',
    'form.label.new_password': 'New Password',
    'form.label.new_password_confirmation': 'Confirm New Password',
    'form.label.district': 'District Name',
    'form.label.district-name-confirm': 'Confirm Your District Name',
    'form.label.state': tabulator['tabulator.thead.state'],
    'form.label.prefix': tabulator['tabulator.thead.prefix'],
    'form.label.school': 'School Name',
    'form.label.school-name-confirm': 'Confirm Your School Name',
    'form.label.first_name': tabulator['tabulator.thead.first_name'],
    'form.label.last_name': tabulator['tabulator.thead.last_name'],
    'form.label.grade': tabulator['tabulator.thead.grade'],
    'form.label.teacher': tabulator['tabulator.thead.teacher'],
    'form.label.student_id': tabulator['tabulator.thead.student_id'],
    'form.label.clever_user_id': 'Clever User Id',
    'form.label.classlink_login_id': 'ClassLink Login Id',
    'form.label.classlink_tenant_id': 'ClassLink Tenant Id',
    'form.label.team': tabulator['tabulator.thead.team'],
    'form.label.email': 'Email',
    'form.label.account': 'Account',
    'form.label.school_id': 'School',
    'form.label.school-and-district': 'School, District',
    'form.label.select-district': 'Select District',
    'form.label.select-district-single-name': 'District',
    'form.label.select-account': 'Select School',
    'form.label.select-educator': 'Select Educator',
    'form.label.select-educator-single-name': 'Educator',
    'form.label.select-school': 'Select School',
    'form.label.select-school-single-name': 'School',
    'form.label.select-group-type': 'Select Group Type',
    'form.label.select-group-type-single-name': 'Group Type',
    'form.label.select-team': 'Select Group',
    'form.label.select-team-single-name': 'Group',
    'form.label.select-site': 'Select Site License',
    'form.label.select-site-single-name': 'Site License',
    'form.label.select-owner': 'Select Owner',
    'form.label.select-owner-single-name': 'Owner',
    'form.label.admin': 'Admin',
    'form.label.educator': 'Educator',
    'form.label.team-user': 'Educator',
    'form.label.team_participants': 'Educator(s)',
    'form.label.member': 'Member',
    'form.label.lessons-email-list': 'Add to lessons email list',
    'form.label.csv_file': 'CSV File',
    'form.label.allow_duplicates': `Allow duplicates -  if student is present in multiple CSV rows
        process all the rows and as a result allow student to be updated for each duplicate row`,
    'form.label.never_detach_other_providers': `If educator_username is provided
        do not remove a student from the rosters of other educators`,
    'form.label.archive_not_affected': `Archive all students of the school chosen above
        who are not affected by bulk upload`,
    'form.label.archive_not_affected_exclude_from': `Do not archive students created from`,
    'form.label.file': `Drag 'n' drop a file here, or click to select file`,
    'form.label.csv_bulk_upload_file': 'Bulk Upload File (CSV)',
    'form.label.image_lesson_file': 'Cover File (Image)',
    'form.label.pdf_lesson_educator_file': 'Lesson File (PDF)',
    'form.label.pdf_lesson_student_file': 'Student Materials File (PDF)',
    'form.label.pool_status': 'Turn on the Pool',
    'form.label.no_free_trial_on_activation': 'Do not create Free Trial on account activation',
    'form.label.free_trial_ends_at': 'Free Trial Expiration Date',
    'form.label.quote_ends_at': 'Quote Expiration Date',
    'form.label.individual': 'Individual Licenses Quantity',
    'form.label.colleagues-first-name': `Colleague's First Name`,
    'form.label.colleagues-last-name': `Colleague's Last Name`,
    'form.label.colleagues-email': `Colleague's School Email Address`,
    'form.label.school-email': 'School Email Address',
    'form.label.my_role': 'My Role',
    'form.label.my_role_description': 'My role description',
    'form.label.programs_evaluation_goal_option': 'Your goal for evaluating Centervention\'s SEL programs?',
    'form.label.programs_evaluation_goal_option.short': 'Programs Evaluation Goal',
    'form.label.invited_by': 'Invitor',
    'form.label.adult': 'I am ...',
    'form.label.reclaim_from': 'Reclaim From',
    'form.label.transfer_from_id': 'Transfer From',
    'form.label.transfer-from-school': 'Transfer From School',
    'form.label.transfer-from-educator': 'Transfer From Educator',
    'form.label.select-transfer-to': 'Transfer To',
    'form.label.transfer_licenses': 'Number of Licenses to Transfer',
    'form.label.reclaim_licenses': 'Number of Licenses to Reclaim',
    'form.label.quantity': 'Quantity',
    'form.label.purchase-licenses-quantity': 'Enter Number of Individual Student Licenses',
    'form.label.purchase-children-quantity': 'Enter Number of Students',
    'form.label.purchase-price': 'Price per License',
    'form.label.purchase-total': 'Total',
    'form.label.purchase-cost': 'Cost',
    'form.label.purchase-licenses-select-quantity': 'Number of Individual Student Licenses',
    'form.label.new-parent-quantity': 'How Many Student Accounts?',
    'form.label.new-parent-email': 'Enter Your Email Address',
    'form.label.transfer-to-school': 'Transfer To School',
    'form.label.transfer-to-educator': 'Transfer To Educator',
    'form.label.transfer-to-another-educator': 'Select Educator',
    'form.label.share-with-educator': 'Share With Educator',
    'form.label.activate_license': 'Activate License',
    'form.label.assign_program': 'Assign Program',
    'form.label.reset-password': `Update Student's Password To Match Updated Username`,
    'form.label.class_code': 'Class Code',
    'form.label.purchase_date': 'Purchase Date',
    'form.label.purchase_number': 'Purchase Order Number',
    'form.label.price-per-license': `Price Per License`,
    'form.label.is_historical': `Historical Purchase (do not add licenses to educator's account)`,
    'form.label.is_existing_user': `Existing user`,
    'form.label.starts-at': `Starts At`,
    'form.label.ends-at': `Ends At`,
    'form.label.pawn_id': ` `,
    'form.label.i_agree_to_one_student': 'I agree to try Centervention with at least one student',

    'form.label.connect': 'Connect',
    'form.label.connect-another-account': 'Connect to another account',
    'form.label.connect-new-username': 'Change Username',
    'form.label.modify-free-trial': 'Manage Free Trial ({date})',
    'form.label.modify-individual': 'Manage Individual Licenses ({remaining})',
    'form.label.add-another': 'Add another after submitting',
    'form.label.activate-plan': 'Select Activation Method',
    'form.label.activate-program': 'Select Program to Activate',
    'form.label.switch-program': 'Select Program to Switch to',
    'form.label.renew-program': 'Select Program to Renew',
    'form.label.assign-program-individual': 'Select Program to Assign',
    'form.label.assign-program-free-trial': 'Assign Program with Free Trial',
    'form.label.assign-program-recommended': 'Recommended',
    'form.label.assign-program-other-choices': 'Other Choices',
    'form.label.assign-program-later': 'Skip and assign program later',
    'form.label.assign-program-parent': 'Select Program',
    'form.label.assign-program-rollback': 'Select Program to Rollback',
    'form.label.student_username': 'Student Username',
    'form.label.search-users-bar': 'Search Users',
    'form.label.team-users.educators': `List of Educators ({count})`,
    'form.label.team-users.admins': 'Selected Admins ({count})',
    'form.label.team-users.members': 'Selected Members ({count})',
    'form.label.search': 'Search',
    'form.label.calculate-default-license-price': 'Use Default Price',
    'form.label.primary-quote': 'Primary Quote',
    'form.label.sub-quote': 'Sub Quote',
    'form.label.current-school-year': 'Current School Year',
    'form.label.next-school-year': 'Next School Year',
    'form.label.site-license': 'Site License',
    'form.label.is_site_license': 'Create Site License Quote',
    'form.label.is_partial_year_license': 'Use partial year license for current school year',
    'form.label.is-partial-year-license-upgrade': 'Use partial year license',
    'form.label.tier': 'Tier',
    'form.label.license-expiration': 'License Expiration',
    'form.label.current_or_next_school_year': 'Current or Next School Year',
    'form.label.interested_in': 'I\'m interested in',
    'form.label.mail_broadcast': 'Receive Centervention updates via email',
    'form.label.mail_transactional': ' ',
    'form.label.lesson': tabulator['tabulator.thead.lesson'],
    'form.label.duration': tabulator['tabulator.thead.duration'],
    'form.label.duration-min': tabulator['tabulator.thead.duration'] + ' (min)',
    'form.label.sel-skills': tabulator['tabulator.thead.skills'],
    'form.label.grade_levels': tabulator['tabulator.thead.grade_levels'],
    'form.label.overview': tabulator['tabulator.thead.overview'],
    'form.label.materials': 'Materials',
    'form.label.student_materials_anchor': 'Student Materials Anchor Text',
    'form.label.resource_type': 'Resource Type',
    'form.label.usage': 'Usage',
    'form.label.prep_levels': 'Prep Level',
    'form.label.challenges': 'Challenges',

    'form.label.switch.option.high_priority': 'Is High Priority',
    'form.label.switch.option.elementary': tabulator['tabulator.formatters.elementary'],
    'form.label.switch.option.middle': tabulator['tabulator.formatters.middle'],
    'form.label.switch.option.ic': tabulator['tabulator.thead.group-data-ic'],
    'form.label.switch.option.com': tabulator['tabulator.thead.group-data-com'],
    'form.label.switch.option.coop': tabulator['tabulator.thead.group-data-coop'],
    'form.label.switch.option.ini': tabulator['tabulator.thead.group-data-ini'],
    'form.label.switch.option.emp': tabulator['tabulator.thead.group-data-emp'],
    'form.label.switch.option.er': tabulator['tabulator.thead.group-data-er'],
    'form.label.switch.option.group': 'Group',
    'form.label.switch.option.individual': 'Individual',
    'form.label.switch.option.none': 'None',
    'form.label.switch.option.modest': 'Modest',
    'form.label.switch.option.moderate': 'Moderate',
    'form.label.switch.option.anger': 'Anger',
    'form.label.switch.option.anxiety-and-stress': 'Anxiety and Stress',
    'form.label.switch.option.causing-disruptions': 'Causing Disruptions',
    'form.label.switch.option.calming-down': 'Calming Down',
    'form.label.switch.option.conflict-resolution': 'Conflict Resolution',
    'form.label.switch.option.coping-with-change': 'Coping with Change',
    'form.label.switch.option.daily-transitions': 'Daily Transitions',
    'form.label.switch.option.dealing-with-disappointment': 'Dealing with Disappointment',
    'form.label.switch.option.growth-mindset': 'Growth Mindset',
    'form.label.switch.option.feeling-left-out': 'Feeling Left Out',
    'form.label.switch.option.friendship': 'Friendship',
    'form.label.switch.option.listening-skills': 'Listening Skills',
    'form.label.switch.option.peer-pressure': 'Peer Pressure',
    'form.label.switch.option.perfectionism': 'Perfectionism',
    'form.label.switch.option.sadness-and-grief': 'Sadness and Grief',
    'form.label.switch.option.self-esteem-and-self-confidence': 'Self-Esteem and Self-Confidence',
    'form.label.switch.option.staying-focused': 'Staying Focused',

    'form.autocomplete.no-options.not-found': tabulator['tabulator.empty-rows'],
    'form.autocomplete.no-options.select-district': 'Type name, prefix or id to select district',
    'form.autocomplete.no-options.select-account': tabulator['tabulator.empty-rows'],
    'form.autocomplete.no-options.select-educator': 'Type first name, last name, username or id to select educator',
    'form.autocomplete.no-options.share-with-educator': 'Type first name, last name, username or id to select educator',
    'form.autocomplete.no-options.transfer-to-educator': 'Type first name, last name, username or id to select educator',
    'form.autocomplete.no-options.transfer-to-another-educator': 'Type first name, last name or username to select educator',
    'form.autocomplete.no-options.select-school': tabulator['tabulator.empty-rows'],
    'form.autocomplete.no-options.select-transfer-to': tabulator['tabulator.empty-rows'],
    'form.autocomplete.no-options.transfer-to-school': tabulator['tabulator.empty-rows'],
    'form.autocomplete.no-options.select-team': 'Type group name, district name, school name or group id to select group',
    'form.autocomplete.no-options.select-site': 'Type group name, district name, school name or group id to select Site License',
    'form.autocomplete.no-options.select-owner': 'Type first name, last name, username or id to select owner',
    'form.autocomplete.no-options.search-users-bar': 'Type first name, last name, username or id',
    'form.autocomplete.status.active': 'active',
    'form.autocomplete.status.archived': 'archived',

    'form.helper-text.connect-new-username': `Your new username must be valid email address.
        We will send confirmation link to your new email.
        Open it and follow instructions to complete username change.`,
    'form.helper-text.individual': 'Provide a positive quantity to add licenses, a negative quantity to subtract licenses',
    'form.helper-text.bulk-upload': 'If you have more than 20 student accounts to add, please contact us to create these accounts with a bulk upload',
    'form.helper-text.student_id': 'Please provide the school or district Student ID to prevent creating duplicate student accounts',

    'form.student-bulk-upload.created': 'Created',
    'form.student-bulk-upload.updated': 'Updated',
    'form.student-bulk-upload.failed': 'Failed',
    'form.student-bulk-upload.out-of-licenses': 'Out Of Licenses',
    'form.student-bulk-upload.download.result': 'Download as CSV',
    'form.student-bulk-upload.download.template': 'Download the CSV-file template',
    'form.student-bulk-upload.download.file-name': 'template',
    'form.student-bulk-upload.download.created.file-name': 'created',
    'form.student-bulk-upload.download.updated.file-name': 'updated',
    'form.student-bulk-upload.download.failed.file-name': 'failed',
    'form.student-bulk-upload.download.out-of-licenses.file-name': 'out-of-licenses',
    'form.student-bulk-upload.description-top': `CSV-file must have the first line with column names.
        The rest of the lines should be the data rows.
        This is the list of columns (the order is not important, optional can be skipped):`,
    'form.student-bulk-upload.ul.1': 'first_name - required',
    'form.student-bulk-upload.ul.2': 'last_name - required',
    'form.student-bulk-upload.ul.3': `grade - required / can change on update -
        enter -1 or P for Pre-Kindergarten, 0 or K for Kindergarten, or a grade from 1 to 12`,
    'form.student-bulk-upload.ul.4': 'teacher - optional / can change on update',
    'form.student-bulk-upload.ul.5': 'student_id - optional',
    'form.student-bulk-upload.ul.6': `educator_username - optional`,
    'form.student-bulk-upload.ul.6.1': `educator provided in this column must be connected to the school chosen below`,
    'form.student-bulk-upload.ul.6.2': `on student create: if an educator's username is provided
        it adds the student to the specified educator's roster instead of the educator chosen below.
        This allows adding students to different educators in a single upload`,
    'form.student-bulk-upload.ul.6.3': `on student update: if student already exists and is updated by bulk upload
        a student is removed from all other rosters and is added only to the educator_username's roster.
        That behavior can be changed below`,
    'form.student-bulk-upload.ul.7': `student_username - optional`,
    'form.student-bulk-upload.ul.7.1': `if is not provided username will be generated automatically`,
    'form.student-bulk-upload.ul.8': `activate_license - optional - enter Y to activate/extend license,
        anything else to skip license activation.
        Note: if activation is skipped or license is not available free trial will be used`,
    'form.student-bulk-upload.ul.8.1': `license of educator_username will be used,
        if not available license of educator chosen below will be used,
        if not available student will be created/updated without license activation`,
    'form.student-bulk-upload.ul.8.2': `if student has an existing license
        that expires in less than 90 days license will be extended`,
    'form.student-bulk-upload.ul.8.3': `if assign_program is not provided
        default program will be assigned along with license activation`,
    'form.student-bulk-upload.ul.9': `assign_program - optional - enter one of academy, zoou, ssgrin, hoh, sim
        to assign/switch program`,
    'form.student-bulk-upload.ul.10': `clever_user_id - optional`,
    'form.student-bulk-upload.ul.11': `classlink_login_id - optional, required if classlink_tenant_id is provided`,
    'form.student-bulk-upload.ul.12': `classlink_tenant_id - optional, required if classlink_login_id is provided`,
    'form.student-bulk-upload.description-bottom': `Student will be updated instead of created if
        (at least one must be met):`,
    'form.student-bulk-upload.ol.1': `student with existing student_id is found within school chosen below`,
    'form.student-bulk-upload.ol.2': `student_id is not provided and
        student with existing student_username is found within school chosen below`,
    'form.student-bulk-upload.ol.3': `student_id is not provided and 
        student with existing first_name, last_name, and grade (lower or equal than provided)
        is found within school chosen below.`,
    'form.request-quote.general-question': `Will you be using Centervention programs with more than 40 students?`,
    'form.request-quote.general-answer-no': `Up To 40 Students`,
    'form.request-quote.general-answer-yes': `More Than 40 Students`,
    'form.request-quote.partial-year-license': `Prorated amount for partial year license`,
    'form.transfer-students.share': `Share Access {br} within the Same School`,
    'form.transfer-students.same-school': `Transfer {br} within the Same School`,
    'form.transfer-students.another-school': `Transfer {br} to Another School`,
    'form.transfer-to-another-educator.transfer': `Transfer {br} to Another Educator`,
    'form.transfer-to-another-educator.share': `Share Access {br} with Another Educator`,
    'form.account-rollback-deactivate.0': `Check list after successful rollback`,
    'form.account-rollback-deactivate.1': `if account was awaiting activation resend activation link`,
    'form.account-rollback-deactivate.2': `if account was using free trial adjust free trial expiration date`,
    'form.account-rollback-deactivate.3': `if account was deactivated by Admin transfer back user's licenses`,

    'form.account-request.header': `Your Free Centervention Account Includes`,
    'form.account-request.li.1': `Free access, for 30 days,
        to Centervention’s evidence-based online SEL interventions for your entire roster of students!`,
    'form.account-request.li.2': `After 30 days, 
        all students can continue to log in and complete daily emotion check ins and access our digital Cool Down Corner.
        And you have an option to purchase licenses for the students that need to continue using the interventions.`,
    'form.account-request.li.3': `From your educator account,
        you can continue to track progress and daily emotion check-ins from your educator dashboard.`,
    'form.account-request.li.4': `During the school year, you will receive a weekly email with supplemental SEL lessons.`,

    'form.validation.username-not-found': 'A user was not found with the given credentials',
    'form.validation.license-not-found': `We're sorry you don't have valid license. Ask your teacher or parent for help`,
    'form.validation.username-student-login-on-program-subdomain': 'User not valid. Students do not login here, they login at the game website',
    'form.validation.educator-exists': 'User is not an educator',
    'form.validation.password-incorrect': 'The password is incorrect',
    'form.validation.required': 'The {field} is required',
    'form.validation.required_if': 'The {field} is required',
    'form.validation.required_with': 'The {field} is required',
    'form.validation.required-assign-program': 'The student needs an assigned program to play the game (you can also skip program selection and assign it later)',
    'form.validation.unique': 'The {field} has already been taken',
    'form.validation.dates-overlap': 'Dates have already been taken',
    'form.validation.email-registered': 'Email is already registered',
    'form.validation.exists': 'The selected {field} is invalid',
    'form.validation.exists-connected-to-educator': 'The selected {field} is not connected to Educator',
    'form.validation.in': 'The selected {field} is invalid',
    'form.validation.different': 'The selected {field} is invalid',
    'form.validation.string': 'The {field} must be a text',
    'form.validation.integer': 'The {field} must be a number',
    'form.validation.numeric': 'The {field} must be a number',
    'form.validation.boolean': 'The {field} must be true or false',
    'form.validation.date': 'The {field} is not a valid date',
    'form.validation.unique-in-state': 'The {field} in given state has already been taken',
    'form.validation.unique-in-district': 'The {field} in given district has already been taken',
    'form.validation.unique-for-update-in-school': 'The {field} in given school is not unique, update failed',
    'form.validation.unique-flg-for-update-in-school': 'The First Name, Last Name, and Grade in given school are not unique, update failed',
    'form.validation.unique-uploaded-file': 'File has already been uploaded',
    'form.validation.csv-row-duplicated': 'The row is duplicated - the student has already been processed in this bulk upload',
    'form.validation.email': 'The {field} must be a valid email address',
    'form.validation.email.list.validation': 'The {field} was rejected by email list service',
    'form.validation.email.list.failed': 'Email list service failed, try again',
    'form.validation.file': 'The {field} type is invalid',
    'form.validation.mimes': 'The {field} type is invalid',
    'form.validation.image': 'The {field} type is invalid',
    'form.validation.file-empty': 'File is empty',
    'form.validation.uploaded': 'File failed to upload',
    'form.validation.non-zero': 'The {field} is not a non-zero value',
    'form.validation.greater-than-zero': 'The {field} must be greater than zero',
    'form.validation.max-cc-purchase': 'Contact us for 100+ licenses',
    'form.validation.max-40': 'Maximum Quantity is 40',
    'form.validation.max-50': 'Maximum Quantity is 50',
    'form.validation.max-1000': 'Maximum Quantity is 1000',
    'form.validation.max-50000': 'Maximum Quantity is 50000',
    'form.validation.licenses-positive-number': 'Licenses Remaining can not be below zero (current Licenses Remaining decreased by your Change)',
    'form.validation.mimetypes': 'The {field} type is invalid',
    'form.validation.csv-columns-format': 'The {field} format is invalid, make sure all required columns are present and no unknown columns are present',
    'form.validation.current-password-incorrect': 'The {field} is incorrect',
    'form.validation.new-password-confirmed': 'The {field} and confirmation do not match',
    'form.validation.new-username-confirmed': 'The {field} and confirmation do not match',
    'form.validation.new-password-different': 'The {field} and Current Password must be different',
    'form.validation.max-string-500': 'The {field} may not be greater than 500 characters',
    'form.validation.min-3': 'The {field} must be at least 3 characters',
    'form.validation.min-6': 'The {field} must be at least 6 characters',
    'form.validation.reset-password-user-not-found': 'No user found with that username. Contact us for assistance',
    'form.validation.reset-password-user-is-student': `This is a student account, please have your teacher log in to change your password for you.
        If you are an educator and you need assistance with this account, please contact us`,
    'form.validation.owner-is-admin-or-member': 'Owner can not be Admin or Member at the same time',
    'form.validation.student-username-format': 'The {field} format is invalid: characters allowed are letters (a-z), numbers (0-9), a dot (.), and a dash (-)',
    'form.validation.missing-licenses-to-transfer': 'There is not enough licenses to transfer',
    'form.validation.missing-valid-purchase': `Valid license for student not found`,
    'form.validation.new-parent-is-already-educator': `You have an existing school account associated with email address provided.
        Would you like to purchase a Family Subscription using a different email address?`,
};

export default form;
