import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { endpoints } from '../../../api';
import LinkExternal from '../../components/common/LinkExternal';

const PublicFooterLinks: FunctionComponent = () => (
    <>
        <FormattedMessage id="footer.copyright" values={{ year: moment().year() }}/>
        <span>|</span>
        <LinkExternal to={endpoints.external.marketingWebsiteDomain}>
            <FormattedMessage id="footer.marketing-website-name"/>
        </LinkExternal>
        <Link to="/terms">
            <FormattedMessage id="footer.terms"/>
        </Link>
        <Link to="/privacy">
            <FormattedMessage id="footer.privacy"/>
        </Link>
        <LinkExternal to="https://www.centervention.com/sel-interventions/">
            SEL Interventions
        </LinkExternal>
    </>
);

export default PublicFooterLinks;
