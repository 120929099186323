const resolvePusher = (isSandbox: boolean, env: string): string => {
    const production = process.env.REACT_APP_PRODUCTION_PUSHER_APP_KEY || '';
    const sandbox = process.env.REACT_APP_SANDBOX_PUSHER_APP_KEY || '';

    if (env === 'test') {
        return '';
    }

    if (isSandbox) {
        return sandbox;
    }

    return production;
};

export default resolvePusher;
