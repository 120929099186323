import React, { FunctionComponent } from 'react';
import { FieldProps } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Field, FieldRenderProps } from 'react-final-form';
import styles from './_form.module.sass';

const InputHidden: FunctionComponent<FieldProps<any, FieldRenderProps<any>>> = (props) => (
    <Field
        type="hidden"
        component={TextField}
        className={styles.fieldHidden}
        {...props}
    />
);

export default InputHidden;
