import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { endpoints } from '../../../api';
import LinkExternal from '../../components/common/LinkExternal';
import styles from './_common.module.sass';

const PublicLightFooter: FunctionComponent = () => (
    <section className={styles.footerLight}>
        <div>
            <LinkExternal to="/terms">
                <FormattedMessage id="footer.terms"/>
            </LinkExternal>
            <span className={styles.pipe}>|</span>
            <LinkExternal to="/privacy">
                <FormattedMessage id="footer.privacy"/>
            </LinkExternal>
            <span className={styles.pipe}>|</span>
            <FormattedMessage id="app.do-you-need-help"/>
            {' '}
            <LinkExternal to={endpoints.external.support}>
                <FormattedMessage id="app.contact-us-for-assistance"/>
            </LinkExternal>
        </div>
    </section>
);

export default PublicLightFooter;
