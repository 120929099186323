import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps } from 'react-redux';
import { authSelectors, RootState } from '../../../state';
import styles from './_common.module.sass';

type Props = {
    i18n: string,
    to?: string,
    color?: 'default' | 'primary',
}

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
}));

const LinkButton: FunctionComponent<Props & ConnectedProps<typeof connector>> = ({
    i18n,
    homePath,
    to,
    color = 'default',
}) => (
    <Link to={to || homePath} className={styles.link}>
        <Button
            variant="contained"
            color={color}
            size="medium"
        >
            <FormattedMessage id={`button.${i18n}`}/>
        </Button>
    </Link>
);

export default connector(LinkButton);
