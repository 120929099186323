import {
    Team,
    School,
    Parent,
    Lesson,
    Student,
    ApiItem,
    District,
    Educator,
    SelCheckIn,
    SiteLicense,
    MetricsAdmin,
    MetricsEducator,
    ActivityHistory,
    TabulatorSegment,
    EcommerceHistory,
    PurchaseOverview,
    StudentBulkUpload,
    AccountSourceUber,
    AccountSourceForm,
    MetricsOutOfLicense,
    AssignProgramHistory,
    StudentBulkUploadRow,
    AccountSourceReferral,
    TransferLicensesHistory,
    MetricsGiftsInviteDetails,
    MetricsGiftsInviteAggregated,
} from './types';

const mapDistrict = (district: ApiItem | any): District => {
    return district;
};

const mapSchool = (school: ApiItem | any): School => {
    return school;
};

const mapTeam = (team: ApiItem | any): Team => {
    return {
        ...team,
        pool_status_individual_remaining: team.pool_status,
        pool_status_site_initial: team.pool_status,
        pool_status_site_remaining: team.pool_status,
        pool_status_individual_remaining_count: team.individual_quantity - team.individual_used > 0
            ? team.individual_quantity - team.individual_used
            : null,
        pool_status_site_initial_count: team.site_quantity,
        pool_status_site_remaining_count: team.site_quantity !== null ? team.site_quantity - team.site_used : null,
    };
};

const mapEducator = (educator: ApiItem | any): Educator => {
    return { ...educator, individual_remaining: educator.individual_quantity - educator.individual_used };
};

const mapParent = (parent: ApiItem | any): Parent => {
    return { ...parent, individual_remaining: parent.individual_quantity - parent.individual_used };
};

const mapTransferLicensesHistory = (transfer: ApiItem | any): TransferLicensesHistory => {
    return {
        ...transfer,
        from_name: `${transfer.from_first_name} ${transfer.from_last_name}`,
        to_name: `${transfer.to_first_name} ${transfer.to_last_name}`,
        transferred_at: transfer.created_at,
    };
};

const mapAccountSourceForm = (accountSourceForm: ApiItem | any): AccountSourceForm => {
    return accountSourceForm;
};

const mapAccountSourceReferral = (accountSourceReferral: ApiItem | any): AccountSourceReferral => {
    return accountSourceReferral;
};

const mapAccountSourceUber = (accountSourceUber: ApiItem | any): AccountSourceUber => {
    return accountSourceUber;
};

const mapPurchaseOverview = (purchase: ApiItem | any): PurchaseOverview => {
    return { ...purchase, individual_remaining: purchase.individual_quantity - purchase.individual_used };
};

const mapActivityHistory = (activityHistory: ApiItem | any): ActivityHistory => {
    return {
        ...activityHistory,
        issuer_user_and_issuer_uber: activityHistory.issuer_uber
            ? `${activityHistory.issuer_user} / ${activityHistory.issuer_uber}`
            : activityHistory.issuer_user,
    };
};

const mapStudent = (student: ApiItem | any): Student => {
    return { ...student, full_name: `${student.last_name} ${student.first_name}` };
};

const mapSelCheckIn = (selCheckIn: ApiItem | any): SelCheckIn => {
    return { ...selCheckIn, full_name: `${selCheckIn.last_name} ${selCheckIn.first_name}` };
};

const mapStudentBulkUpload = (studentBulkUpload: ApiItem | any): StudentBulkUpload => {
    return studentBulkUpload;
};

const mapStudentBulkUploadRow = (studentBulkUploadRow: ApiItem | any): StudentBulkUploadRow => {
    return studentBulkUploadRow;
};

const mapAssignProgramHistory = (assignProgramHistory: ApiItem | any): AssignProgramHistory => {
    return {
        ...assignProgramHistory,
        assign_issuer_and_assign_uber: assignProgramHistory.assign_uber
            ? `${assignProgramHistory.assign_issuer} / ${assignProgramHistory.assign_uber}`
            : assignProgramHistory.assign_issuer,
        rollback_issuer_and_rollback_uber: assignProgramHistory.rollback_uber
            ? `${assignProgramHistory.rollback_issuer} / ${assignProgramHistory.rollback_uber}`
            : assignProgramHistory.rollback_issuer,
    };
};

const mapEcommerceHistory = (ecommerceHistory: ApiItem | any): EcommerceHistory => {
    return {
        ...ecommerceHistory,
        issuer_user_and_issuer_uber: ecommerceHistory.issuer_uber
            ? `${ecommerceHistory.issuer_user} / ${ecommerceHistory.issuer_uber}`
            : ecommerceHistory.issuer_user,
    };
};

const mapSiteLicense = (siteLicense: ApiItem | any): SiteLicense => {
    return { ...siteLicense, remaining: siteLicense.quantity - siteLicense.used };
};

const mapMetricsEducator = (metricsEducator: ApiItem | any): MetricsEducator => {
    return metricsEducator;
};

const mapMetricsAdmin = (metricsAdmin: ApiItem | any): MetricsAdmin => {
    return { ...metricsAdmin, acting_as_id: metricsAdmin.user_id };
};

const mapMetricsOutOfLicense = (metricsOutOfLicense: ApiItem | any): MetricsOutOfLicense => {
    return {
        ...metricsOutOfLicense,
        individual_remaining: metricsOutOfLicense.individual_quantity - metricsOutOfLicense.individual_used,
    };
};

const mapMetricsGiftsInviteDetails = (metricsGiftsInviteDetails: ApiItem | any): MetricsGiftsInviteDetails => {
    return { ...metricsGiftsInviteDetails, username: metricsGiftsInviteDetails.invitee };
};

const mapMetricsGiftsInviteAggregated = (metricsGiftsInviteAggregated: ApiItem | any): MetricsGiftsInviteAggregated => {
    return { ...metricsGiftsInviteAggregated, username: metricsGiftsInviteAggregated.invitor };
};

const mapLesson = (lesson: ApiItem | any): Lesson => {
    return lesson;
};

const mapTemp = (temp: ApiItem | any): ApiItem => {
    return temp;
};

export default {
    [TabulatorSegment.AccountSourcesForm]: mapAccountSourceForm,
    [TabulatorSegment.AccountSourcesReferral]: mapAccountSourceReferral,
    [TabulatorSegment.AccountSourcesUber]: mapAccountSourceUber,
    [TabulatorSegment.Districts]: mapDistrict,
    [TabulatorSegment.Schools]: mapSchool,
    [TabulatorSegment.Teams]: mapTeam,
    [TabulatorSegment.Educators]: mapEducator,
    [TabulatorSegment.Parents]: mapParent,
    [TabulatorSegment.TransferLicensesHistory]: mapTransferLicensesHistory,
    [TabulatorSegment.PurchaseOverview]: mapPurchaseOverview,
    [TabulatorSegment.PurchaseHistory]: mapEcommerceHistory,
    [TabulatorSegment.ActivityHistory]: mapActivityHistory,
    [TabulatorSegment.Students]: mapStudent,
    [TabulatorSegment.SelCheckIns]: mapSelCheckIn,
    [TabulatorSegment.StudentsBulkUpload]: mapStudentBulkUpload,
    [TabulatorSegment.StudentsBulkUploadRow]: mapStudentBulkUploadRow,
    [TabulatorSegment.AssignProgramHistory]: mapAssignProgramHistory,
    [TabulatorSegment.EcommerceHistory]: mapEcommerceHistory,
    [TabulatorSegment.SiteLicense]: mapSiteLicense,
    [TabulatorSegment.MetricsEducator]: mapMetricsEducator,
    [TabulatorSegment.MetricsAdmin]: mapMetricsAdmin,
    [TabulatorSegment.MetricsOutOfLicense]: mapMetricsOutOfLicense,
    [TabulatorSegment.MetricsGiftsInviteDetails]: mapMetricsGiftsInviteDetails,
    [TabulatorSegment.MetricsGiftsInviteAggregated]: mapMetricsGiftsInviteAggregated,
    [TabulatorSegment.Lessons]: mapLesson,
    [TabulatorSegment.Temp]: mapTemp,
};
