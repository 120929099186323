import React from 'react';
import Blank from '../layout/Blank';
import PageLoader from './PageLoader';

const RouterLazyLoader = () => (
    <Blank>
        <PageLoader/>
    </Blank>
);

export default RouterLazyLoader;
