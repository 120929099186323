import { store } from '../../../state';
import { TabulatorName } from '../../../state/ducks/tabulator';
import { ModalId, ModalSettings, ModalType, TabulatorRowCopy } from '../../../state/ducks/modals';

const copyTabulatorRow = (tabulator: TabulatorName | undefined, id: ModalId): TabulatorRowCopy => {
    if (! tabulator || ! id) {
        return undefined;
    }

    const state = store.getState();

    if (! state.tabulator.hasOwnProperty(tabulator)) {
        return undefined;
    }

    if (! state.tabulator[tabulator].byId.hasOwnProperty(id)) {
        return undefined;
    }

    return {
        name: tabulator,
        segment: state.tabulator[tabulator].segment,
        row: { ...state.tabulator[tabulator].byId[id] },
    }
}

const createModalSettings = (
    type: ModalType,
    id: ModalId = undefined,
    tabulator: TabulatorName | undefined = undefined,
    tabulatorRowCopy: TabulatorRowCopy | undefined = undefined,
): ModalSettings => {
    return {
        name: id ? `${type}-${id}` : type,
        type,
        id,
        tabulator,
        tabulatorRowCopy: tabulatorRowCopy || copyTabulatorRow(tabulator, id),
    };
};

export default createModalSettings;
