import actions from './actions';
import config from '../../../config';
import { AppThunkAction } from '../../types';
import { browserStorage } from '../../utils';
import { apiActions, endpoints } from '../../../api';
import {
    SidebarId,
    SidebarData,
    SidebarType,
    SidebarTaskData,
    SidebarDeployedAppData,
    SidebarAccountRequestsPendingVerificationData,
} from './types';

const subscribeUber = (
    type: SidebarType,
    id: SidebarId,
    data: SidebarData,
    isFromApiResponse: boolean = false,
): AppThunkAction => (dispatch) => {
    if (
        type === SidebarType.DeployedApp
        && (data as SidebarDeployedAppData).version !== config.version
        && ! config.isSandbox
    ) {
        dispatch(deployedApp(type, id, data as SidebarDeployedAppData));
        return;
    }

    if (type === SidebarType.AccountRequestsPendingVerification) {
        dispatch(accountRequestsPendingVerification(type, data as SidebarAccountRequestsPendingVerificationData));
        return;
    }

    if (
        type === SidebarType.TaskStudentCloneIntoSandbox
        || type === SidebarType.TaskStudentBulkUpload
    ) {
        dispatch(task(type, id, data as SidebarTaskData, isFromApiResponse));
        return;
    }
};

const deployedApp = (type: SidebarType, id: SidebarId, data: SidebarDeployedAppData) => {
    return actions.sidebarAdd(type, id, data);
};

const accountRequestsPendingVerification = (type: SidebarType, data: SidebarAccountRequestsPendingVerificationData) => {
    return actions.sidebarSingleton(type, undefined, data);
};

const task = (type: SidebarType, id: SidebarId, data: SidebarTaskData, isFromApiResponse: boolean) => {
    return actions.sidebarTask(type, id, data, isFromApiResponse);
};

const sidebarSeen = (id: SidebarId): AppThunkAction => (dispatch) => {
    dispatch(actions.sidebarSeen(id));
    browserStorage.setNotificationsSidebarSeen(id);
};

const sidebarRemove = (id: SidebarId): AppThunkAction => (dispatch) => {
    dispatch(actions.sidebarRemove(id));
    browserStorage.setNotificationsSidebarRemove(id);
};

const dashboardFetchPage = (): AppThunkAction => async (dispatch, getState) => {
    if (getState().notifications.dashboard.loading) {
        return;
    }

    try {
        dispatch(actions.dashboardLoadingBegin());
        const response = await apiActions.get(endpoints.educator.dashboard.notifications);
        dispatch(actions.dashboardFetchPage(response.data.data, response.data.total));
        dispatch(actions.dashboardLoadingCommit());
    } catch (e) {
        dispatch(actions.dashboardLoadingCommit());
    }
};

export default {
    subscribeUber,
    sidebarSeen,
    sidebarRemove,
    sidebarTask: actions.sidebarTask,
    signIn: actions.signIn,
    signOut: actions.signOut,
    actingAs: actions.actingAs,
    dashboardFetchPage,
    dashboardDismiss: actions.dashboardDismiss,
};
