import React from 'react';
import { FormattedMessage } from 'react-intl';
import snackbars from '../../../views/components/snackbars/utils';

const limitNotification = (limit: number) => {
    const values = { limit, br: <br/> };

    snackbars.warning(<FormattedMessage id="notification.select.limit.exceeded" values={values} />);
};

export default {
    limitNotification,
};
