const resolveSubdomain = (): { subdomain: string, isStudentSubdomain: boolean } => {
    const subdomain = resolve();

    title(subdomain.isStudentSubdomain);

    return subdomain;
};

const resolve = (): { subdomain: string, isStudentSubdomain: boolean } => {
    const subdomain = (window as any).subdomain;
    const hostname = window.location.hostname.split('.');
    const app = process.env.REACT_APP_SUBDOMAIN_APP as string;
    const student = process.env.REACT_APP_SUBDOMAIN_STUDENT as string;

    if (subdomain === student || hostname[0] === student) {
        return {
            subdomain: student,
            isStudentSubdomain: true,
        };
    }

    return {
        subdomain: app,
        isStudentSubdomain: false,
    };
};

const title = (isStudentSubdomain: boolean): void => {
    const title = isStudentSubdomain
        ? process.env.REACT_APP_TITLE_STUDENT as string
        : process.env.REACT_APP_TITLE_APP as string;

    if (document.title !== title) {
        document.title = title;
    }
};

export default resolveSubdomain;
