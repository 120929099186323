import React, { FunctionComponent } from 'react';
import DialogContent from '@material-ui/core/DialogContent';

type Props = {
    classes?: string | undefined,
}

const Body: FunctionComponent<Props> = ({ children, classes = undefined }) => (
    <DialogContent key="body" dividers className={classes}>
        {children}
    </DialogContent>
);

export default Body;
