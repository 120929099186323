import { createReducer } from '../../utils';
import { nullAccessToken, nullUser } from './mappers';
import types, {
    AuthState,
    SignInAction,
    SignOutAction,
    ActingAsAction,
    HandshakeLiteAction,
    RefreshProfileAction,
    ReplaceAccessTokenAction,
} from './types';

const initialState: AuthState = {
    accessToken: nullAccessToken(),
    user: nullUser(),
    actingAs: undefined,
};

const signIn = (state: AuthState, action: SignInAction): AuthState => ({
    ...state,
    accessToken: action.payload.accessToken,
    user: action.payload.user,
    actingAs: undefined,
});

const handshakeLite = (state: AuthState, action: HandshakeLiteAction): AuthState => state;

const signOut = (state: AuthState, action: SignOutAction): AuthState => ({
    ...state,
    accessToken: action.payload.accessToken,
    user: action.payload.user,
    actingAs: undefined,
});

const actingAs = (state: AuthState, action: ActingAsAction): AuthState => (
    { ...state, actingAs: action.payload.actingAsUser }
);

const replaceAccessToken = (state: AuthState, action: ReplaceAccessTokenAction): AuthState => (
    { ...state, accessToken: action.payload.accessToken }
);

const refreshProfile = (state: AuthState, action: RefreshProfileAction): AuthState => (
    { ...state, user: { ...state.user, ...action.payload.profile } }
);

const reducers = createReducer(initialState, {
    [types.SIGN_IN]: signIn,
    [types.HANDSHAKE_LITE]: handshakeLite,
    [types.SIGN_OUT]: signOut,
    [types.ACTING_AS]: actingAs,
    [types.REPLACE_ACCESS_TOKEN]: replaceAccessToken,
    [types.REFRESH_PROFILE]: refreshProfile,
} as any);

export default reducers;
