import React from 'react';
import { FormattedMessage } from 'react-intl';
import snackbars from '../../../views/components/snackbars/utils';

const dashboard = (status: number) => {
    if (status === 422) return snackbars.error(
        <FormattedMessage id="notification.validation.failed"/>
    );

    if (status === 404) return snackbars.errorPersist(
        <FormattedMessage id="notification.http.404" values={{ br: <br /> }}/>
    );

    if (status === 403) return snackbars.errorPersist(
        <FormattedMessage id="notification.http.403" values={{ br: <br /> }}/>
    );

    if (status === 401) return snackbars.error401Persist(
        <FormattedMessage id="notification.http.401" values={{ br: <br /> }}/>
    );

    unknown4xx();
};

const actingAs = (status: number) => {
    if (status === 404) return snackbars.infoPersist(
        <FormattedMessage id="notification.acting.as.user.not.found" values={{ br: <br/> }}/>
    );

    if (status === 403) return snackbars.errorPersist(
        <FormattedMessage id="notification.http.403" values={{ br: <br /> }}/>
    );

    if (status === 401) return snackbars.error401Persist(
        <FormattedMessage id="notification.http.401" values={{ br: <br /> }}/>
    );

    unknown4xx();
};

const play = (status: number) => {
    if (status === 403) return snackbars.errorPersist(
        <FormattedMessage id="notification.play.http.403" values={{ br: <br /> }}/>
    );

    if (status === 401) return snackbars.error401Persist(
        <FormattedMessage id="notification.http.401" values={{ br: <br /> }}/>
    );

    unknown4xx();
};

const unknown4xx = () => {
    snackbars.errorPersist(
        <FormattedMessage id="notification.http.4xx" values={{ br: <br /> }}/>
    );
};

export default {
    play,
    actingAs,
    dashboard,
};
