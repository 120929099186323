import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormValues } from './types';
import Body from '../../../dialog/Body';
import InputText from '../../../form/InputText';
import { FormProps } from '../../../form/types';

const ResetMyPasswordForm: FunctionComponent<FormProps<FormValues>> = () => (
    <Body>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputText
                    name="username"
                    i18n="email"
                />
            </Grid>
        </Grid>
    </Body>
);

export default ResetMyPasswordForm;
