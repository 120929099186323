import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../state';
import { ModalContainerProps } from './types';
import ModalComponent from './ModalComponent';

type Props = {
    components: { [key in string]: React.ComponentType<ModalContainerProps> },
    canBeHidden: boolean,
}

const connector = connect((state: RootState) => ({
    modals: state.modals,
}));

class Modals extends React.PureComponent<Props & ConnectedProps<typeof connector>> {
    render() {
        const { modals, components, canBeHidden } = this.props;

        return Object.keys(modals).filter(name => {
            return components.hasOwnProperty(modals[name].type);
        }).map(name => {
            const modal = { ...modals[name], name };

            return <ModalComponent
                key={name}
                Component={components[modal.type]}
                modal={modal}
                canBeHidden={canBeHidden}
            />;
        });
    }
}

export default connector(Modals);
