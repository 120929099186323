import { PageResponder, PageResponderType } from './types';

const NOTIFICATION = 'notification';
const REDIRECT = 'redirect';
const FORM = 'form';

const pageResponder = <T extends PageResponderType>(responseData?: any): PageResponder<PageResponderType> => {
    if (responseData && responseData.type === NOTIFICATION) {
        return {
            received: true,
            isNotification: true,
            isRedirect: false,
            isForm: false,
            data: {
                type: responseData.data.notification,
                i18n: responseData.data.msg,
                values: responseData.data.extra,
            },
        };
    }

    if (responseData && responseData.type === REDIRECT) {
        return {
            received: true,
            isNotification: false,
            isRedirect: true,
            isForm: false,
            data: undefined,
        };
    }

    if (responseData && responseData.type === FORM) {
        return {
            received: true,
            isNotification: false,
            isRedirect: false,
            isForm: true,
            data: responseData.data,
        };
    }

    if (responseData) {
        return {
            received: true,
            isNotification: false,
            isRedirect: false,
            isForm: false,
            data: undefined,
        };
    }

    return {
        received: false,
        isNotification: false,
        isRedirect: false,
        isForm: false,
        data: undefined,
    };
};

export default pageResponder;
