import enForm from '../en/form';

const form = { ...enForm, ...{
    'form.label.username': '使用者名稱',
    'form.label.password': '密碼',

    'form.validation.username-not-found': '這個使用者帳號未經驗證。',
    'form.validation.license-not-found': `很抱歉，你的帳號失效了。請尋求老師或家長的協助`,
    'form.validation.password-incorrect': '密碼錯誤',
    'form.validation.required': '{field}是必填的。',
    'form.validation.string': '{field}必須是字元。',
}};

export default form;
