import modal from './modal';

const sidebar = {
    'sidebar.home': 'Home',
    'sidebar.uber': 'Uber',
    'sidebar.account-sources': 'Account Requests',
    'sidebar.schools': 'Schools',
    'sidebar.families': 'Families',
    'sidebar.metrics': 'Metrics',
    'sidebar.admin': 'Admin',
    'sidebar.students': 'Students',
    'sidebar.notifications': 'Notifications',
    'sidebar.modals': 'Modals',
    'sidebar.manage-educators': 'Educators',
    'sidebar.documentation': 'Getting Started',
    'sidebar.purchasing': 'Purchasing',
    'sidebar.manage-students': 'Manage Students',
    'sidebar.student-progress': 'Student Progress',
    'sidebar.sel-check-ins': 'Emotion Check Ins',
    'sidebar.reports': 'Reports',
    'sidebar.group-data': 'Group Data',
    'sidebar.lessons': 'Supplemental Resources',
    'sidebar.support': 'Support',
    'sidebar.purchase-licenses': 'Purchase Licenses',
    'sidebar.account-settings': 'Account Settings',
    'sidebar.update-profile': 'Profile',
    'sidebar.class-code-manage': 'Class Code',
    'sidebar.purchase-history': 'Purchase History',
    'sidebar.change-my-password': 'Password',
    'sidebar.new-label': 'New',
    'sidebar.task-failed': 'Task Failed',

    'sidebar.notification.deployed-app': `The new application version {version} has just been deployed.
        Refresh the page to pull it to your browser.
        Note: to avoid downtime the server is slowly switching to the new version
        and it may be available with a delay of up to five minutes`,

    'sidebar.notification.task-student-clone-into-sandbox.title': `Clone Student Into Sandbox`,
    'sidebar.notification.task-student-clone-into-sandbox.text': `
        From student: {from} {br}
        Sandbox username: {to}`,

    'sidebar.notification.task-student-bulk-upload.title': modal['modal.header.student-bulk-upload'],
};

export default sidebar;
