import { EcommercePartial, EcommerceState } from './types';

const mapEcommerce = (ecommerce: EcommercePartial): EcommerceState => {
    return {
        purchases: {
            freeTrial: ecommerce.purchases?.['free-trial'],
            individual: ecommerce.purchases?.individual,
            site: ecommerce.purchases?.site,
        },
        productUsage: ecommerce.product_usage || {},
    };
};

export {
    mapEcommerce,
};
