import { saveAs } from 'file-saver';
import { AsyncParser } from 'json2csv';
import { intl } from '../i18n';
import { ApiItem } from '../state/ducks/tabulator';
import { Column } from '../views/components/tabulator';

const downloadCsv = (
    name: string,
    data: any[],
    columns: Column[],
    mapper: (item: ApiItem) => ApiItem = item => item,
    callback?: () => void
) => {
    const columnsToCsv = columns.filter(column => {
        return ! column.hasOwnProperty('csv') || (column.hasOwnProperty('csv') && column.csv !== undefined);
    });

    const fields = columnsToCsv.map(column => ({
        label: intl.formatMessage({ id: `tabulator.thead.${column.i18n || column.field}` }),
        value: column.key || column.field,
    }));

    const parser = new AsyncParser({ fields }, { objectMode: true });

    let csv = '';
    parser.processor
        .on('data', chunk => (csv += chunk.toString()))
        .on('end', () => {
            callback && callback();
            saveAs(new Blob([csv]), `${name}.csv`);
        });

    data.forEach((item: ApiItem) => parser.input.push(mapItemToCsv(item, columnsToCsv, mapper)));
    parser.input.push(null);
};

const mapItemToCsv = (item: ApiItem, columnsToCsv: Column[], mapper: (item: ApiItem) => ApiItem) => {
    const mappedItem = mapper(item) as any;

    return columnsToCsv.reduce((result, column) => {
        const value = column.hasOwnProperty('csv')
            ? (column.csv as any)(column.field, mappedItem)
            : mappedItem[column.field];

        return { ...result, [column.key || column.field]: value };
    }, {});
};

export default downloadCsv;
