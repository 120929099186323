import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormValues } from './types';
import Body from '../../../dialog/Body';
import { FormProps } from '../../../form/types';
import InputText from '../../../form/InputText';
import InputPassword from '../../../form/InputPassword';

const AccountActivateForm: FunctionComponent<FormProps<FormValues>> = ({ extra }) => (
    <Body>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputPassword name="password"/>
            </Grid>
            <Grid item xs={12}>
                <InputPassword name="password_confirmation"/>
            </Grid>
            {extra.isInvite && <>
                <Grid item xs={12}>
                    <InputText name="district" i18n="district-name-confirm"/>
                </Grid>
                <Grid item xs={12}>
                    <InputText name="school" i18n="school-name-confirm"/>
                </Grid>
            </>}
        </Grid>
    </Body>
);

export default AccountActivateForm;
