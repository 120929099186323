import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormValues } from './types';
import validation from './validation';
import FormPage from '../../../form/FormPage';
import AccountActivateForm from './AccountActivateForm';
import Notification from '../../../common/Notification';
import exceptionTracker from '../../../../../exception-tracker';
import withFormPageDefault from '../../../hoc/withFormPageDefault';
import { apiLogin, endpoints, pageResponder } from '../../../../../api';
import { authOperations, modalsOperations } from '../../../../../state';
import { FormDefaultProps, FormPageProps, FormProps } from '../../../form/types';

type Props = FormDefaultProps<FormValues> & ConnectedProps<typeof connector> & RouteComponentProps<{
    id: string,
    code: string,
}>

type State = {
    loading: boolean,
    hasValidCode: boolean,
    isInvite: boolean,
    initialValues: Partial<FormValues>
}

const connector = connect(null, {
    open: modalsOperations.open,
    login: authOperations.login,
});

class AccountActivate extends React.Component<Props, State> {
    state = {
        loading: true,
        hasValidCode: true,
        isInvite: false,
        initialValues: {},
    }

    async componentDidMount() {
        const path = `${endpoints.app.accountActivate}/${this.props.match.params.id}/${this.props.match.params.code}`;

        try {
            const response = await apiLogin.get(path);
            const responder = pageResponder(response.data);

            if (responder.isRedirect) {
                return this.props.history.push('/');
            }

            this.setState({
                isInvite: responder.isForm,
                initialValues: {
                    ...responder.data,
                    id: this.props.match.params.id,
                    code: this.props.match.params.code,
                },
            });
        } catch (e) {
            this.setState({ hasValidCode: false });
            exceptionTracker.info(e, path);
        }

        this.setState({ loading: false });
    }

    render() {
        const pageProps: FormPageProps = {
            headerI18n: 'activate-centervention-educator-account',
        };

        const formProps: FormProps<FormValues> = {
            renderForm: (props) => <AccountActivateForm {...props} />,
            handleSubmit: this.props.handleSubmit,
            validation: validation,
            initialValues: this.state.initialValues,
            mode: 'none',
            loading: this.state.loading,
            submitI18n: 'login',
            extra: {
                isInvite: this.state.isInvite,
            },
        };

        if (this.state.hasValidCode) {
            return <FormPage pageProps={pageProps} formProps={formProps} />;
        }

        return <Notification type="error">
            <FormattedMessage id="notification.on-page.account-activation-link-invalid"/>
        </Notification>;
    }
}

export default connector(withRouter(withFormPageDefault<FormValues>(AccountActivate, props => ({
    endpoint: endpoints.app.accountActivate,
    method: 'patch',
    validation: validation,
    callback: (responseData) => {
        props.login(responseData);
        props.history.push('/account/activated');
    },
}))));
