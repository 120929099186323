import global from './../types';

const SIGN_IN = global.SIGN_IN;
const HANDSHAKE_LITE = global.HANDSHAKE_LITE;
const SIGN_OUT = global.SIGN_OUT;
const ACTING_AS = global.ACTING_AS;
const REPLACE_ACCESS_TOKEN = 'auth/replace-access-token';
const REFRESH_PROFILE = 'auth/refresh-profile';

export default {
    SIGN_IN: SIGN_IN as typeof SIGN_IN,
    HANDSHAKE_LITE: HANDSHAKE_LITE as typeof HANDSHAKE_LITE,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
    ACTING_AS: ACTING_AS as typeof ACTING_AS,
    REPLACE_ACCESS_TOKEN: REPLACE_ACCESS_TOKEN as typeof REPLACE_ACCESS_TOKEN,
    REFRESH_PROFILE: REFRESH_PROFILE as typeof REFRESH_PROFILE,
};

export * from './../../types';

export enum Scope {
    Uber = 'uber',
    Educator = 'educator',
    Parent = 'parent',
    Student ='student',
}

export interface AuthState {
    accessToken: AccessToken,
    user: User,
    actingAs: ActingAsUser | undefined,
}

export interface AccessToken {
    original: string,
    scope: string,
    scopes: string[],
}

export type OriginalAccessToken = string;

export type Products = { [key in string]: string };

export interface User {
    username: string,
    firstName: string,
    lastName: string,
    verified: boolean,
    isGroupAdmin: boolean,
    isTeamAdmin: boolean,
    isHighPriority: boolean,
    hasStudentsUsingProgram: boolean,
    hasSiteLicenseUsageReport: boolean,
    fullName: string,
    grade: number | null | undefined,
    personalChannel: string | undefined,
}

export interface ActingAsUser {
    id: number,
    scope: string,
    scopes: string[],
    username: string,
    firstName: string,
    lastName: string,
    verified: boolean,
    isGroupAdmin: boolean,
    isTeamAdmin: boolean,
    isHighPriority: boolean,
    hasStudentsUsingProgram: boolean,
    hasSiteLicenseUsageReport: boolean,
    fullName: string,
    grade: number | null | undefined,
    personalChannel: string | undefined,
}

export interface Profile {
    firstName: string,
    lastName: string,
    fullName: string,
}

export interface TabUser {
    id: number,
    type: string,
}

export type OriginalUser = {
    username: string,
    first_name: string,
    last_name: string,
    verified: boolean,
    is_group_admin?: boolean,
    is_team_admin?: boolean,
    is_high_priority?: boolean,
    has_students_using_program?: boolean,
    has_site_license_usage_report?: boolean,
    grade: number | null | undefined,
    personal_channel: string | undefined,
}

export type OriginalActingAsUser = OriginalUser & {
    id: number,
    scope: string,
    scopes: string[],
    ecommerce: any,
}

export type OriginalProfile = {
    first_name: string,
    last_name: string,
}

export type AuthLoginFormValues = {
    username: string,
    password: string,
}

export interface SignInAction {
    type: typeof SIGN_IN,
    payload: {
        accessToken: AccessToken,
        user: User,
        ecommerce: any,
        notifications: any,
    }
}

export interface HandshakeLiteAction {
    type: typeof HANDSHAKE_LITE,
    payload: {
        accessToken: undefined,
        user: undefined,
        ecommerce: any,
        notifications: any,
    }
}

export interface SignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: AccessToken,
        user: User,
    }
}

export interface ActingAsAction {
    type: typeof ACTING_AS,
    payload: {
        actingAsUser: ActingAsUser,
        ecommerce: any,
        notifications: any,
    }
}

export interface ReplaceAccessTokenAction {
    type: typeof REPLACE_ACCESS_TOKEN,
    payload: {
        accessToken: AccessToken,
    }
}

export interface RefreshProfileAction {
    type: typeof REFRESH_PROFILE,
    payload: {
        profile: Profile,
    }
}
