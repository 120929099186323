import { createReducer } from '../../utils';
import types, {
    SidebarStatus,
    SidebarTaskData,
    SidebarActionAdd,
    SidebarActionSeen,
    SidebarActionTask,
    NotificationsState,
    SidebarActionRemove,
    SidebarActionSingleton,
    DashboardDismissAction,
    DashboardFetchPageAction,
    NotificationsSignInAction,
    DashboardLoadingBeginAction,
    DashboardLoadingCommitAction,
} from "./types";

const initialState: NotificationsState = {
    sidebar: [],
    dashboard: {
        notifications: [],
        loading: false,
        total: 0,
    },
};

const ofList = () => {
    return { status: SidebarStatus.Success, isFromApiResponse: false, isListable: true, isNew: true };
};

const ofSingle = () => {
    return { status: SidebarStatus.Success, isFromApiResponse: false, isListable: false, isNew: false };
};

const ofTask = (data: SidebarTaskData) => {
    if (data.task.failed) {
        return {
            status: SidebarStatus.Failed,
            isListable: true,
            isNew: true,
        };
    }

    return {
        status: data.task.done >= data.task.total ? SidebarStatus.Success : SidebarStatus.Processing,
        isListable: true,
        isNew: data.task.done >= data.task.total,
    };
};

const sidebarAdd = (state: NotificationsState, action: SidebarActionAdd): NotificationsState => {
    return { ...state, sidebar: [...state.sidebar, { ...action.payload, ...ofList() }] };
};

const sidebarSeen = (state: NotificationsState, action: SidebarActionSeen): NotificationsState => {
    const sidebar = state.sidebar.map(item => {
        if (item.id === action.payload.id) {
            item.isNew = false;
        }

        return item;
    });

    return { ...state, sidebar };
};

const sidebarRemove = (state: NotificationsState, action: SidebarActionRemove): NotificationsState => {
    const sidebar = state.sidebar.filter(item => item.id === undefined || item.id !== action.payload.id);

    return { ...state, sidebar };
};

const sidebarSingleton = (state: NotificationsState, action: SidebarActionSingleton): NotificationsState => {
    const sidebar = state.sidebar.filter(item => item.type !== action.payload.type);

    return { ...state, sidebar: [...sidebar, { ...action.payload, ...ofSingle() }] };
};

const sidebarTask = (state: NotificationsState, action: SidebarActionTask): NotificationsState => {
    const data = action.payload.data;

    const hasTask = !! state.sidebar.find(item => 'task' in item.data && item.data.task.uuid === data.task.uuid);

    if (hasTask && action.payload.isFromApiResponse) {
        return state;
    }

    if (! hasTask) {
        return { ...state, sidebar: [...state.sidebar, { ...action.payload, ...ofTask(data) }] };
    }

    const sidebar = state.sidebar.map(item => {
        if (! ('task' in item.data)) {
            return item;
        }

        if (item.data.task.uuid !== data.task.uuid) {
            return item;
        }

        return { ...item, data: action.payload.data, ...ofTask(data) };
    });

    return { ...state, sidebar: [...sidebar] };
};

const signIn = (state: NotificationsState, action: NotificationsSignInAction): NotificationsState => {
    return { ...state, dashboard: { ...state.dashboard, total: action.payload.notifications } };
};

const signOut = (): NotificationsState => {
    return { ...initialState };
};

const dashboardLoadingBegin = (state: NotificationsState, action: DashboardLoadingBeginAction): NotificationsState => {
    return { ...state, dashboard: { ...state.dashboard, loading: action.payload.loading } };
};

const dashboardLoadingCommit = (state: NotificationsState, action: DashboardLoadingCommitAction): NotificationsState => {
    return { ...state, dashboard: { ...state.dashboard, loading: action.payload.loading } };
};

const dashboardFetchPage = (state: NotificationsState, action: DashboardFetchPageAction): NotificationsState => {
    return { ...state, dashboard: { ...state.dashboard, notifications: action.payload.data, total: action.payload.total } };
};

const dashboardDismiss = (state: NotificationsState, action: DashboardDismissAction): NotificationsState => {
    const total = state.dashboard.total - 1;
    const notifications = state.dashboard.notifications.filter(notification => notification.id !== action.payload.id);

    return { ...state, dashboard: { ...state.dashboard, notifications, total } };
};

const reducers = createReducer(initialState, {
    [types.SIDEBAR_ADD]: sidebarAdd,
    [types.SIDEBAR_SEEN]: sidebarSeen,
    [types.SIDEBAR_REMOVE]: sidebarRemove,
    [types.SIDEBAR_SINGLETON]: sidebarSingleton,
    [types.SIDEBAR_TASK]: sidebarTask,
    [types.SIGN_IN]: signIn,
    [types.HANDSHAKE_LITE]: signIn,
    [types.SIGN_OUT]: signOut,
    [types.ACTING_AS]: signIn,
    [types.DASHBOARD_LOADING_BEGIN]: dashboardLoadingBegin,
    [types.DASHBOARD_LOADING_COMMIT]: dashboardLoadingCommit,
    [types.DASHBOARD_FETCH_PAGE]: dashboardFetchPage,
    [types.DASHBOARD_DISMISS]: dashboardDismiss,
} as any);

export default reducers;
