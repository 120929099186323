import moment from 'moment';
import { UIPersist } from '../ducks/ui';
import { SidebarId } from '../ducks/notifications';
import exceptionTracker from '../../exception-tracker';
import { AccessToken, Profile, User } from '../ducks/auth';
import { mapAccessToken, nullAccessToken, nullUser } from '../ducks/auth/mappers';

export const ACCESS_TOKEN = 'access_token';
export const NOTIFICATIONS_SIDEBAR_SEEN = 'notifications_sidebar_seen';
export const NOTIFICATIONS_SIDEBAR_REMOVE = 'notifications_sidebar_remove';
const USER = 'user';
const UI = 'ui';
const ACTIVE_AT = 'active_at';

const setAccessToken = (accessToken: AccessToken): void => {
    try {
        localStorage.setItem(ACCESS_TOKEN, accessToken.original);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const removeAccessToken = (): void => {
    try {
        localStorage.removeItem(ACCESS_TOKEN);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const accessToken = (): AccessToken => {
    let originalAccessToken = undefined;
    try {
        originalAccessToken = localStorage.getItem(ACCESS_TOKEN);
    } catch (e) {
        exceptionTracker.console(e);
    }

    if (originalAccessToken) {
        return mapAccessToken(originalAccessToken);
    }

    return nullAccessToken();
};

const setUser = (user: User): void => {
    try {
        localStorage.setItem(USER, JSON.stringify(user));
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const removeUser = (): void => {
    try {
        localStorage.removeItem(USER);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const user = (): User => {
    let user = undefined;
    try {
        user = localStorage.getItem(USER);
    } catch (e) {
        exceptionTracker.console(e);
    }

    if (user) {
        return JSON.parse(user);
    }

    return nullUser();
};

const setProfile = (profile: Profile): void => {
    setUser({ ...user(), ...profile });
};

const setUI = (uiPersist: UIPersist): void => {
    try {
        localStorage.setItem(UI, JSON.stringify(uiPersist));
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const ui = (): UIPersist => {
    let persisted = undefined;
    try {
        persisted = localStorage.getItem(UI);
    } catch (e) {
        exceptionTracker.console(e);
    }

    const initialPersist: UIPersist = {
        sidebar: {
            isCollapsed: false,
            uberIsOpen: true,
            studentsIsOpen: true,
            accountSettingsIsOpen: true,
        },
        tabs: {},
        video: {
            isOverviewCollapsed: false,
        },
    };

    if (persisted) {
        persisted = JSON.parse(persisted);

        return {
            sidebar: { ...initialPersist.sidebar, ...persisted.sidebar },
            tabs: { ...initialPersist.tabs, ...persisted.tabs },
            video: { ...initialPersist.video, ...persisted.video },
        };
    }

    return initialPersist;
};

const setActiveAt = (activeAt: string): void => {
    try {
        localStorage.setItem(ACTIVE_AT, activeAt);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const setActiveNow = (): void => {
    setActiveAt(moment().unix().toString());
};

const removeActiveAt = (): void => {
    try {
        localStorage.removeItem(ACTIVE_AT);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const activeAt = (): string | null => {
    try {
        return localStorage.getItem(ACTIVE_AT);
    } catch (e) {
        exceptionTracker.console(e);
    }

    return null;
};

const setNotificationsSidebarSeen = (id: SidebarId): void => {
    if (id === undefined) {
        return;
    }

    try {
        localStorage.setItem(NOTIFICATIONS_SIDEBAR_SEEN, id);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const setNotificationsSidebarRemove = (id: SidebarId): void => {
    if (id === undefined) {
        return;
    }

    try {
        localStorage.setItem(NOTIFICATIONS_SIDEBAR_REMOVE, id);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const removeNotificationsSidebar = (): void => {
    try {
        localStorage.removeItem(NOTIFICATIONS_SIDEBAR_SEEN);
        localStorage.removeItem(NOTIFICATIONS_SIDEBAR_REMOVE);
    } catch (e) {
        exceptionTracker.console(e);
    }
};

const signOut = (): void => {
    removeAccessToken();
    removeUser();
    removeActiveAt();
    removeNotificationsSidebar();
};

export default {
    setAccessToken,
    removeAccessToken,
    accessToken,
    setUser,
    removeUser,
    user,
    setProfile,
    setUI,
    ui,
    signOut,
    setActiveAt,
    setActiveNow,
    removeActiveAt,
    activeAt,
    setNotificationsSidebarSeen,
    setNotificationsSidebarRemove,
};
