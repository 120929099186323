import { lazy } from 'react';
import { RouteProps } from 'react-router';
import config from '../config';
import LogoutView from '../views/public/LogoutView';
import WelcomeView from '../views/public/WelcomeView';
import Error404View from '../views/public/Error404View';
import requireAuth from '../views/components/hoc/requireAuth';
import AccountRequestView from '../views/public/AccountRequestView';
import AccountActivateView from '../views/public/AccountActivateView';
import PaymentCanceledView from '../views/public/PaymentCanceledView';
import PaymentCompletedView from '../views/public/PaymentCompletedView';
import AccountActivatedView from '../views/public/AccountActivatedView';

const common: RouteProps[] = [
    { path: '/', component: WelcomeView, exact: true },
    { path: '/logout', component: LogoutView, exact: true },
    { path: '/terms', component: lazy(() => import('../views/public/TermsOfUseView')), exact: true },
    { path: '/privacy', component: lazy(() => import('../views/public/PrivacyPolicyView')), exact: true },
];

const appSubdomain: RouteProps[] = [
    { path: '/uber', component: requireAuth(lazy(() => import('../views/uber/UberDashboard'))), exact: false },
    { path: '/educator', component: requireAuth(lazy(() => import('../views/educator/EducatorDashboard'))), exact: false },
    { path: '/parent', component: requireAuth(lazy(() => import('../views/parent/ParentDashboard'))), exact: false },
    { path: '/student', component: requireAuth(lazy(() => import('../views/student/StudentDashboard'))), exact: false },
    { path: '/account/request', component: AccountRequestView, exact: true },
    { path: '/account/activate/:id/:code', component: AccountActivateView, exact: true },
    { path: '/account/activated', component: AccountActivatedView, exact: true },
    { path: '/account/:id/:code/:suffix', component: AccountActivateView, exact: true },
    { path: '/payment/canceled', component: PaymentCanceledView, exact: true },
    { path: '/payment/completed/:session', component: PaymentCompletedView, exact: true },
    { path: '/reset-password/:id/:code', component: lazy(() => import('../views/public/SetNewPasswordView')), exact: true },
    { path: '/profile/:id/:code/:suffix', component: lazy(() => import('../views/public/SetNewProfileUsernameView')), exact: true },
    { path: '/password/:id/:code/:suffix', component: lazy(() => import('../views/public/SetNewPasswordView')), exact: true },
    { path: '/payment-family-subscription', component: lazy(() => import('../views/public/PaymentNewParentView')), exact: true },
    { path: '*', component: Error404View, exact: true },
];

const studentSubdomain: RouteProps[] = [
    { path: '/student', component: requireAuth(lazy(() => import('../views/student/StudentDashboard'))), exact: false },
    { path: '/classlink', component: lazy(() => import('../views/public/classlink/ClasslinkView')), exact: true },
    { path: '/classlink/login', component: lazy(() => import('../views/public/classlink/ClasslinkLoginView')), exact: true },
    { path: '/clever', component: lazy(() => import('../views/public/clever/CleverView')), exact: true },
    { path: '/clever/login', component: lazy(() => import('../views/public/clever/CleverLoginView')), exact: true },
    { path: '*', component: Error404View, exact: true },
];

const routes: RouteProps[] = common.concat(
    config.isStudentSubdomain ? studentSubdomain : appSubdomain
);

export default routes;
