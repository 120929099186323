import program from './program';
import sidebar from './sidebar';

const app = {
    'academy.title': program['program.academy.title'],
    'zoou.title': program['program.zoou.title'],
    'ssgrin.title': program['program.ssgrin.title'],
    'hoh.title': program['program.hoh.title'],
    'sim.title': program['program.sim.title'],

    'tabs.account-sources-form': 'Form Source',
    'tabs.account-sources-referral': 'Referral Source',
    'tabs.account-sources-uber': 'Uber Source',
    'tabs.districts': 'Districts',
    'tabs.schools': 'Schools',
    'tabs.teams': 'Groups',
    'tabs.educators': 'Educators',
    'tabs.purchases': 'Purchases',
    'tabs.purchase-overview': 'Purchase Overview',
    'tabs.purchase-history': 'Purchase History',
    'tabs.activity-history': 'Activity',
    'tabs.parents': 'Parents',
    'tabs.transfer-licenses-history': 'Transfer Licenses History',
    'tabs.students': 'Students',
    'tabs.my-students': 'My Students',
    'tabs.my-children': 'My Students',
    'tabs.all-students': 'All Students',
    'tabs.students-active': 'Current Roster',
    'tabs.students-assessment': 'Last Assessment',
    'tabs.students-assessment-1': '1st Assessment',
    'tabs.students-assessment-2': '2nd Assessment',
    'tabs.students-assessment-3': '3rd Assessment',
    'tabs.students-assessment-4': '4th Assessment',
    'tabs.students-skill-builder': 'Skill Builder',
    'tabs.students-archived': 'Archived Students',
    'tabs.students-bulk-upload': 'Bulk Uploads',
    'tabs.children': 'Students',
    'tabs.children-active': 'Current Roster',
    'tabs.children-archived': 'Archived Students',
    'tabs.children-progress': 'All Programs',
    'tabs.educator-metrics': 'Educator Metrics',
    'tabs.admin-metrics': 'Admin Metrics',
    'tabs.out-of-license-metrics': 'Out Of License Metrics',
    'tabs.metrics-gifts': 'Free Licenses Metrics',
    'tabs.gifts-invite-details-metrics': 'Overview',
    'tabs.gifts-invite-aggregated-metrics': 'Invitor Summary',
    'tabs.lessons': 'Lessons',

    'tabs.reports-progress': 'Overview',
    'tabs.reports-academy': program['program.academy.title'],
    'tabs.reports-zoou': program['program.zoou.title'],
    'tabs.reports-ssgrin': program['program.ssgrin.title'],
    'tabs.reports-hoh': program['program.hoh.title'],
    'tabs.reports-sim': program['program.sim.title'],
    'tabs.group-data-academy': program['program.academy.title'],
    'tabs.group-data-zoou': program['program.zoou.title'],
    'tabs.group-data-ssgrin': program['program.ssgrin.title'],
    'tabs.group-data-hoh': program['program.hoh.title'],

    'tabs.academy': program['program.academy.title'],
    'tabs.zoou': program['program.zoou.title'],
    'tabs.ssgrin': program['program.ssgrin.title'],
    'tabs.hoh': program['program.hoh.title'],
    'tabs.sim': program['program.sim.title'],

    'dashboard.tab-selector.title': 'View Selector',

    'documentation.getting-started': 'Getting Started',
    'documentation.game-previews': 'Game Previews',
    'documentation.program-guides': 'Program Guides',
    'documentation.Miscellaneous': 'Miscellaneous',
    'documentation.video': 'Centervention Video Overview',

    'lessons.library': 'Supplemental Resource Library',
    'lessons.showing-resources': 'Showing {filtered} of {total} {count, plural, one {resource} other {resources}}',
    'lessons.overview': 'Lesson Overview',
    'lessons.grade-levels': 'Recommended Grade Level',
    'lessons.skills': 'SEL Skill(s)',
    'lessons.prep-levels': 'Prep Level',
    'lessons.challenges': 'Challenge(s)',
    'lessons.materials': 'Materials',
    'lessons.button.review': 'Review Lesson',
    'lessons.button.student-materials-default': 'Student Worksheet',
    'lessons.tooltip.add-to-my-lessons': 'Add to My Lessons',
    'lessons.tooltip.remove-from-my-lessons': 'Remove from to My Lessons',
    'lessons.filter.view.all': 'All Lessons',
    'lessons.filter.view.my': 'My Lessons',
    'lessons.filter.resource_type': 'Resource Type',
    'lessons.filter.usage': 'Usage',
    'lessons.filter.skills': 'SEL Skills',
    'lessons.filter.grade-levels': 'Grade Levels',
    'lessons.filter.prep-levels': 'Prep Levels',
    'lessons.filter.challenges': 'Challenges',

    'button.login': 'Login',
    'button.set-new-password': 'Set New Password',
    'button.logout': 'Logout',
    'button.program-logout': 'Not {name}? Logout',
    'button.go-home': 'Go Home',
    'button.play': 'Play',
    'button.create-new-comic': 'Create New Comic',
    'button.manage-students': sidebar['sidebar.manage-students'],
    'button.show-video': 'Show Video',
    'button.hide-video': 'Hide Video',

    'app.welcome-back.line1': 'Welcome back, {name}!',
    'app.welcome-back.line2': 'Nice to see you.',
    'app.welcome-user': 'Welcome, {name}',
    'app.you-are-acting-as': 'You are acting as',
    'app.forgot-password': 'Forgot your password?',
    'app.reset-password': 'Reset password',
    'app.go-back-to-login': 'Go back to login view',
    'app.contact-us-for-assistance': 'Contact us for assistance',
    'app.need-educator-account': 'Need a Free educator account?',
    'app.request-educator-account': 'Request Educator Account',
    'app.click-here': 'Click here',
    'app.do-you-need-help': 'Need help?',

    'app.login-with-classlink': 'Login with ClassLink',
    'app.login-with-clever': 'Login with Clever',

    'footer.copyright': 'Copyright {year}',
    'footer.marketing-website-name': 'Centervention',
    'footer.terms': 'Terms of Use',
    'footer.privacy': 'Privacy Policy',
    'footer.account-request': 'Join Over 10,000 Schools Using Centervention Programs',

    'error-pages.page-not-found': 'Page Not Found',
    'error-pages.forbidden': 'Permission Denied',
    'error-boundary.chunk-load.line-1': 'Network Error',
    'error-boundary.chunk-load.line-2': 'Reload this page to try again',
};

export default app;
