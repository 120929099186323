import React, { FunctionComponent } from 'react';

type Props = {
    to: string,
    classes?: string | undefined,
    onClick?: () => void,
    newWindow?: boolean,
}

const LinkExternal: FunctionComponent<Props> = ({ children, to, classes, onClick, newWindow = true }) => (
    <a
        href={to}
        className={classes}
        target={newWindow ? '_blank' : '_self'}
        key={`l${to.replace(/\//g, '-')}`}
        onClick={onClick}
    >
        {children}
    </a>
);

export default LinkExternal;
