import React from 'react';
import { render } from 'react-snapshot';
import App from './views/App';
import config from './config';
import { IntlProvider } from './i18n';
import { Root, store } from './state';
import exceptionTracker from './exception-tracker';

exceptionTracker.init();

const SPA = () => (
    <IntlProvider config={config}>
        <Root store={store}>
            <App/>
        </Root>
    </IntlProvider>
);

render(<SPA/>, document.getElementById('root'));
