import { createSelector } from 'reselect';
import {
    ApiItem,
    RootState,
    TabulatorName,
    TabulatorSelected,
    TabulatorSingleNameState,
    TabulatorSelectableLimit,
} from './types';

const rows = (tabulator: TabulatorSingleNameState, page: number): ApiItem[] => {
    return (tabulator?.pages[page] || []).map(id => {
        return tabulator.byId[id];
    });
};

const rowsSelected = (tabulator: TabulatorSingleNameState): ApiItem[] => {
    return Object.values(tabulator.selected).map((item: ApiItem) => {
        return tabulator.byId[item.id] || item;
    });
};

const selectableLimit = (state: RootState, name: TabulatorName) => state.tabulator[name].selectableLimit;
const selected = (state: RootState, name: TabulatorName) => state.tabulator[name]?.selected || {};
const total = (state: RootState, name: TabulatorName) => state.tabulator[name].total;

const isSelectable = createSelector(
    [selectableLimit],
    (selectableLimit: TabulatorSelectableLimit): boolean => {
        return selectableLimit !== undefined;
    }
);

const countSelected = createSelector(
    [selected],
    (selected: TabulatorSelected): number => {
        return Object.keys(selected).length;
    }
);

const hasSelectedAll = createSelector(
    [countSelected, selectableLimit, total],
    (countSelected: number, selectableLimit: number | undefined, total: number): boolean => {
        return countSelected > 0 && countSelected >= Math.min(selectableLimit as number, total);
    }
);

export default {
    rows,
    rowsSelected,
    isSelectable,
    countSelected,
    hasSelectedAll,
};
