import React, { FunctionComponent } from 'react';
import logo from '../../assets/app/centervention-big.png';
import styles from './_common.module.sass';

const PublicLightHeader: FunctionComponent = () => (
    <section className={styles.headerLight}>
        <img src={logo} alt=""/>
    </section>
);

export default PublicLightHeader;
