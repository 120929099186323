import { CancelTokenSource } from 'axios';
import types, {
    ApiItem,
    NameAddAction,
    TabulatorName,
    TabulatorPage,
    FetchPageAction,
    TabulatorMapper,
    TabulatorFilter,
    SelectRowAction,
    SelectAllAction,
    TabulatorSorting,
    FetchFreshAction,
    TabulatorSegment,
    ForceFreshAction,
    PageChangeAction,
    SetCurrentAction,
    NameRemoveAction,
    SelectBulkAction,
    DeselectAllAction,
    FilterChangeAction,
    LoadingBeginAction,
    NameActivateAction,
    LoadingCommitAction,
    SortingChangeAction,
    TabulatorSortingDir,
    FilterClearAllAction,
    TabulatorSignOutAction,
    DownloadingBeginAction,
    DownloadingCommitAction,
    TabulatorSelectableLimit,
} from './types';

const nameAdd = (
    name: TabulatorName,
    segment: TabulatorSegment,
    endpoint: string,
    forceFreshFetchAfter: number = 0,
    selectableLimit: TabulatorSelectableLimit = undefined,
    shareFiltersWith: TabulatorName[] = [],
    filters: TabulatorFilter = {},
    sorting: TabulatorSorting = {},
): NameAddAction => ({
    type: types.NAME_ADD,
    payload: {
        name,
        segment,
        endpoint,
        forceFreshFetchAfter,
        selectableLimit,
        shareFiltersWith,
        filters,
        sorting,
    },
});

const nameActivate = (
    id: any,
    group: any,
    tab: TabulatorName,
    filters: TabulatorFilter,
    sorting?: TabulatorSorting,
): NameActivateAction => ({
    type: types.NAME_ACTIVATE,
    payload: { id, group, tab, filters, sorting },
});

const nameRemove = (name: TabulatorName): NameRemoveAction => ({
    type: types.NAME_REMOVE,
    payload: { name },
});

const fetchPage = (
    name: TabulatorName,
    mapper: TabulatorMapper,
    data: [],
    filtered: number,
    total: number,
    totalFromCache: boolean,
    at: number,
): FetchPageAction => ({
    type: types.FETCH_PAGE,
    payload: { name, mapper, data, filtered, total, totalFromCache, at },
});

const fetchFresh = (
    name: TabulatorName,
    mapper: TabulatorMapper,
    data: [],
    filtered: number,
    total: number,
    totalFromCache: boolean,
    at: number,
): FetchFreshAction => ({
    type: types.FETCH_FRESH,
    payload: { name, mapper, data, filtered, total, totalFromCache, at },
});

const pageChange = (name: TabulatorName, page: TabulatorPage): PageChangeAction => ({
    type: types.PAGE_CHANGE,
    payload: { name, page },
});

const filterChange = (name: TabulatorName, filter: TabulatorFilter): FilterChangeAction => ({
    type: types.FILTER_CHANGE,
    payload: { name, filter },
});

const filterClearAll = (name: TabulatorName): FilterClearAllAction => ({
    type: types.FILTER_CLEAR_ALL,
    payload: { name },
});

const sortingChange = (name: TabulatorName, column: string, first: TabulatorSortingDir): SortingChangeAction => ({
    type: types.SORTING_CHANGE,
    payload: { name, column, first },
});

const loadingBegin = (name: TabulatorName, cancelTokenSource: CancelTokenSource): LoadingBeginAction => ({
    type: types.LOADING_BEGIN,
    payload: { name, cancelTokenSource },
});

const loadingCommit = (name: TabulatorName): LoadingCommitAction => ({
    type: types.LOADING_COMMIT,
    payload: { name },
});

const downloadingBegin = (name: TabulatorName): DownloadingBeginAction => ({
    type: types.DOWNLOADING_BEGIN,
    payload: { name },
});

const downloadingCommit = (name: TabulatorName): DownloadingCommitAction => ({
    type: types.DOWNLOADING_COMMIT,
    payload: { name },
});

const setCurrent = (name: TabulatorName): SetCurrentAction => ({
    type: types.SET_CURRENT,
    payload: { name },
});

const forceFresh = (name: TabulatorName): ForceFreshAction => ({
    type: types.FORCE_FRESH,
    payload: { name },
});

const selectRow = (name: TabulatorName, id: number): SelectRowAction => ({
    type: types.SELECT_ROW,
    payload: { name, id },
});

const selectAll = (name: TabulatorName, items: ApiItem[]): SelectAllAction => ({
    type: types.SELECT_ALL,
    payload: { name, items },
});

const selectBulk = (name: TabulatorName, items: ApiItem[]): SelectBulkAction => ({
    type: types.SELECT_BULK,
    payload: { name, items },
});

const deselectAll = (name: TabulatorName): DeselectAllAction => ({
    type: types.DESELECT_ALL,
    payload: { name },
});

const signOut = (accessToken: any, user: any): TabulatorSignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

export default {
    nameAdd,
    nameActivate,
    nameRemove,
    fetchPage,
    fetchFresh,
    pageChange,
    filterChange,
    filterClearAll,
    sortingChange,
    loadingBegin,
    loadingCommit,
    downloadingBegin,
    downloadingCommit,
    setCurrent,
    forceFresh,
    selectRow,
    selectAll,
    selectBulk,
    deselectAll,
    signOut,
};
