import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { apiHandshake, endpoints } from '../../../api';
import { authSelectors, RootState } from '../../../state';

const connector = connect((state: RootState) => ({
    isActingAsAnotherUser: authSelectors.isActingAsAnotherUser(state),
}));

class Preload extends React.Component<ConnectedProps<typeof connector>> {
    async componentDidMount() {
        if (this.props.isActingAsAnotherUser) {
            return;
        }

        const response = await apiHandshake.get(endpoints.student.preload);
        this.fetch(response.data.data);
    }

    fetch(resources: Array<string>, index: number = 0) {
        if (! Array.isArray(resources) || ! (index in resources) || ! resources[index]) {
            return;
        }

        fetch(resources[index]).then(response => response.blob()).then(() => {
            this.fetch(resources, index + 1);
        }).catch(error => {
            this.fetch(resources, index + 1);
        });
    }

    render() {
        return null;
    }
};

export default connector(Preload);
