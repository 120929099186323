import types, {
    User,
    Profile,
    AccessToken,
    SignInAction,
    ActingAsUser,
    SignOutAction,
    ActingAsAction,
    HandshakeLiteAction,
    RefreshProfileAction,
    ReplaceAccessTokenAction,
} from './types';

const signIn = (accessToken: AccessToken, user: User, ecommerce: any = {}, notifications: any = 0): SignInAction => ({
    type: types.SIGN_IN,
    payload: { accessToken, user, ecommerce, notifications },
});

const handshakeLite = (ecommerce: any = {}, notifications: any = 0): HandshakeLiteAction => ({
    type: types.HANDSHAKE_LITE,
    payload: { accessToken: undefined, user: undefined, ecommerce, notifications },
});

const signOut = (accessToken: AccessToken, user: User): SignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

const actingAs = (actingAsUser: ActingAsUser, ecommerce: any = {}, notifications: any = 0): ActingAsAction => ({
    type: types.ACTING_AS,
    payload: { actingAsUser, ecommerce, notifications },
});

const replaceAccessToken = (accessToken: AccessToken): ReplaceAccessTokenAction => ({
    type: types.REPLACE_ACCESS_TOKEN,
    payload: { accessToken },
});

const refreshProfile = (profile: Profile): RefreshProfileAction => ({
    type: types.REFRESH_PROFILE,
    payload: { profile },
});

export default {
    signIn,
    handshakeLite,
    signOut,
    actingAs,
    replaceAccessToken,
    refreshProfile,
};
