import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { authOperations } from '../../state/ducks/auth';
import RouterLazyLoader from '../components/loaders/RouterLazyLoader';

const connector = connect(null, {
    signOut: authOperations.signOut,
});

class LogoutView extends React.Component<RouteComponentProps & ConnectedProps<typeof connector>> {
    componentDidMount() {
        this.props.signOut(() => {
            const query = new URLSearchParams(this.props.history.location.search);
            const redirect = query.get('r');
            const search = redirect ? `?r=${redirect}` : '';
            this.props.history.push(`/${search}`);
        });
    }

    render() {
        return <RouterLazyLoader />;
    }
}

export default withRouter(connector(LogoutView));
