import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormValues } from './types';
import validation from './validation';
import { endpoints } from '../../../../../api';
import FormModal from '../../../form/FormModal';
import ResetMyPasswordForm from './ResetMyPasswordForm';
import { modalsOperations } from '../../../../../state';
import { FormDefaultProps, FormProps } from '../../../form/types';
import withFormModalDefault from '../../../hoc/withFormModalDefault';
import { ModalContainerProps, ModalProps } from '../../../modals/types';
import createModalSettings from '../../../modals/create-modal-settings';
import ResetMyPasswordAdditionalFooter from './ResetMyPasswordAdditionalFooter';

type Props = ModalContainerProps & FormDefaultProps<FormValues> & ConnectedProps<typeof connector>

const connector = connect(null, {
    open: modalsOperations.open,
    close: modalsOperations.close,
});

class ResetMyPassword extends React.Component<Props> {
    switchToLogin = () => {
        this.props.close('reset-my-password');
        setTimeout(() => this.props.open(createModalSettings('login')), 100);
    }

    render() {
        const modalProps: ModalProps = {
            name: this.props.name,
            size: 'xs',
            headerI18n: this.props.type,
            canBeHidden: this.props.canBeHidden,
        };

        const formProps: FormProps<FormValues> = {
            renderForm: (props) => <ResetMyPasswordForm {...props} />,
            handleSubmit: this.props.handleSubmit,
            validation: validation,
            initialValues: undefined,
            mode: 'none',
            loading: false,
            submitI18n: 'submit.reset-password',
            additionalFooterButtons: submitting => <ResetMyPasswordAdditionalFooter
                submitting={submitting}
                switchToLogin={this.switchToLogin}
            />,
        };

        return <FormModal<FormValues> modalProps={modalProps} formProps={formProps} />;
    }
}

export default withFormModalDefault<FormValues>(connector(ResetMyPassword), props => ({
    name: props.name,
    endpoint: endpoints.app.resetMyPassword,
    validation: validation,
    i18nSuccessful: 'notification.reset-my-password.success',
    refreshAfterAction: false,
}));
