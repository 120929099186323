import React, { FunctionComponent, useEffect } from 'react';
import Blank from '../components/layout/Blank';
import Error404 from '../components/errors/Error404';
import exceptionTracker from '../../exception-tracker';

const Error404View: FunctionComponent = () => {
    useEffect(() => {
        exceptionTracker.info(new Error('Page Not Found'), window.location.pathname);
    }, []);

    return <Blank>
        <Error404/>
    </Blank>;
};

export default Error404View;
