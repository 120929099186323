import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { authSelectors, RootState } from '../../../state';
import LinkButton from '../../components/common/LinkButton';
import Notification from '../../components/common/Notification';

const connector = connect((state: RootState) => ({
    homePath: authSelectors.homePath(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
}));

const PaymentCanceled: FunctionComponent<ConnectedProps<typeof connector>> = ({ homePath, isAuthenticated }) => {
    const action = {
        to: isAuthenticated ? `${homePath}/manage-students` : homePath,
        i18n: isAuthenticated ? 'manage-students' : 'login',
    };

    return <Notification type="warning" actions={<LinkButton {...action}/>}>
        <FormattedMessage id={`notification.on-page.payment-canceled`}/>
    </Notification>;
};

export default connector(PaymentCanceled);
