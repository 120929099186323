import { createIntl } from 'react-intl';
import config from '../config';
import messages from './messages';
import errorHandler from './errorHandler';

const intl = createIntl({
    locale: config.i18n,
    messages: messages[config.i18n],
    onError: errorHandler,
});

export default intl;
