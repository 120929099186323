import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { authOperations } from '../../../state';
import { browserStorage } from '../../../state/utils';
import { ACCESS_TOKEN } from '../../../state/utils/browser-storage';

const connector = connect(null, {
    replaceAccessToken: authOperations.replaceAccessToken,
});

class ReplaceAccessTokenListener extends React.Component<ConnectedProps<typeof connector>> {
    constructor(props: ConnectedProps<typeof connector>) {
        super(props);

        this.handleAccessTokenChange = this.handleAccessTokenChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener('storage', this.handleAccessTokenChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleAccessTokenChange, false);
    }

    handleAccessTokenChange(e: any) {
        if (e.key === ACCESS_TOKEN && !! e.newValue) {
            this.props.replaceAccessToken(e.newValue, browserStorage.user().username);
        }
    }

    render() {
        return null;
    }
}

export default connector(ReplaceAccessTokenListener);
