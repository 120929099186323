import { InputSwitchGroup } from '../../../form/types';

export type FormValues = {
    name: string,
    overview: string,
    materials: string,
    duration: string,
    student_materials_anchor: string,
    resource_type: string,
    usage: InputSwitchGroup[],
    skills: InputSwitchGroup[],
    prep_levels: InputSwitchGroup[],
    grade_levels: InputSwitchGroup[],
    challenges: InputSwitchGroup[],
    pdf_educator_file: File,
    pdf_student_file: File,
    image_file: File,
}

export enum ResourceType {
    Worksheet = 'worksheet',
    Video = 'video',
    Activity = 'activity',
    Quiz = 'quiz',
    Article = 'article',
}

export enum Usage {
    Group = 'group',
    Individual = 'individual',
}

export enum Skills {
    IC = 'ic',
    COM = 'com',
    COOP = 'coop',
    INI = 'ini',
    EMP = 'emp',
    ER = 'er',
}

export enum PrepLevel {
    None = 'none',
    Modest = 'modest',
    Moderate = 'moderate',
}

export enum GradeLevel {
    Elementary = 'elementary',
    Middle = 'middle',
}

export enum Challenges {
    Anger = 'anger',
    AnxietyAndStress = 'anxiety-and-stress',
    CausingDisruptions = 'causing-disruptions',
    CalmingDown = 'calming-down',
    ConflictResolution = 'conflict-resolution',
    CopingWithChange = 'coping-with-change',
    DailyTransitions = 'daily-transitions',
    DealingWithDisappointment = 'dealing-with-disappointment',
    GrowthMindset = 'growth-mindset',
    FeelingLeftOut = 'feeling-left-out',
    Friendship = 'friendship',
    ListeningSkills = 'listening-skills',
    PeerPressure = 'peer-pressure',
    Perfectionism = 'perfectionism',
    SadnessAndGrief = 'sadness-and-grief',
    SelfEsteemAndSelfConfidence = 'self-esteem-and-self-confidence',
    StayingFocused = 'staying-focused',
}
