import { createReducer } from '../../utils';
import types, { EcommerceSet, EcommerceState } from './types';

const initialState: EcommerceState = {
    purchases: {
        freeTrial: undefined,
        individual: undefined,
        site: undefined,
    },
    productUsage: {},
};

const set = (state: EcommerceState, action: EcommerceSet): EcommerceState => {
    return {
        ...state,
        purchases: {
            freeTrial: action.payload.ecommerce.purchases.freeTrial,
            individual: action.payload.ecommerce.purchases.individual,
            site: action.payload.ecommerce.purchases.site,
        },
        productUsage: action.payload.ecommerce.productUsage,
    };
};

const signOut = (): EcommerceState => {
    return { ...initialState };
};

const reducers = createReducer(initialState, {
    [types.SIGN_IN]: set,
    [types.HANDSHAKE_LITE]: set,
    [types.SIGN_OUT]: signOut,
    [types.ACTING_AS]: set,
    [types.FETCH_FRESH]: set,
} as any);

export default reducers;
