import types, {
    EcommerceState,
    EcommerceSignInAction,
    EcommerceSignOutAction,
    EcommerceActingAsAction,
    EcommerceFetchFreshAction,
} from './types';

const signIn = (accessToken: any, user: any, ecommerce: EcommerceState, notifications: any): EcommerceSignInAction => ({
    type: types.SIGN_IN,
    payload: { accessToken, user, ecommerce, notifications },
});

const signOut = (accessToken: any, user: any): EcommerceSignOutAction => ({
    type: types.SIGN_OUT,
    payload: { accessToken, user },
});

const actingAs = (actingAsUser: any, ecommerce: EcommerceState, notifications: any): EcommerceActingAsAction => ({
    type: types.ACTING_AS,
    payload: { actingAsUser, ecommerce, notifications },
});

const fetchFresh = (ecommerce: EcommerceState): EcommerceFetchFreshAction => ({
    type: types.FETCH_FRESH,
    payload: { ecommerce },
});

export default {
    signIn,
    signOut,
    actingAs,
    fetchFresh,
};
