import config from '../../config';

const external = {
    marketingWebsiteDomain: config.marketingWebsiteDomain,
    dontHaveAccountParents: 'https://www.centervention.com/purchasing/#homepurchase',
    support: 'https://www.centervention.com/support/',
    purchasing: 'https://www.centervention.com/purchasing/',
};

export default external;
