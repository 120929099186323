import app from './app';
import uber from './uber';
import parent from './parent';
import student from './student';
import educator from './educator';
import external from './external';

export default {
    app,
    uber,
    parent,
    student,
    educator,
    external,
};
