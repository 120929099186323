import React, { ComponentType } from 'react';
import { AnyObject, FormApi } from 'final-form';
import { connect, ConnectedProps } from 'react-redux';
import { FormModalDefaultSettings } from './types';
import { handleSuccessful, handleValidationFailed } from './utils';
import { ecommerceOperations } from '../../../state/ducks/ecommerce';
import { apiActions, extractFormErrorsOrFail, mapToApiValues } from '../../../api';
import { modalsOperations, ModalStatus, tabulatorOperations } from '../../../state';

const connector = connect(null, {
    close: modalsOperations.close,
    updateStatus: modalsOperations.updateStatus,
    refresh: tabulatorOperations.refreshWithRelations,
    ecommerceFetchFresh: ecommerceOperations.fetchFresh,
});

const withFormModalDefault = <FormValues extends AnyObject, P = any>(
    WrappedComponent: ComponentType<P>,
    settingsCallback: FormModalDefaultSettings<FormValues, P>
): ComponentType<P> => {
    class WithFormModalDefault extends React.Component<P & ConnectedProps<typeof connector>> {
        private settings: ReturnType<FormModalDefaultSettings<FormValues, P>>;

        constructor(props: P & ConnectedProps<typeof connector>) {
            super(props);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.settings = settingsCallback(props);
        }

        async handleSubmit(values: FormValues, form: FormApi<FormValues>) {
            try {
                this.props.updateStatus(this.settings.name, ModalStatus.Processing);
                const response = await apiActions.patch(this.settings.endpoint, mapToApiValues(values));
                this.props.updateStatus(this.settings.name, ModalStatus.Success);
                return handleSuccessful(this.settings, this.props, {}, response.data);
            } catch (error) {
                this.props.updateStatus(this.settings.name, ModalStatus.Failed);
                const errors = extractFormErrorsOrFail(error, form.getRegisteredFields());
                return handleValidationFailed(this.settings, errors);
            }
        }

        render() {
            const props = {
                handleSubmit: this.handleSubmit,
            };

            return <WrappedComponent {...this.props} {...props} />;
        }
    }

    return connector(WithFormModalDefault as ComponentType<any>);
};

export default withFormModalDefault;
