import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import appMode from './../app-mode';
import routes from '../../../routes/utils';
import Header from '../../components/layout/Header';
import { authSelectors, modalsOperations, RootState } from '../../../state';
import createModalSettings from '../../components/modals/create-modal-settings';
import styles from './_common.module.sass';

const connector = connect((state: RootState) => ({
    user: authSelectors.user(state),
    homePath: authSelectors.homePath(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
    isActingAsAnotherUser: authSelectors.isActingAsAnotherUser(state),
}), {
    handleModalOpen: modalsOperations.open,
});

const PublicHeader: FunctionComponent<RouteComponentProps & ConnectedProps<typeof connector>> = ({
    user,
    homePath,
    handleModalOpen,
    isAuthenticated,
    isActingAsAnotherUser,
    history,
}) => {
    const handleLoginModalOpen = useCallback(() => {
        handleModalOpen(createModalSettings('login'));
    }, [handleModalOpen]);

    const handleLogout = useCallback(() => {
        routes.logoutApp(history, isActingAsAnotherUser);
    }, [history, isActingAsAnotherUser]);

    return <Header classes={styles.header}>
        {appMode.publicHeaderLogo()}
        <section>
            {! isAuthenticated && <Button
                variant="outlined"
                size="small"
                onClick={handleLoginModalOpen}
            >
                <FormattedMessage id="button.login"/>
            </Button>}

            {isAuthenticated && <Link to={homePath}>
                <Button
                    variant="outlined"
                    size="small"
                >
                    {user.fullName}
                </Button>
            </Link>}

            {isAuthenticated && <Button
                variant="outlined"
                size="small"
                onClick={handleLogout}
            >
                <FormattedMessage id="button.logout"/>
            </Button>}
        </section>
    </Header>;
};

export default withRouter(connector(PublicHeader));
