import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import Preload from './Preload';
import appMode from './../app-mode';
import ButtonPlay from './ButtonPlay';
import Main from '../../components/layout/Main';
import StudentHeader from '../common/StudentHeader';
import ButtonProgramLogout from './ButtonProgramLogout';
import CardNiceToSeeYouBack from './CardNiceToSeeYouBack';
import { authSelectors, RootState } from '../../../state';
import Container from '../../components/layout/Container';
import styles from './_welcome.module.sass';

const connector = connect((state: RootState) => ({
    user: authSelectors.user(state),
    isAuthenticated: authSelectors.isAuthenticated(state),
}));

const WelcomeStudent: FunctionComponent<ConnectedProps<typeof connector>> = ({ user, isAuthenticated }) => (
    <Container classes={classNames(styles.background, styles.backgroundStudent)}>
        <StudentHeader/>
        <Main classes={styles.main}>
            {isAuthenticated && <>
                <CardNiceToSeeYouBack user={user}/>
                <ButtonPlay/>
                <ButtonProgramLogout user={user}/>
            </>}
        </Main>
        {appMode.publicFooter()}
        <Preload/>
    </Container>
);

export default connector(WelcomeStudent);
