import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import MaterialSwitch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './_form.module.sass';

type Props = {
    name: string,
    i18n?: string,
    disabled?: boolean,
}

const InputSwitch: FunctionComponent<Props> = ({ name, i18n, disabled }) => {
    const field = useField<boolean>(name);
    const { meta } = field;

    const showError = ((meta.submitError && ! meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return <>
        <FormControlLabel
            label={<FormattedMessage id={`form.label.${i18n || name}`}/>}
            className={styles.switch}
            control={
                <MaterialSwitch
                    checked={!! field.input.value}
                    onChange={field.input.onChange}
                    color="primary"
                    disabled={disabled}
                />
            }
        />
        {showError && <FormHelperText className={styles.error}>{meta.error || meta.submitError}</FormHelperText>}
    </>;
};

export default InputSwitch;
