import global from './../types';
import reports from './../../../views/components/actions/common/report/types';

const MODAL_OPEN = 'modal/open';
const MODAL_HIDE = 'modal/hide';
const MODAL_SHOW = 'modal/show';
const MODAL_CLOSE = 'modal/close';
const MODAL_UPDATE_STATUS = 'modal/update-status';
const MODAL_UPDATE_TITLE = 'modal/update-title';
const SIGN_OUT = global.SIGN_OUT;

export default {
    MODAL_OPEN: MODAL_OPEN as typeof MODAL_OPEN,
    MODAL_HIDE: MODAL_HIDE as typeof MODAL_HIDE,
    MODAL_SHOW: MODAL_SHOW as typeof MODAL_SHOW,
    MODAL_CLOSE: MODAL_CLOSE as typeof MODAL_CLOSE,
    MODAL_UPDATE_STATUS: MODAL_UPDATE_STATUS as typeof MODAL_UPDATE_STATUS,
    MODAL_UPDATE_TITLE: MODAL_UPDATE_TITLE as typeof MODAL_UPDATE_TITLE,
    SIGN_OUT: SIGN_OUT as typeof SIGN_OUT,
};

export * from './../../types';

export type ModalPublicType = 'login' | 'reset-my-password' | 'set-new-password'

export type ModalUberType =
    typeof reports.ACADEMY_REPORT | typeof reports.ACADEMY_TIME_PLAYED |
    typeof reports.ZOOU_ASSESSMENT | typeof reports.ZOOU_SKILL_BUILDER | typeof reports.ZOOU_SKILL_BUILDER_BOARD |
    typeof reports.ZOOU_TIME_PLAYED |
    typeof reports.SSGRIN_REPORT | typeof reports.SSGRIN_TIME_PLAYED |
    typeof reports.HOH_ASSESSMENT | typeof reports.HOH_SKILL_BUILDER | typeof reports.HOH_TIME_PLAYED |
    typeof reports.SIM_LIBRARY | typeof reports.SIM_TIME_PLAYED | 'sim-comic' |
    'district-add' | 'district-edit' | 'district-delete' |
    'school-add' | 'school-edit' | 'school-delete' | 'schools-of-educator' | 'schools-of-log' |
    'team-add' | 'team-edit' | 'team-delete' | 'teams-of-educator' | 'teams-of-log' |
    'admins-of-team' | 'members-of-team' | 'site-licenses-of-team' | 'site-license-history' |
    'educator-add' | 'educator-edit' | 'connect-students-with-educator' |
    'transfer-students-list' | 'transfer-students' |
    'educators-of-school' | 'educators-of-log' | 'educators-of-student-active' | 'educators-of-student-archived' |
    'educator-convert-to-parent' |
    'purchase-order-individual-add' | 'purchase-order-site-add' | 'purchase-order-site-upgrade' |
    'purchase-site-edit' |
    'quote-create' | 'quote-add' | 'quote-add-renewal' | 'quote-view' |
    'last-request-quote-of-educator-view' | 'last-quote-of-educator-view' |
    'quote-of-history-view' | 'request-quote-of-history-view' |
    'parent-edit' |
    'parents-of-child-active' | 'parents-of-child-archived' | 'parent-convert-to-educator' |
    'account-deactivate' | 'account-rollback-deactivate' |
    'account-source-resend-form' | 'account-source-resend-referral' | 'account-source-resend-uber' |
    'account-source-verify' | 'account-source-hold' | 'account-source-delete' |
    'student-add' | 'student-edit' | 'student-archive' | 'student-unarchive' | 'student-delete' |
    'student-bulk-upload' | 'student-bulk-upload-rows' | 'student-bulk-upload-pending' | 'student-bulk-upload-created' |
    'student-bulk-upload-updated' | 'student-bulk-upload-failed' | 'student-bulk-upload-out-of-license' |
    'students-valid-license-of-educator' | 'students-active-of-educator' | 'students-archived-of-educator' |
    'students-of-log' |
    'student-clone-into-sandbox' | 'student-assign-program-history' | 'assign-program-rollback' |
    'child-edit' | 'children-active-of-parent' | 'children-archived-of-parent' |
    'mass-archive' | 'mass-unarchive' | 'mass-grade' | 'mass-teacher' | 'mass-password' | 'mass-reset-password' |
    'ecommerce-history' | 'game-debug-mode' |
    'metrics-educator-access' | 'metrics-admin-access' | 'metrics-live-admin-metrics' |
    'educator-reached-out' | 'admin-reached-out' | 'educator-high-priority' | 'admin-high-priority' |
    'lesson-add' | 'lesson-edit' |
    'change-password' | 'change-my-password'

export type ModalEducatorType =
    typeof reports.ACADEMY_REPORT | typeof reports.ACADEMY_TIME_PLAYED |
    typeof reports.ZOOU_SINGLE_REPORT | typeof reports.ZOOU_SKILL_BUILDER_BOARD |
    typeof reports.ZOOU_TIME_PLAYED |
    typeof reports.SSGRIN_REPORT | typeof reports.SSGRIN_TIME_PLAYED |
    typeof reports.HOH_SINGLE_REPORT |
    typeof reports.HOH_TIME_PLAYED |
    typeof reports.SIM_LIBRARY | typeof reports.SIM_TIME_PLAYED | 'sim-comic' |
    typeof reports.TIME_PLAYED |
    'student-create-account' | 'my-student-edit' | 'all-student-edit' |
    'demo-academy' | 'demo-zoou' | 'demo-ssgrin' | 'demo-hoh' | 'demo-sim' | 'student-login-page' |
    'mass-switch-program' | 'mass-assign-license' | 'switch-default-program' |
    'mass-archive' | 'mass-unarchive' | 'mass-grade' | 'mass-teacher' | 'mass-password' |
    'mass-transfer' | 'mass-transfer-my-students' |
    'mass-transfer-by-admin' | 'mass-transfer-by-admin-all-students' |
    'mass-academy-report' | 'mass-zoou-assessment' | 'mass-zoou-skill-builder' | 'mass-ssgrin-report' |
    'mass-hoh-assessment' | 'mass-hoh-skill-builder' | 'mass-unlock-assessment' |
    'mass-restart-academy' | 'mass-restart-zoou' | 'mass-restart-ssgrin' | 'mass-restart-hoh' |
    'mass-restart-active-game' | 'mass-all-programs-report' |
    'change-password' | 'student-unarchive' | 'update-profile' | 'change-my-password' |
    'class-code-manage' | 'class-code-delete' | 'purchase-history' |
    'purchase-licenses' | 'transfer-licenses' | 'reclaim-licenses' |
    'invite-colleague' | 'resources-educator' | 'lesson-resource' |
    'request-quote-add' | 'request-quote-view' |
    'assign-program-my-rollback' | 'assign-program-all-rollback' |
    'activate-program-student' | 'renew-program-student' | 'switch-program-student' |
    'educator-deactivate' | 'educator-create-account' | 'educator-schools' |
    'educator-active-students' | 'educator-archived-students' |
    'site-license-grant-access' | 'site-license-revoke-access' | 'site-license-usage-report'

export type ModalStudentCreateNewAccount = 'child-create-account-free-trial' | 'child-create-account-individual'

export type ModalParentType = ModalStudentCreateNewAccount |
    typeof reports.ACADEMY_REPORT | typeof reports.ACADEMY_TIME_PLAYED |
    typeof reports.ZOOU_ASSESSMENT | typeof reports.ZOOU_SKILL_BUILDER | typeof reports.ZOOU_SKILL_BUILDER_BOARD |
    typeof reports.ZOOU_TIME_PLAYED |
    typeof reports.SSGRIN_REPORT | typeof reports.SSGRIN_TIME_PLAYED |
    typeof reports.HOH_ASSESSMENT | typeof reports.HOH_SKILL_BUILDER | typeof reports.HOH_TIME_PLAYED |
    typeof reports.SIM_LIBRARY | typeof reports.SIM_TIME_PLAYED | 'sim-comic' |
    'my-student-edit' |
    'change-password' | 'update-profile' | 'change-my-password' | 'purchase-history' |
    'purchase-licenses' | 'resources-parent' | 'lesson-resource' |
    'activate-program-child' | 'renew-program-child' | 'switch-program-child' |
    'purchase-renew-child'

export type ModalStudentType = 'sim-comic'

export type ModalType = ModalPublicType | ModalUberType | ModalEducatorType | ModalParentType |
    'detached-download-spreadsheet-confirm-modal';

export type ModalId = number | undefined;

export enum ModalStatus {
    Open = 'open',
    Processing = 'processing',
    Success = 'success',
    Failed = 'failed',
    Warning = 'warning',
}

export type TabulatorRowCopy = {
    name: string,
    segment: string,
    row: any,
} | undefined

export type ModalSettings = {
    name: string,
    type: ModalType,
    id: ModalId,
    tabulator: string | undefined,
    tabulatorRowCopy: TabulatorRowCopy,
}

export type ModalSingleNameState = {
    isOpen: boolean,
    type: ModalType,
    id: ModalId,
    status: ModalStatus,
    tabulator: string | undefined,
    tabulatorRowCopy: TabulatorRowCopy,
    title: string,
    extra: any,
}

export interface ModalsState {
    [name: string]: ModalSingleNameState,
}

export interface ModalOpenAction {
    type: typeof MODAL_OPEN,
    payload: ModalSettings & { extra: any },
}

export interface ModalHideAction {
    type: typeof MODAL_HIDE,
    payload: {
        name: string,
    }
}

export interface ModalShowAction {
    type: typeof MODAL_SHOW,
    payload: {
        name: string,
    }
}

export interface ModalCloseAction {
    type: typeof MODAL_CLOSE,
    payload: {
        name: string,
    }
}

export interface ModalUpdateStatusAction {
    type: typeof MODAL_UPDATE_STATUS,
    payload: {
        name: string,
        status: ModalStatus,
    }
}

export interface ModalUpdateTitleAction {
    type: typeof MODAL_UPDATE_TITLE,
    payload: {
        name: string,
        title: string,
    }
}

export interface ModalSignOutAction {
    type: typeof SIGN_OUT,
    payload: {
        accessToken: any,
        user: any,
    }
}
