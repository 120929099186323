import React, { SyntheticEvent } from 'react';

type ContextProps = {
    canBeHidden: boolean,
    disableClose: boolean,
    handleHide: (e: SyntheticEvent) => void,
    handleClose: (e: SyntheticEvent) => void,
};

const DialogContext = React.createContext<ContextProps>({
    canBeHidden: false,
    disableClose: false,
    handleHide: () => {},
    handleClose: () => {},
});

export default DialogContext;
