import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from '../../../routes/utils';
import { authSelectors, RootState, User } from '../../../state/ducks/auth';
import styles from './_welcome.module.sass';

type Props = RouteComponentProps & ConnectedProps<typeof connector> & {
    user: User,
}

const connector = connect((state: RootState) => ({
    isActingAsAnotherUser: authSelectors.isActingAsAnotherUser(state),
}));

const ButtonProgramLogout: FunctionComponent<Props> = ({ user, isActingAsAnotherUser, history }) => {
    const handleLogout = useCallback(() => {
        routes.logoutStudent(history, isActingAsAnotherUser, false);
    }, [history, isActingAsAnotherUser]);

    return <Button
        variant="contained"
        color="default"
        size="large"
        className={styles.withoutTransform}
        onClick={handleLogout}
    >
        <FormattedMessage id="button.program-logout" values={{ name: user.fullName || user.username }}/>
    </Button>;
};

export default withRouter(connector(ButtonProgramLogout));
