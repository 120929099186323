import { FormValues } from './types';
import { createValidation, LabelOverrides, Rules } from '../../../../../validation';

const rules: Rules<FormValues> = {
    username: ['required'],
};

export const labelOverrides: LabelOverrides<FormValues> = {
    username: 'email',
};

const validation = createValidation<FormValues>(rules, labelOverrides);

export default validation;
