import { Programs } from '../../../../../programs';

export enum EducatorDocs {
    QuickStartGuide = 101,
    StudentLoginCards = 102,
    BackToSchoolTips = 103,
    AcademyGuide = 111,
    ZoouGuide = 112,
    SsgrinGuide = 113,
    HohGuide = 114,
    SimGuide = 115,
    CDCAndECIGuide = 116,
    EducatorTrainingGuide = 121,
    EducatorTrainingVideo = 122,
    ParentLetterAndPermissionSlip = 123,
    W9 = 124,
};

export type Resource = {
    icon?: 'guide' | 'login' | 'school' | 'video' | 'envelope' | 'smile',
    logo?: Programs,
    external?: string,
    resource: EducatorDocs,
}
